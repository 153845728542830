<script lang="ts" setup>
import { computed } from 'vue';
import IdeoFormRadio from './IdeoFormRadio.vue';

const model = defineModel<any>({
    required: false,
    default: null,
    set(value)
    {
        emit('change', value);

        return value;
    }
});
const props = withDefaults(defineProps<{
    checked?: any,
    valueField?: string,
    textField?: string,
    options?: Record<string, any>[],
    stacked?: boolean,
    columns?: number,
    disabled?: boolean
}>(), {
    checked: undefined,
    valueField: 'value',
    textField: 'text',
    options: (): any => [],
    stacked: false,
    columns: 1,
    disabled: false
});
const emit = defineEmits<{
    (e: 'change', value: any): void;
}>();


const internal = computed({
    get: () => props.checked !== undefined ? props.checked : model.value,
    set: (value) => props.checked !== undefined ? emit('change', value) : model.value = value
});

const customClass = (): Record<string, boolean> =>
{
    return {
        'd-flex flex-wrap flex-row': props.stacked === false,
        'd-flex flex-wrap flex-column': props.stacked !== false && props.columns == 1,
        [`row row-cols-${props.columns}`]: props.stacked !== false && props.columns > 1
    };
};

const isDisabled = (option: Record<string, any>): boolean =>
{
    if (props.disabled == true)
        return true;

    if ('disabled' in option)
        return option.disabled;

    return false;
};

const onChange = (value: any): void =>
{
    internal.value = value;
};
</script>

<template>
    <fieldset :class="customClass()" :disabled="disabled">
        <slot name="default">
            <span class="col" v-for="(option, i) in options" :key="i">
                <IdeoFormRadio :value="option[valueField]" :disabled="isDisabled(option)" :checked="internal" @change="onChange">
                    <slot name="label" :option="option" :index="i">{{ option[textField] }}</slot>
                </IdeoFormRadio>
            </span>
        </slot>
    </fieldset>
</template>

<script lang="ts" setup>
const props = defineProps<{
    display: string|null;
    toggleClass?: any;
    menuClass?: any;
}>();
</script>

<template>
    <IdeoDropdown
        variant="none" :split="false" no-icon block
        :toggle-class="['form-control-custom border bg-body-tertiary btn-text-normal fw-normal text-start overflow-hidden', props.toggleClass]"
        :menu-class="['shadow', props.menuClass]"
    >
        <template #button-content>
            <div class="d-flex">
                <div class="display text-truncate">
                    {{ props.display || '&nbsp;' }}
                </div>
                <div class="ms-auto">
                    <i class="select-icon fas fa-chevron-down"></i>
                </div>
            </div>
        </template>
        <template #default>
            <slot name="default"></slot>
        </template>
    </IdeoDropdown>
</template>

<style scoped>
:deep(.dropdown-menu)
{
    width: 100%;
    min-width: fit-content;
}
</style>

<script lang="ts" setup>
import InputNumber from 'primevue/inputnumber';

const model = defineModel<number>();

defineOptions({
    name: 'ideo-form-number'
});

const resetValue = (): void =>
{
    model.value = 0;
};
</script>

<template>
    <ideo-input-group>
        <InputNumber class="form-control" v-model="model" />
        <template #append>
            <button @click.prevent="resetValue" class="btn btn-secondary">
                <i class="fas fa-fw fa-times"></i>
            </button>
        </template>
    </ideo-input-group>
</template>

<style scoped>
.input-group .form-control {
    border: none;
    padding: 0;
}
.input-group .form-control :deep(input) {
    display: block;
    width: 100%;
}
</style>

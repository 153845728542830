<template>
    <div :class="getClass()" @click="onClick" @dblclick="onDblClick">
        <slot name="default"></slot>
    </div>
</template>

<script lang="ts" setup>
import { inject } from 'vue';

export interface Props
{
    index: number;
    item: any;
}

defineOptions({
    name: 'list-view-utils-row',
    components: {
        'vnodes': (props: Record<string, any>, context: any) => props.vnodes
    },
    inheritAttrs: false
});

const props = withDefaults(defineProps<Props>(), {});
const rowClick = inject<(item: any, i: number) => void>('row-click');
const rowClass = inject<(item: any, i: number) => Record<string, boolean> | string[] | string>('row-class');
const rowDblClick = inject<(item: any, index: number) => void>('row-dbl-click');

const getClass = (): any =>
{
    if (rowClass)
    {
        return rowClass(props.item, props.index);
    }

    return {};
};

const onClick = (): void =>
{
    if (rowClick)
    {
        rowClick(props.item, props.index);
    }
};

const onDblClick = (): void =>
{
    if (rowDblClick)
    {
        rowDblClick(item.value, props.index);
    }
};
</script>

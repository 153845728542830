<script lang="ts" setup>
import { computed, onMounted, onBeforeUnmount } from 'vue';
import { normalizeClasses } from '@/helpers/Utils';
import { Option } from '@/helpers/Interfaces';
import { useEvents } from '@/plugins/events';

const tab = defineModel<string>('tab');
const props = withDefaults(defineProps<{
    disabled?: boolean,
    card?: boolean,
    vertical?: boolean,
    pills?: boolean,
    underline?: boolean,
    fill?: boolean,
    justified?: boolean,
    align?: 'start'|'center'|'end',
    navClass?: Record<string, boolean> | string[] | string,
    activeNavItemClass?: Record<string, boolean> | string[] | string,
    tabs: Option[]
}>(), {
    disabled: false,
    card: false,
    vertical: false,
    pills: false,
    underline: false,
    fill: false,
    justified: false,
    align: 'start',
    navClass: () => [] as string[],
    activeNavItemClass: () => [] as string[],
    tabs: () => [] as Option[]
});

const { $events } = useEvents();

const flag = (value: any): boolean =>
{
    return value !== false;
};

const navClasses = computed(() =>
{
    const vertical = flag(props.vertical);
    const card = flag(props.card) && !vertical;
    const underline = flag(props.underline);
    const pills = flag(props.pills) && !underline;
    const tabs = !pills && !underline;
    const fill = flag(props.fill);
    const justified = flag(props.justified);

    return {
        'nav': true,
        'nav-tabs': tabs,
        'nav-pills': pills,
        'nav-underline': underline,
        'nav-fill': fill,
        'nav-justified': justified,
        'card-header-tabs': card && tabs,
        'card-header-pills': card && pills,
        'card-header-underline': card && underline,
        'flex-column': vertical,
        'justify-content-center': props.align == 'center',
        'justify-content-end': props.align == 'end',
        ...normalizeClasses(props.navClass)
    };
});

const navLinkClasses = (tabName: string): Record<string, boolean> =>
{
    const active = tabName == tab.value;

    return {
        'nav-link': true,
        'active': active,
        'disabled': flag(props.disabled),
        ...(active ? normalizeClasses(props.activeNavItemClass) : {})
    };
};

const onChange = (item: Option): void =>
{
    tab.value = item.value;
};

const onErrorOccured = (): void =>
{
    const foundElement = document.querySelector('.invalid');

    if (foundElement)
    {
        const parentElement = foundElement.closest(`[data-tab]`);

        if (parentElement)
            tab.value = parentElement.getAttribute('data-tab');
    }
};

onMounted(() =>
{
    $events.$on('error-occured', onErrorOccured);
});

onBeforeUnmount(() =>
{
    $events.$off('error-occured', onErrorOccured);
});
</script>

<template>
    <ul :class="navClasses">
        <li class="nav-item" v-for="item in props.tabs" :key="item.value">
            <a :class="navLinkClasses(item.value)" href="#" @click.stop.prevent="onChange(item)">
                <i :class="item.icon" v-if="item.icon" class="me-2"></i>
                <span>{{ $t(item.text) }}</span>
            </a>
        </li>
        <li class="nav-item" v-if="$slots.end">
            <slot name="end"></slot>
        </li>
    </ul>
</template>

<script setup lang="ts">
import { ref, computed, useAttrs, defineModel, watch } from 'vue';
import TreeSelect from 'primevue/treeselect';

defineOptions({
    name: 'ideo-form-tree-select',
    components: {
        'tree-select': TreeSelect
    }
});

const model = defineModel<number>({ default: null });

const props = withDefaults(defineProps<{
    fluid?: boolean,
    buildOptions?: boolean,
    options?: any[],
    emptyOption?: string
}>(), {
    fluid: true,
    options: () => ([]),
    emptyOption: ''
});

const attrs = useAttrs();

const selected = ref();

watch(model, (value) =>
{
    selected.value = isNaN(value) ? {null: true} : {[value]: true};
}, { immediate: true });

const handleChange = (value: any): void =>
{
    const key = Object.keys(value)[0];

    model.value = key === 'null' ? null : parseInt(key);
};

// Build tree is optional - you can pass premade options
const buildTree = (data) =>
{
    if (!data)
        return;

    const map = {};
    const result = [];

    data.forEach(item =>
    {
        const id = item.id === 0 ? item.folderId : item.id;
        const parentId = item.parentId === null ? (item.id === 0 ? null : item.folderId) : item.parentId; // Use folderId if parentId is null

        map[id] = {
            key: id,
            label: item.name,
            leaf: !item.hasChildren,
            selectable: item.hasChildren || parentId !== null,
            children: []
        };
    });

    data.forEach(item =>
    {
        const id = item.id === 0 || item.id === null ? item.folderId : item.id;
        const parentId = item.parentId === null ? (item.id === 0 ? null : item.folderId) : item.parentId;

        if (parentId !== null && parentId !== undefined && map[parentId])
        {
            map[parentId]?.children.push(map[id]);
        }
        else
        {
            result.push(map[id]);
        }
    });

    if (props.emptyOption?.length)
        return [{ key: null, label: props.emptyOption },...result];

    return result;
};

const options = computed(() =>
{
    if (props.buildOptions)
        return buildTree(props.options);

    return props.options;
});
</script>

<template>
    <tree-select
        :fluid="fluid"
        v-model="selected"
        @change="handleChange"
        v-bind="attrs"
        :options="options"
    />
</template>

<style lang="scss">
.p-tree-root-children {
    margin-bottom: 0;
    padding-left: 0;
}

.p-treeselect-overlay {
    z-index: 10314 !important;
}
</style>

import { Plugin, getCurrentInstance } from "vue";
import ModulesService, { ProcessEnabledModel } from "@/modules/wms/common/services/ModulesService";
import ModulesServices, { SitemapElement } from "@/modules/wms/additional-modules/services/ModulesServices";
import { SitemapNode } from "./sitemap";

export interface WmsModules
{
    get(modules: Record<string, boolean>): Promise<Record<string, boolean>>;
    check(modulesToCheck: string[]): Promise<ProcessEnabledModel[]>;
    updateSitemap(items: SitemapNode[]) : Promise<void>;
}

class WmsModulesHelper implements WmsModules
{
    private wmsModules: ProcessEnabledModel[];

    public async get(modules: Record<string, boolean>): Promise<Record<string, boolean>>
    {
        const response = await ModulesService.getActiveStatus();

        const items = response.result;

        this.wmsModules = response.result;

        items.forEach(module =>
        {
            modules[module.moduleName] = module.value;
        });

        return modules;
    }

    public async check(modulesToCheck: string[]) : Promise<ProcessEnabledModel[]>
    {
        if (!this.wmsModules)
        {
            const modules: Record<string, boolean> = {};

            modulesToCheck.forEach(x => modules[x] = false);

            await this.get(modules);
        }

        return this.wmsModules.filter(x => modulesToCheck.includes(x.moduleName));
    }

    public async updateSitemap(items: SitemapNode[]) : Promise<void>
    {
        const response = await ModulesServices.getSitemap();
        const root = items.find(x => x.name == '[[[Dodatkowe moduły]]]');

        const children = response.map(p =>
        {
            const module: SitemapNode = {
                name: p.name,
                route: "additional-modules-main",
                routeParams: {
                    symbol: [p.route]
                },
                icon: `far fa-fw ${p.icon}`,
                auth: {any: [], all: []},
                visible: p.visible,
                allowed: true,
                children: []
            };

            if (p.childrens && p.childrens.length > 0)
            {
                module.children = this.prepareChildrens(module, p.childrens);
            }

            return module;
        });

        root.children = children;
    }

    private prepareChildrens(parent: SitemapNode, childrens: SitemapElement[]) : SitemapNode[]
    {
        const submodules: SitemapNode[] = childrens.map((q, i) =>
        {
            const submodule: SitemapNode = {
                name: q.name,
                route: "additional-modules-main",
                routeParams: {
                    symbol: [q.route]
                },
                icon: `far fa-fw ${q.icon}`,
                auth: {any: [], all: []},
                visible: q.visible,
                allowed: true,
                children: []
            };

            if (q.childrens && q.childrens.length > 0)
            {
                submodule.children = this.prepareChildrens(submodule, q.childrens);
            }

            return submodule;
        });

        return submodules;
    }
}

const WmsModulesPlugin: Plugin =
{
    install(app, options)
    {
        app.config.globalProperties.$wmsmodules = new WmsModulesHelper();
    }
};

export const useWmsModules = () =>
{
    const app = getCurrentInstance();

    return {
        $wmsmodules: app.appContext.config.globalProperties.$wmsmodules
    };
};

export default WmsModulesPlugin;

declare module "@vue/runtime-core"
{
    interface ComponentCustomProperties
    {
        $wmsmodules: WmsModules;
    }
}

import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { ValidatableBlueprint } from '@/components/builder/base/blueprints/ValidatableBlueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { FormBuilderContract } from '@/components/builder/form';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { CustomErrorBlueprint } from '@/components/builder/form/blueprints/CustomErrorBlueprint';
import { ReadonlyBlueprint } from '@/components/builder/form/blueprints/ReadonlyBlueprint';
import { RequiredBlueprint } from '@/components/builder/form/blueprints/RequiredBlueprint';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { Entry, entry, instanceOfEntry } from '@/components/builder/form/entries/Entry';
import { ValidEntry } from '@/components/builder/form/entries/ValidEntry';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { InternallyChoice } from '@/components/builder/form/enums/InternallyChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';
import { HasFilter } from '@/components/builder/form/traits/HasFilter';
import { HasHelp } from '@/components/builder/form/traits/HasHelp';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { HasPlaceholder } from '@/components/builder/form/traits/HasPlaceholder';
import { ChoiceOption } from '@/components/builder/form/types/ChoiceOption';
import { ProcessCallback } from '@/components/builder/form/types/ProcessCallback';
import { ChoiceFieldTypes } from '../choice';
import { MinMaxValue } from '@/components/builder/form/types/MinMaxValue';

export const Definition: BlueprintDefinition = {
    type: 'choice-plus',
    name: '[[[Wybór+]]]',
    icon: 'fa-list-check',
    group: 'special',
    position: 10
};

const FREE_ANSWER_VALUE = '-1';

export {
    ChoiceFieldTypes,
    FREE_ANSWER_VALUE
};

export class ChoicePlusEntryData
{
    public values: string[] = null;
    public answer: string = null;
}

export class ChoicePlusEntry extends ValidEntry<ChoicePlusEntryData>
{
    public type: string = Definition.type;
    public data: ChoicePlusEntryData = {
        values: null,
        answer: null
    };

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.data = data;
        }
    }

    public get value(): string
    {
        return this.data.values && this.data.values.length > 0 ? this.data.values[0] : null;
    }

    public async collect(blueprint: ChoicePlusContract, form: FormBuilderContract, preprocess: ProcessCallback): Promise<Entry>
    {
        const result = await preprocess(blueprint, this, form.blueprintId, form.entryId);

        return entry({
            type: this.type,
            data: {
                values: this.getValues(blueprint, form),
                answer: this.data.answer
            },
            ...(result ?? {})
        });
    }

    public validate(blueprint: ChoicePlusContract, form: FormBuilderContract): boolean
    {
        this.errors = {};

        const values = this.getValues(blueprint, form);
        const answer = this.data.answer || '';

        if (!form.expressions.readonly(blueprint, true) && form.expressions.visible(blueprint, true))
        {
            const required = form.expressions.required(blueprint);

            if (required && (values == null || values.length == 0))
            {
                this.errors.value = [`[[[Pole "%0" jest wymagane.|||${form.localization.translate(blueprint.label)}]]]`];
            }
            else if (values.includes(FREE_ANSWER_VALUE) && answer.length < blueprint.characters.min)
            {
                this.errors.answer = [`[[[Nie podano wymaganej ilości znaków: %0.|||${blueprint.characters.min}]]]`];
            }
            else if (values.includes(FREE_ANSWER_VALUE) && blueprint.characters.max != null && answer.length > blueprint.characters.max)
            {
                this.errors.answer = [`[[[Przekroczono dozwoloną ilość znaków: %0.|||${blueprint.characters.max}]]]`];
            }
            else if (form.expressions.customError(blueprint))
            {
                this.errors.custom = [form.expressions.customErrorMessage(blueprint, form)];
            }
        }

        return this.valid();
    }

    private getValues(blueprint: ChoicePlusContract, form: FormBuilderContract): string[]
    {
        if (this.data.values !== null)
            return this.data.values;

        if (blueprint.defaultValue)
            return [form.expressions.executeExpression(blueprint.defaultValue)];

        return blueprint.options.filter(p => p.selected).map(p => p.value);
    }
}

export const instanceOfChoicePlusEntry = (object: any): object is ChoicePlusEntry =>
{
    return instanceOfEntry(object) && 'type' in object && object.type === Definition.type;
};

export interface ChoicePlusContract extends Blueprint, VisibleBlueprint, ReadonlyBlueprint, RequiredBlueprint, CustomErrorBlueprint, HasLabel, HasPlaceholder, HasHelp, HasWidth, HasFilter
{
    fieldType: ChoiceFieldTypes;
    options: ChoiceOption[];
    defaultValue: string;
    layout: number;
    freeAnswerLabel: Record<string, string>;
    characters: MinMaxValue;
}

export class ChoicePlusType implements ChoicePlusContract, ValidatableBlueprint, EntryFactory<ChoicePlusEntry>
{
    public id: string;
    public type: string;
    public name: string;
    public label: Record<string, string>;
    public showLabel: boolean;
    public placeholder: Record<string, string>;
    public help: Record<string, string>;
    public minWidth: number;
    public width: number;
    public fieldType: ChoiceFieldTypes;
    public options: ChoiceOption[];
    public defaultValue: string;
    public layout: number;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public required: AlwaysChoice | NeverChoice | WhenChoice;
    public requiredWhen: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: Record<string, string>;
    public errors: ValidationErrors;
    public showFilter: boolean;
    public freeAnswerLabel: Record<string, string>;
    public characters: MinMaxValue;

    public constructor(id: string, name: string)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.label = { 'pl-PL': 'Wybór' };
        this.showLabel = true;
        this.placeholder = {};
        this.options = [
            { value: '1', text: { 'pl-PL': 'Primus...' }, selected: false },
            { value: '2', text: { 'pl-PL': 'Secundus...' }, selected: false },
            { value: '3', text: { 'pl-PL': 'Tetrium...' }, selected: false }
        ];
        this.layout = 0;
        this.defaultValue = '';
        this.help = {};
        this.fieldType = ChoiceFieldTypes.Dropdown;
        this.minWidth = 1;
        this.width = 0;
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.customErrorMessage = {};
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.required = NeverChoice.Never;
        this.requiredWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
        this.showFilter = false;
        this.freeAnswerLabel = {};
        this.characters = { min: 0, max: 50 };
    }

    // public getFreeAnswerLabel(): string { return this.freeAnswerLabel || '[[[Odpowiedź dowolna]]]'; }

    public setDefaultWidth(width: number): void
    {
        this.width = Math.min(3, Math.max(this.minWidth, width));
    }

    public createEntry(data: any): ChoicePlusEntry
    {
        return new ChoicePlusEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        return {
            [this.name]: this.errors
        };
    }
}

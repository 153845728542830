<script lang="ts" setup>
import { computed, ref } from 'vue';
import { Option } from '@/helpers/Interfaces';
import IdeoComboBoxSelect from './IdeoComboBoxSelect.vue';
import IdeoComboBoxMultiple from './IdeoComboBoxMultiple.vue';
import IdeoComboBoxDropdown from './IdeoComboBoxDropdown.vue';
import IdeoComboBoxInline from './IdeoComboBoxInline.vue';

type BaseType = string|number;
type ArrayType = BaseType[];
type ModelType = BaseType|ArrayType;
type FetchSingle = (id: BaseType) => Promise<Option<BaseType>>;
type FetchMultiple = (ids: ArrayType) => Promise<Option<BaseType>[]>;
type Fetch = FetchSingle|FetchMultiple;
type Search = (query: string, limit: number) => Promise<Option<BaseType>[]>;

const model = defineModel<ModelType>();
const query = defineModel<string>('query', {required: false, default: ''});
const display = defineModel<string>('display', {required: false, default: ''});
const props = withDefaults(defineProps<{
    name: string;
    placeholder?: string,
    footer?: boolean,
    options?: Record<string, any>[],
    valueField?: string,
    textField?: string,
    size?: number,
    default?: boolean,
    defaultLabel?: string,
    multiple?: boolean;
    fetch?: Fetch;
    search?: Search,
    inline?: boolean
}>(), {
    placeholder: '[[[Wyszukaj...]]]',
    footer: true,
    options: (): [] => ([]),
    valueField: 'value',
    textField: 'text',
    size: 10,
    default: true,
    defaultLabel: '[[[Brak wyboru]]]',
    multiple: false,
    fetch: undefined,
    search: undefined,
    inline: false
});
const emit = defineEmits<{
    (e: 'change', value: Option<BaseType>|Option<BaseType>[]): void
}>();

const proxy = computed<any>({
    get() { return model.value; },
    set(value) { model.value = value; }
});
const fetch: any = props.fetch;
const search: any = props.search;
const component = () => props.multiple ? IdeoComboBoxMultiple : IdeoComboBoxSelect;
const container = () => props.inline ? IdeoComboBoxInline  : IdeoComboBoxDropdown;

function onChange(value: Option<BaseType>|Option<BaseType>[])
{
    emit('change', value);
}

function onDisplay(value: string)
{
    display.value = value;
}
</script>

<template>
    <component :is="container()" :display="display">
        <component
            :is="component()"
            v-model="proxy"
            v-model:query="query"
            :name="props.name"
            :placeholder="$t('[[[Wyszukaj...]]]')"
            :footer="props.footer"
            :options="props.options"
            :value-field="props.valueField"
            :text-field="props.textField"
            :size="props.size"
            :default="props.default"
            :default-label="props.defaultLabel"
            :fetch="fetch"
            :search="search"
            @change="onChange"
            @display="onDisplay"
        >
            <template #label="{option, index}">
                <slot name="label" :option="option" :index="index"></slot>
            </template>
            <template #header>
                <slot name="header"></slot>
            </template>
            <template #footer>
                <slot name="footer"></slot>
            </template>
        </component>
    </component>
</template>

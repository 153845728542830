<template>
    <div class="inline">
        <slot name="default"></slot>
    </div>
</template>

<style scoped>
.inline {
    background-color: var(--bs-body-bg);
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
}
.inline:has(:focus) {
    border-color: var(--bs-primary-border-subtle);
}
</style>

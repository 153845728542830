export function isImage(file: File): boolean
{
    return file.type.match(/image.*/) != null;
}

export function convertImage(file: File, type: string, quality: number = 0.75): Promise<File>
{
    return new Promise((ok, no) =>
    {
        const image = new Image();
        const reader = new FileReader();

        reader.onload = (readerEvent: any) =>
        {
            if (isImage(file))
            {
                image.onload = () =>
                {
                    const canvas = document.createElement('canvas');

                    canvas.width = image.width;
                    canvas.height = image.height;
                    canvas.getContext('2d').drawImage(image, 0, 0, image.width, image.height);
                    canvas.toBlob((blob) =>
                    {
                        const name = `${file.name.substring(0, file.name.lastIndexOf("."))}.${type}`;
                        const result = new File([blob], name, { type: blob.type });

                        ok(result);
                    },
                    `image/${type}`,
                    quality);
                };
                image.src = readerEvent.target.result;
            }
            else
            {
                no(new Error("Not an image."));
            }
        };
        reader.readAsDataURL(file);
    });
}

export function resizeImage(file: File, maxSize: number, type: string = null, quality: number = 0.75): Promise<File>
{
    return new Promise((ok, no) =>
    {
        const image = new Image();
        const reader = new FileReader();

        const ext = type == null ? file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length) : type;
        const mime = type == null ? file.type : `image/${type}`;

        reader.onload = (readerEvent: any) =>
        {
            if (isImage(file))
            {
                image.onload = () =>
                {
                    const canvas = document.createElement('canvas');

                    let width = image.width;
                    let height = image.height;

                    if (width > height)
                    {
                        if (width > maxSize)
                        {
                            height *= maxSize / width;
                            width = maxSize;
                        }
                    }
                    else
                    {
                        if (height > maxSize)
                        {
                            width *= maxSize / height;
                            height = maxSize;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;
                    canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                    canvas.toBlob((blob) =>
                    {
                        const name = `${file.name.substring(0, file.name.lastIndexOf("."))}.${ext}`;
                        const result = new File([blob], name, { type: blob.type });

                        ok(result);
                    },
                    mime,
                    quality);
                };
                image.src = readerEvent.target.result;
            }
            else
            {
                no(new Error("Not an image."));
            }
        };
        reader.readAsDataURL(file);
    });
}

import { axios } from '@/plugins/axios';
import { Statement } from '@/helpers/Interfaces';

/**
 * AiService
 */
export default class AiService
{
    /**
     * @param model Record<string, any>
     *
     * @returns Promise<Statement>
     */
    public static async testSettings(model: Record<string, any>): Promise<Statement>
    {
        return (await axios.post<Statement>(`admin/ai/test`, model)).data;
    }

    /**
     * @returns Promise<boolean>
     */
    public static async isEnabled(): Promise<boolean>
    {
        return (await axios.get<boolean>('admin/ai/aienabled')).data;
    }

    /**
     * @returns Promise<AiResponseUsage>
     */
    public static async getOpenAiUsage(): Promise<AiResponseUsage>
    {
        return (await axios.get<AiResponseUsage>('admin/ai/openaiusage')).data;
    }

    /**
     * @returns Promise<OpenAiResponsePricing>
     */
    public static async getOpenAiPricing(): Promise<OpenAiResponsePricing>
    {
        return (await axios.get<OpenAiResponsePricing>('admin/ai/openaipricing')).data;
    }
}

export interface AiResponseUsage {
    limitInDollars: number;
    sumPriceInDollars: number;
    remainingLimitInDollars: number;
}

export interface OpenAiResponsePricing {
    gpt4VisionPreviewInput: string,
    gpt4VisionPreviewOutput: string,
    dalle3StandardSquare1024: string,
    dalle3StandardRectangle1792: string,
    dalle3HdSquare1024: string,
    dalle3HdRectangle1792: string
}

export interface OpenAiPricing {
    '1024x1024_standard': string,
    '1024x1792_standard': string,
    '1792x1024_standard': string,
    '1024x1024_hd': string,
    '1024x1792_hd': string,
    '1792x1024_hd': string,
}

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, computed, watch } from 'vue';
import AuthService, { Substitution } from '@/modules/core/auth/services/AuthService';
import { useSignalR } from '@/plugins/signalr';
import { DateTime, Duration } from 'luxon';
import { useAuth } from '@/plugins/auth';
import { useAlerts } from '@/plugins/alerts';
import { useLocalization } from '@/plugins/localization';
import { Option } from '@/helpers/Interfaces';
import { useMixins } from '@/plugins/mixins';

defineOptions({
    name: 'Substitutions'
});

const { $auth } = useAuth();
const { $alert } = useAlerts();
const { $t } = useLocalization();
const { $redirect } = useMixins();
const { channel } = useSignalR();
const server = channel('substitutions');

const substitutions = ref<Substitution[]>([]);
const interval = ref<any>(null);
const now = ref<DateTime | null>(null);

const me = computed(() => $auth.user()?.id);
const current = computed({
    get: () => $auth.impersonating() || me.value,
    set: (value: number) =>
    {
        if (value != me.value)
        {
            $auth.impersonate(value);
        }
        else
        {
            $auth.unimpersonate();
        }
    },
});

const options = computed<Option<number>[]>(() =>
{
    return substitutions.value
        .filter(p => p.intervals.filter(q => q.dateBeginUtc <= now.value && now.value <= q.dateEndUtc).length > 0)
        .map(p => ({ value: p.id, text: p.name }));
});

const dashboard = () =>
{
    $redirect({ name: 'dashboard' }, () =>
    {
        $auth.reload();
    });
};

const checkSubstitutionInterval = (userId: number, time: DateTime) =>
{
    const substitution = substitutions.value.find(p => p.id == userId);

    if (substitution)
    {
        const interval = substitution.intervals.find(q => q.dateBeginUtc <= time && time <= q.dateEndUtc);

        if (interval)
        {
            const ttl = interval.dateEndUtc.minus(Duration.fromObject({ seconds: 15 })).diff(time);

            switch (Math.ceil(ttl.as('minutes')))
            {
                case 10:
                    $alert.info($t('[[[Zastępstwo wygaśnie za 10 minut.]]]'));
                    break;
                case 5:
                    $alert.info($t('[[[Zastępstwo wygaśnie za 5 minut.]]]'));
                    break;
                case 1:
                    $alert.danger($t('[[[Zastępstwo wygaśnie za minutę.]]]'));
                    break;
                case 0:
                    $alert.danger($t('[[[Zastępstwo wygasło.]]]'));
                    $auth.unimpersonate();
                    dashboard();
                    break;
            }
        }
    }
};

onMounted(async () =>
{
    now.value = DateTime.utc();

    interval.value = setInterval(() =>
    {
        now.value = DateTime.utc();
    }, 5 * 1000);

    await onReloadSubstitutions();
});

onBeforeUnmount(() =>
{
    if (interval.value)
    {
        clearInterval(interval.value);
    }
});

watch(current, (): void =>
{
    dashboard();
});

watch(now, (value: DateTime, old: DateTime): void =>
{
    if (value && old && value.minute !== old.minute)
    {
        if (current.value !== me.value)
        {
            const time = DateTime.fromObject(
                { ...value.toObject(), ...{ second: 0, millisecond:0 } },
                { zone: 'utc' }
            );

            checkSubstitutionInterval(current.value, time);
        }
    }
});

const onReloadSubstitutions = async (): Promise<void> =>
{
    try
    {
        substitutions.value = await AuthService.getSubstitutions();
    }
    catch (ex)
    {
        // ...
    }
};

server.on.reloadSubstitutions = async (): Promise<void> =>
{
    await onReloadSubstitutions();
};
</script>

<template>
    <template v-if="options.length > 0">
        <div class="mx-3 my-2">
            <ideo-form-select
                name="substitutions"
                :class="{'border-danger': $auth.impersonating()}"
                v-model="current"
                :options="[{value: me, text: $t('[[[Moje konto]]]') }, ...options]"
                @click.stop
            >
            </ideo-form-select>
        </div>
        <div class="dropdown-divider"></div>
    </template>
</template>

<style lang="scss" scoped>
select.form-control {
    width: 95%;
    margin-left: 5px;
}
</style>

<script lang="ts" setup>
import { ref, provide } from 'vue';
import { AccordionProps } from './types';

const props = withDefaults(defineProps<AccordionProps>(), {
    headerTag: 'h2',
    headerClass: 'py-3 fs-5 fw-normal',
    headerClassActive: 'text-primary',
    itemClass: 'border-bottom border-light',
    itemClassActive: '',
    iconClass: 'me-4',
    labelClass: '',
    contentClass: 'px-0 py-2',
    alwaysOpen: false,
    border: false,
    icon: true
});

const opened = ref<string[]>([]);

provide('accordion', props);
provide('opened', opened);
</script>

<template>
    <div :class="{'accordion': props.border}">
        <slot name="default"></slot>
    </div>
</template>

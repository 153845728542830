<script lang="ts" setup>
defineOptions({
    name: 'custom-toast'
});

const props = defineProps<{
    message: string;
    update: string;
}>();

const emit = defineEmits<{
    (e: 'reload'): void;
}>();

const onReload = (): void =>
{
    emit('reload');
};
</script>

<template>
    <div>
        <div class="mb-2">{{ message }}</div>
        <div>
            <button class="btn btn-light" @click.stop="onReload">
                {{ update }}
            </button>
        </div>
    </div>
</template>

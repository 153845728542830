<script lang="ts" setup>
import { computed } from 'vue';

defineOptions({
    name: 'ideo-button'
});

const props = withDefaults(defineProps<{
    variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' | 'link' | 'none' | 'outline-primary' | 'outline-secondary' | 'outline-success' | 'outline-danger' | 'outline-warning' | 'outline-info' | 'outline-light' | 'outline-dark';
    size?: 'sm'|'md'|'lg';
    block?: boolean;
    pill?: boolean;
    squared?: boolean;
    disabled?: boolean;
    icon?: string;
    link?: string;
    loading?: boolean;
    noBorder?: boolean;
}>(), {
    variant: 'secondary',
    size: 'md',
    icon: null,
    link: null,
});

const customClasses = computed((): Record<string, boolean> => ({
    'btn': true,
    [`btn-${props.variant}`]: props.variant !== 'none',
    'w-100': props.block,
    'rounded-pill': props.pill,
    'rounded-0': props.squared,
    'btn-sm': props.size === 'sm',
    'btn-lg': props.size === 'lg',
    'btn-no-border': props.noBorder
}));
</script>

<template>
    <component :is="link ? 'router-link' : 'button'" :to="link" type="button" :class="customClasses" :disabled="disabled || loading">
        <i :class="[icon, {'me-2': $slots.default}]" v-if="icon"></i>
        <slot name="default"></slot>

        <div v-show="loading" class="overflow">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </div>
    </component>
</template>

<style lang="scss">
.btn {
    position: relative;
    cursor: pointer;

    &-no-border {
        border: none !important;
    }

    &-subtle {
        &.btn-success, &.btn-outline-success {
            background: var(--bs-success-bg-subtle);
            color: var(--bs-success-text-emphasis);
        }

        &.btn-secondary, &.btn-outline-secondary {
            background: var(--bs-secondary-bg-subtle);
            color: var(--bs-secondary-text-emphasis);
        }

        &.btn-primary, &.btn-outline-primary {
            background: var(--bs-primary-bg-subtle);
            color: var(--bs-primary-text-emphasis);
        }

        &.btn-info, &.btn-outline-info {
            background: var(--bs-info-bg-subtle);
            color: var(--bs-info-text-emphasis);
        }

        &.btn-warning, &.btn-outline-warning {
            background: var(--bs-warning-bg-subtle);
            color: var(--bs-warning-text-emphasis);
        }

        &.btn-danger, &.btn-outline-danger {
            background: var(--bs-danger-bg-subtle);
            color: var(--bs-danger-text-emphasis);
        }
    }

    .overflow {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: inherit;
    }
}
</style>

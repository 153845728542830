export default class ChatbotService
{
    public static async generateToken(secretKey: string): Promise<DirectLineTokenModel>
    {
        const response = await fetch('https://directline.botframework.com/v3/directline/tokens/generate', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${secretKey}`,
                'Content-Type': 'application/json'
            }
        });

        const data = await response.json();

        return data;
    }
}

export interface DirectLineTokenModel {
    conversationId: string
    token: string
    expires_in: number
}
<script setup lang="ts">
import { computed } from 'vue';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import forEach from 'lodash/forEach';
import Draggable from 'vuedraggable';
import { TreeNode } from '../utils';

defineOptions({
    name: 'ideo-tree-nodes',
    components: {
        'draggable': Draggable
    }
});

const props = defineProps<{
    parent?: TreeNode;
    pages: TreeNode[];
    expanded: number[];
    menu: null|((node: TreeNode) => Promise<any[]>);
    active: null|((node: TreeNode) => boolean);
    move: (e: any) => boolean;
    disabled?: boolean;
}>();

const emit = defineEmits<{
    (e: 'toggle', node: TreeNode): void;
    (e: 'click', node: TreeNode): void;
    (e: 'sort', pages: TreeNode[]): void;
}>();

const isExpanded = computed(() =>
{
    return props.parent == null || (props.parent.hasChildren && props.expanded.indexOf(props.parent.id) >= 0);
});

const onToggle = (node: TreeNode): void =>
{
    emit('toggle', node);
};

const onClick = (node: TreeNode): void =>
{
    emit('click', node);
};

const onSort = (pages: TreeNode[]): void =>
{
    emit('sort', pages);
};

const children = computed({
    get(): TreeNode[]
    {
        return sortBy(
            filter(props.pages, p => p.parentId === (props.parent ? props.parent.id : null)), s => s.position
        );
    },
    set(pages: TreeNode[])
    {
        forEach(pages, (page: any, i: number) =>
        {
            page.position = i + 1;
        });

        onSort(pages);
    }
});

</script>

<template>
    <draggable v-model="children" :move="move" item-key="id" tag="ul" class="w-100" v-if="isExpanded" :disabled="disabled">
        <template #item="{element}">
            <ideo-tree-node :pages="pages" :expanded="expanded" :node="element" :menu="menu" :active="active" :move="move" @toggle="onToggle" @click="onClick" @sort="onSort" :disabled="disabled">
                <template #toggle="{node}">
                    <slot name="toggle" :node="node"></slot>
                </template>
                <template #icon="{node}">
                    <slot name="icon" :node="node"></slot>
                </template>
                <template #default="{node}">
                    <slot name="default" :node="node"></slot>
                </template>
            </ideo-tree-node>
        </template>
    </draggable>
</template>

import { ref } from 'vue';
import { defineStore } from 'pinia';
import Pager from '@/helpers/Pager';
import WmsSettingsService, { ViewSettingsItemModel, GlobalConfiguration } from '@/modules/wms/configuration/settings/services/WmsSettingsService';
import StoreService from '@/modules/core/marketplace/services/StoreService';

export const useWmsViewsSettingsStore = defineStore('wms-views-settings', () =>
{
    const viewSettings = ref<ViewSettingsItemModel[]>([]);
    const globalConfig = ref<GlobalConfiguration>();
    const chatbotSecretKey = ref('');

    const showField = (fieldName: string): boolean =>
    {
        return viewSettings.value.some(element => element.id === fieldName);
    };

    const isBlockedField = (fieldName: string): boolean =>
    {
        const field = viewSettings.value.find(element => element.id === fieldName);

        return !!(field && field.blocked);
    };

    const resetViewSettings = (): void =>
    {
        viewSettings.value = [];
    };

    const loadGlobalSettings = async (): Promise<void> =>
    {
        try
        {
            const { result } = await WmsSettingsService.getGlobalConfiguration();

            globalConfig.value = result;
        }
        catch (ex: any)
        {
            // @ts-ignore
            console.error(ex);
        }
    };

    const handleChatbotIntegration = async (): Promise<void> =>
    {
        chatbotSecretKey.value = '';

        try
        {
            const installedIntegrationsList = await StoreService.installed(new Pager(1, 200, 'Name', 'ASC'));

            const copilotEnabled = installedIntegrationsList.items.length && installedIntegrationsList.items.find((item) => item.result.id == 'copilot');

            if (copilotEnabled)
            {
                const response = await StoreService.getCopilotSecretKey();

                chatbotSecretKey.value = response.key;
            }
        }
        catch (ex)
        {
            // @ts-ignore
            console.error(ex);
        }
    };

    return {
        viewSettings,
        globalConfig,
        chatbotSecretKey,
        showField,
        isBlockedField,
        resetViewSettings,
        loadGlobalSettings,
        handleChatbotIntegration
    };
});

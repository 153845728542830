<script setup lang="ts">
import { TreeNode } from '../utils';

defineOptions({
    name: 'ideo-tree'
});

withDefaults(defineProps<{
    pages: TreeNode[];
    expanded: number[];
    menu?: (node: TreeNode) => Promise<any[]>;
    active?: (node: TreeNode) => boolean;
    move?: (e: any) => boolean;
    disabled?: boolean;
}>(), {
    move: () => false,
    active: null,
    menu: null
});

const emit = defineEmits<{
    (e: 'toggle', node: TreeNode): void;
    (e: 'click', node: TreeNode): void;
    (e: 'sort', pages: TreeNode[]): void;
}>();

const onToggle = (node: TreeNode): void =>
{
    emit('toggle', node);
};

const onClick = (node: TreeNode): void =>
{
    emit('click', node);
};

const onSort = (pages: TreeNode[]): void =>
{
    emit('sort', pages);
};
</script>

<template>
    <ideo-tree-nodes :pages="pages" :expanded="expanded" :parent="null" :menu="menu" :active="active" :move="move" @toggle="onToggle" @click="onClick" @sort="onSort" :disabled="disabled" class="tree">
        <template #toggle="{node}">
            <slot name="toggle" :node="node"></slot>
        </template>
        <template #icon="{node}">
            <slot name="icon" :node="node"></slot>
        </template>
        <template #default="{node}">
            <slot name="default" :node="node"></slot>
        </template>
    </ideo-tree-nodes>
</template>

<style lang="scss">
[data-bs-theme=dark] .tree {
    li {
        > .active {
            background: var(--bs-gray-500);
        }

        > span {
            a, i {
                color: var(--bs-white);
            }
        }
    }
}
.tree {
    padding-left: 0;
    list-style: none;

    ul {
        padding-left: 1.5em;
        list-style: none;
    }

    li {
        position: relative;

        > .active {
            background: var(--bs-primary-bg-subtle);
            color: var(--bs-gray-800);

            a {
                color: var(--bs-gray-800);
            }

            i {
                color: var(--bs-gray-800);
            }
        }

        .muted {
            opacity: 0.5;
        }

        .node-title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .toggle, .fas {
            flex: none;
        }

        > span {
            height: 28px;
            border-radius: 5px;
            padding: 0 0.625rem;

            a {
                margin-left: 0.625rem;
                color: var(--bs-gray-600);
            }

            i {
                font-size: 12px;
                color: var(--bs-gray-600);
                min-width: 5px;

                &.toggle {
                    width: 16px;
                    font-size: 8px;
                    padding: 5px 0;
                    cursor: pointer;
                }
            }
        }

        > .popover > .popover-body {
            min-width: 150px;
            padding: 0 !important;
        }
    }
}
</style>

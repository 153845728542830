import Lara from '@primevue/themes/lara';
import { definePreset } from '@primevue/themes';

export default definePreset(Lara, {
    semantic: {
        primary: {
            50: '{orange.50}',
            100: '{orange.50}',
            200: '{orange.100}',
            300: '{orange.200}',
            400: '{orange.300}',
            500: '{orange.400}',
            600: '{orange.500}',
            700: '{orange.600}',
            800: '{orange.700}',
            900: '{orange.800}',
            950: '{orange.900}'
        },
        formField: {
            paddingX: 12,
            paddingY: 6,
            borderRadius: 4,
            focusRing: {
                width: '0',
                style: 'none',
                color: 'transparent',
                offset: '0',
                shadow: 'none'
            }
        }
    },
    components: {
        checkbox: {
            colorScheme: {
                light: {
                    root: {
                        width: 16,
                        height: 16
                    },
                    icon: {
                        size: 10
                    }
                },
                dark: {
                    root: {
                        width: 16,
                        height: 16
                    },
                    icon: {
                        size: 10
                    }
                }
            }
        },
        radiobutton: {
            colorScheme: {
                light: {
                    root: {
                        width: 16,
                        height: 16
                    },
                    icon: {
                        size: 10
                    }
                },
                dark: {
                    root: {
                        width: 16,
                        height: 16
                    },
                    icon: {
                        size: 10
                    }
                }
            }
        },
        toggleswitch: {
            colorScheme: {
                light: {
                    root: {
                        width: 24,
                        height: 16
                    },
                    handle: {
                        size: 10
                    }
                },
                dark: {
                    root: {
                        width: 24,
                        height: 16
                    },
                    handle: {
                        size: 10
                    }
                }
            }
        },
        datatable: {
            colorScheme: {
                light: {
                    headerCell: {
                        padding: '6px 12px',
                    },
                    bodyCell: {
                        padding: '4px 12px',
                    },
                    columnTitle: {
                        fontWeight: 600
                    }
                },
                dark: {}
            }
        },
        treeselect: {
            colorScheme: {
                light: {
                    background: 'var(--bs-tertiary-bg)',
                    padding: {
                        x: '0.75rem',
                        y: '0.375rem'
                    },
                    transition: {
                        duration: 0
                    },
                    border: {
                        color: 'var(--bs-border-color)',
                        radius: 'var(--bs-border-radius)',
                    },
                    dropdown: {
                        width: '32px',
                    },
                    hover: {
                        border: {
                            color: 'var(--bs-border-color)',
                        }
                    },
                    focus: {
                        border: {
                            color: 'var(--bs-border-color)',
                        }
                    },
                    invalid: {
                        border: {
                            color: 'var(--bs-danger)'
                        }
                    },
                    overlay: {
                        background: 'var(--bs-tertiary-bg)',
                        shadow: 'var(--bs-box-shadow)',
                        border: {
                            color: 'var(--bs-border-color)',
                            radius: 'var(--vs-border-radius)',
                        }
                    },
                    tree: {
                        padding: '4px'
                    }
                },
                dark: {}
            }
        }
    }
});

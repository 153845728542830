/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import EmptyLayout from '@/views/layouts/EmptyLayout.vue';

export default [
    {
        path: 'additional-modules',
        redirect: '/error/404',
        component: EmptyLayout,
        meta: { auth: true },
        children: [
            {
                path: ':symbol([a-z-]+)+',
                name: 'additional-modules-main',
                meta: { auth: true },
                component: () => import('@/modules/wms/additional-modules/views/Component.vue'),
            },
            {
                path: ':symbol([a-z-]+)+/:id([\\w-]+)',
                name: 'additional-modules-edit',
                meta: { auth: true },
                component: () => import('@/modules/wms/additional-modules/views/Component.vue'),
            }
        ]
    },
];

import { debounce } from 'lodash';
import { getCurrentInstance, Plugin } from 'vue';
import { createToastInterface, POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

const DEBOUNCE_TIME = 1000;
const DEBOUNCE_DEFAULT_OPTIONS = { leading: true, trailing: false };

export interface Alerts
{
    close(): void;
    success(message: string, options?: any): void;
    debounceSuccess(message: string, options?: any): void;
    info(message: string, options?: any): void;
    debounceInfo(message: string, options?: any): void;
    warning(message: string, options?: any): void;
    debounceWarning(message: string, options?: any): void;
    danger(message: string, options?: any): void;
    debounceDanger(message: string, options?: any): void;
    error(message: string, options?: any): void;
    debounceError(message: string, options?: any): void;
    show(message: any, options?: any): void;
    debounceShow(message: any, options?: any): void;
}

class MessagesHelper implements Alerts
{
    private toast: any;

    public constructor(toast: any)
    {
        this.toast = toast;
    }

    public close(): void
    {
        this.toast.clear();
    }

    // --------------------------------------------------

    public success(message: string, options: any = {}): void
    {
        this.toast.success(message, options);
    }

    public debounceSuccess = debounce((message: string, options: any = {}) =>
    {
        this.success(message, options);
    },
    DEBOUNCE_TIME, DEBOUNCE_DEFAULT_OPTIONS);

    // --------------------------------------------------

    public info(message: string, options: any = {}): void
    {
        this.toast.info(message, options);
    }

    public debounceInfo = debounce((message: string, options: any = {}) =>
    {
        this.info(message, options);
    },
    DEBOUNCE_TIME, DEBOUNCE_DEFAULT_OPTIONS);

    // --------------------------------------------------

    public warning(message: string, options: any = {}): void
    {
        this.toast.warning(message, options);
    }

    public debounceWarning = debounce((message: string, options: any = {}) =>
    {
        this.warning(message, options);
    },
    DEBOUNCE_TIME, DEBOUNCE_DEFAULT_OPTIONS);

    // --------------------------------------------------

    public danger(message: string, options: any = {}): void
    {
        this.toast.error(message, options);
    }

    public debounceDanger = debounce((message: string, options: any = {}) =>
    {
        this.danger(message, options);
    },
    DEBOUNCE_TIME, DEBOUNCE_DEFAULT_OPTIONS);

    // --------------------------------------------------

    public error(message: string, options: any = {}): void
    {
        this.toast.error(message, options);
    }

    public debounceError = debounce((message: string, options: any = {}) =>
    {
        this.error(message, options);
    },
    DEBOUNCE_TIME, DEBOUNCE_DEFAULT_OPTIONS);

    // --------------------------------------------------


    public show(message: any, options: any = {}): void
    {
        this.toast(message, options);
    }

    public debounceShow = debounce((message: any, options: any = {}) =>
    {
        this.show(message, options);
    },
    DEBOUNCE_TIME, DEBOUNCE_DEFAULT_OPTIONS);

    // --------------------------------------------------
}

export const useAlerts = (): {$alert: Alerts} =>
{
    const app = getCurrentInstance();

    return {
        $alert: app.appContext.config.globalProperties.$alert
    };
};

const AlertsPlugin: Plugin =
{
    install(app, options)
    {
        const toast = createToastInterface({
            // eventBus: new Vue(),
            position: POSITION.TOP_CENTER,
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false
        });

        app.config.globalProperties.$alert = new MessagesHelper(toast);
    }
};

export default AlertsPlugin;

declare module "@vue/runtime-core"
{
    interface ComponentCustomProperties
    {
        $alert: Alerts;
    }
}

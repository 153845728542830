import { axios } from '@/plugins/axios';
import merge from 'lodash/merge';
import Pager from '@/helpers/Pager';
import { Pagination, Resource, Statement } from '@/helpers/Interfaces';

export default class StoreService
{
    public static async getList(filter: FilterModel, pager: Pager): Promise<Pagination<Resource<ListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<ListItemModel>>>('admin/integration/marketplace', {
            params: merge({}, filter, pager.data())
        })).data;
    }

    public static async refresh(): Promise<Statement>
    {
        return (await axios.put(`admin/integration/marketplace/refresh`)).data;
    }

    public static async get(id: string): Promise<Resource<MarketplaceEntityInfo>>
    {
        return (await axios.get(`admin/integration/marketplace/${id}`)).data;
    }

    public static async install(id: string, version: string): Promise<Statement>
    {
        return (await axios.post(`admin/integration/marketplace/${id}/install/${version}`));
    }

    public static async reinstall(id: string, version: string): Promise<Statement>
    {
        return (await axios.post(`admin/integration/marketplace/${id}/reinstall/${version}`));
    }

    public static async uninstall(id: string,): Promise<Statement>
    {
        return (await axios.post(`admin/integration/marketplace/${id}/uninstall`));
    }

    public static async installed(pager: Pager): Promise<Pagination<Resource<ListItemModel>>>
    {
        return (await axios.get(`admin/integration/marketplace/installed`, {
            params: merge({}, pager.data())
        })).data;
    }

    public static async configureDefinitions(id: string): Promise<any>
    {
        return (await axios.get(`admin/integration/marketplace/${id}/configure/definition`)).data;
    }

    public static async configure(id: string): Promise<any>
    {
        return (await axios.get(`admin/integration/marketplace/${id}/configure`)).data;
    }

    public static async setConfigure(id: string, model: any): Promise<any>
    {
        return (await axios.post(`admin/integration/marketplace/${id}/configure`, model)).data;
    }

    public static async schedule(id: string): Promise<MarketplaceSchedule[]>
    {
        return (await axios.get(`admin/integration/marketplace/${id}/schedule`)).data;
    }

    public static async scheduleExecute(id: string, jobId: string): Promise<MarketplaceSchedule[]>
    {
        return (await axios.post(`admin/integration/marketplace/${id}/schedule/${jobId}/execute`)).data;
    }

    public static async endpoints(id: string): Promise<MarketplaceEndpoint[]>
    {
        return (await axios.get(`admin/integration/marketplace/${id}/endpoints`)).data;
    }

    public static async getCopilotSecretKey(): Promise<CopilotSecretKeyModel>
    {
        return (await axios.post(`admin/integration/marketplace/copilot/endpoints/secretkey`)).data;
    }
}

export interface ListItemModel
{
    id: string;
    name: string;
    author: string;
    version: string;
    badges: string[];
}

export interface FilterModel
{
    name: string;
}

export interface MarketplaceEntityInfo {
    id: string;
    name: string;
    author: string;
    description: string;
    versions: MarketplaceEntityVersion[];
}

export interface MarketplaceEntityVersion {
    version: string;
    downloadUrl: string;
    entryPointDll: string;
    canBeDownload: boolean;
    changelog: string;
    wmsChangelog: string;
    mesChangelog: string;
    externalLinks: MarketplaceEntityExternalLink[];
    dependencies: MarketplaceEntityDependency[];
}

export interface MarketplaceEntityExternalLink {
    title: string;
    description: string;
    url: string;
}

export interface MarketplaceEntityDependency {
    title: string;
    minVersion: string;
}

export interface MarketplaceSchedule {
    id: string;
    name: string;
    cron: string;
    isEnabled: boolean;
}

export interface MarketplaceEndpoint {
    path: string;
}

export interface Map {
    id: string
    name: string
    type: string
    groups: GroupMap[]
}

export interface GroupMap {
    id: string
    name: string
    properties: PropertyMap[]
}

export interface PropertyMap {
    path: string
}
export interface CopilotSecretKeyModel {
    key: string
}

import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { ValidatableBlueprint } from '@/components/builder/base/blueprints/ValidatableBlueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { FormBuilderContract } from '@/components/builder/form';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { CustomErrorBlueprint } from '@/components/builder/form/blueprints/CustomErrorBlueprint';
import { ReadonlyBlueprint } from '@/components/builder/form/blueprints/ReadonlyBlueprint';
import { RequiredBlueprint } from '@/components/builder/form/blueprints/RequiredBlueprint';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { Entry, entry } from '@/components/builder/form/entries/Entry';
import { ValidEntry } from '@/components/builder/form/entries/ValidEntry';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { InternallyChoice } from '@/components/builder/form/enums/InternallyChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';
import { HasAffix } from '@/components/builder/form/traits/HasAffix';
import { HasFilter } from '@/components/builder/form/traits/HasFilter';
import { HasHelp } from '@/components/builder/form/traits/HasHelp';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { HasPlaceholder } from '@/components/builder/form/traits/HasPlaceholder';
import { AffixValue } from '@/components/builder/form/types/AffixValue';
import { ProcessCallback } from '@/components/builder/form/types/ProcessCallback';

export const Definition: BlueprintDefinition = {
    type: 'url',
    name: '[[[Adres URL]]]',
    icon: 'fa-link',
    group: 'special',
    position: 2
};

export class UrlEntry extends ValidEntry<string>
{
    public type: string = Definition.type;
    public data: string = null;

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.data = data;
        }
    }

    public async collect(blueprint: UrlContract, form: FormBuilderContract, preprocess: ProcessCallback): Promise<Entry>
    {
        const result = await preprocess(blueprint, this, form.blueprintId, form.entryId);

        return entry({
            type: this.type,
            data: this.data ?? form.expressions.executeExpression(blueprint.defaultValue),
            ...(result ?? {})
        });
    }

    public validate(blueprint: UrlContract, form: FormBuilderContract): boolean
    {
        this.errors = {};

        const value = this.data ?? form.expressions.executeExpression(blueprint.defaultValue);

        if (!form.expressions.readonly(blueprint, true) && form.expressions.visible(blueprint, true))
        {
            if (form.expressions.required(blueprint) && (value == null || value.length == 0))
            {
                this.errors.value = [`[[[Pole "%0" jest wymagane.|||${form.localization.translate(blueprint.label)}]]]`];
            }
            else if ((value != null && value.length > 0) && !this.validUrl(value))
            {
                this.errors.value = [`[[[Pole "%0" nie zawiera prawidłowego adresu URL.|||${form.localization.translate(blueprint.label)}]]]`];
            }
            else if (form.expressions.customError(blueprint))
            {
                this.errors.custom = [form.expressions.customErrorMessage(blueprint, form)];
            }
        }

        return this.valid();
    }

    private validUrl(url: string): boolean
    {
        try
        {
            // eslint-disable-next-line no-new
            new URL(url);

            return true;
        }
        catch (e)
        {
            return false;
        }
    }
}

export const instanceOfUrlEntry = (object: any): object is UrlEntry =>
{
    return object && 'type' in object && object.type === Definition.type;
};

export interface UrlContract extends Blueprint, VisibleBlueprint, ReadonlyBlueprint, RequiredBlueprint, CustomErrorBlueprint, HasLabel, HasPlaceholder, HasAffix, HasHelp, HasWidth, HasFilter
{
    defaultValue: string;
}

export class UrlType implements UrlContract, ValidatableBlueprint, EntryFactory<UrlEntry>
{
    public id: string;
    public type: string;
    public name: string;
    public label: Record<string, string>;
    public showLabel: boolean;
    public placeholder: Record<string, string>;
    public defaultValue: string;
    public help: Record<string, string>;
    public affix: AffixValue;
    public minWidth: number;
    public width: number;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public required: AlwaysChoice | NeverChoice | WhenChoice;
    public requiredWhen: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: Record<string, string>;
    public errors: ValidationErrors;
    public showFilter: boolean;

    public constructor(id: string, name: string)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.label = { 'pl-PL': 'Adres URL' };
        this.showLabel = true;
        this.placeholder = {};
        this.defaultValue = '';
        this.help = {};
        this.affix = { prepend: '', append: '' };
        this.minWidth = 1;
        this.width = 0;
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.customErrorMessage = {};
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.required = NeverChoice.Never;
        this.requiredWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
        this.showFilter = false;
    }

    public setDefaultWidth(width: number): void
    {
        this.width = Math.min(3, Math.max(this.minWidth, width));
    }

    public createEntry(data: any): UrlEntry
    {
        return new UrlEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        return {
            [this.name]: this.errors
        };
    }
}

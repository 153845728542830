<template>
    <div v-bind="attrs()">
        <VueDraggable
            v-model="items"
            :item-key="(el: any, index: number) => index"
            draggable=".draggable"
            :move="canSort"
        >
            <template #item="{ element: item, index }">
                <list-view-utils-comment :index="index" :item="item" :headers="props.headers">
                    <slot name="row" :item="item" :index="index"></slot>
                </list-view-utils-comment>
            </template>
        </VueDraggable>
        <div v-if="rows.length == 0">
            <div class="text-center p-3 mt-1 mb-3 bg-body-tertiary">{{ $t(emptyLabel) }}</div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { inject, computed, useAttrs } from 'vue';
import VueDraggable from 'vuedraggable';
import Pager from '@/helpers/Pager';
import Comment from '../utils/Comment.vue';
import { only } from '@/helpers/Utils';

export interface Props
{
    showHeader: boolean;
    headers: any[];
    rows: any[];
    pager: Pager;
}

defineOptions({
    name: 'list-view-comment-layout',
    components: {
        'list-view-utils-comment': Comment
    },
    inheritAttrs: false
});

const props = withDefaults(defineProps<Props>(), {});
const emptyLabel = inject<string>('empty-label');
const $attrs = useAttrs();
const attrs = (): Record<string, any> => only($attrs, 'class', 'style');

const onDrag = inject<any>('onDrag');
const draggable = inject<boolean>('draggable');

const canSort = (): boolean =>
{
    return draggable;
};

const items = computed({
    get()
    {
        return props.rows;
    },
    set(value: any[])
    {
        onDrag(value);
    }
});
</script>

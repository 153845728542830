<script lang="ts" setup>
import { Option } from '@/helpers/Interfaces';
import { ref, computed, watch, onMounted, nextTick, onBeforeUnmount } from 'vue';
import { useEvents } from '@/plugins/events';
import debounce from 'lodash/debounce';
import Multiselect from 'vue-multiselect/src/Multiselect.vue';

type ValueType = null|string|number|string[]|number[];

const props = withDefaults(defineProps<{
    name?: string,
    trackBy?: string,
    label?: string,
    placeholder?: string;
    multiple?: boolean,
    disabled?: boolean,
    refresh?: boolean,
    limit?: number,
    modelValue: ValueType,
    defaultLabel?: string,
    labeledInput?: string
    overflow?: boolean
    closeOnSelect?: boolean
    required?: boolean
    fetch: (id: any) => Promise<Option>;
    search: (query: string, limit: number) => Promise<Option[]>,
    emptyOption?: string;
}>(), {
    name: '',
    trackBy: 'value',
    label: 'text',
    placeholder: '',
    multiple: false,
    disabled: false,
    refresh: false,
    limit: 10,
    modelValue: null,
    defaultLabel: null,
    labeledInput: '',
    overflow: false,
    closeOnSelect: true,
    emptyOption: '',
    required: false,
});

const emit = defineEmits<{
    (e: 'update:modelValue', value: ValueType): void
    (e: 'changed', value: null|Record<string, any>|Record<string, any>[]): void
    (e: 'selected', value: null|Record<string, any>|Record<string, any>[]): void
}>();

const  { $events }  = useEvents();

const root = ref(null);
const multiselect = ref(null);
const loading = ref(false);
const opened = ref(false);
const options = ref<Record<string, any>[]>([]);
const selected = ref<null|Record<string, any>|Record<string, any>[]>(null);
const valueModel = computed<number[]>({
    get: (): number[] =>
    {
        return props.multiple
            ? (props.modelValue ?? []) as number[]
            : props.modelValue != null && props.modelValue != ''
                ? [props.modelValue as number]
                : [];
    },
    set: (value: number[]) =>
    {
        if (props.multiple)
            emit('update:modelValue', value);
        else
        {
            emit('update:modelValue', value.first());
            $events.$emit('autocomplete::singleValue::update', value.first());
        }

        emit('changed', selected.value);

        multiselect.value.$el.querySelector('input').dispatchEvent(new Event('input', {
            bubbles: true
        }));
    }
});
const selectedModel = computed<Record<string, any>[]>({
    get: (): Record<string, any>[] =>
    {
        return props.multiple
            ? (selected.value ?? []) as Record<string, any>[]
            : selected.value != null
                ? [selected.value as Record<string, any>]
                : [];
    },
    set: (value: Record<string, any>[]) =>
    {
        if (props.multiple)
            selected.value = value;
        else
            selected.value = value.first();
    }
});

const fetchModel = async (values: number[]): Promise<void> =>
{
    if (values.none())
    {
        selectedModel.value = [];
    }

    values.forEach(value =>
    {
        if (value != null && selectedModel.value.where(p => props.trackBy in p && p[props.trackBy] == value).none())
        {
            props.fetch(value).then(result =>
            {
                if (result != null)
                {
                    if (props.multiple)
                        selectedModel.value = [...selectedModel.value, result];
                    else
                        selectedModel.value = [result];
                }
            });
        }
    });
};

const searchOptions = async (query: string): Promise<void> =>
{
    loading.value = true;
    options.value = await props.search(query, props.limit);
    loading.value = false;

    if (props.overflow) repositionDropDown();
};

const onSearchChanged = debounce(async (query: string): Promise<void> =>
{
    await searchOptions(query);
},
500);

watch(selectedModel, (value, old) =>
{
    const c1 = value.select(p => props.trackBy in p && p[props.trackBy]).orderBy(p => p).toArray();
    const c2 = (old ?? []).select(p => props.trackBy in p && p[props.trackBy]).orderBy(p => p).toArray();

    if (c1.toString() != c2.toString())
    {
        valueModel.value = value.select(p => props.trackBy in p && p[props.trackBy]).toArray();
    }
});

watch(valueModel, (value) =>
{
    fetchModel(value);
});

watch(() => props.disabled, (value) =>
{
    if (value == false)
    {
        fetchModel(valueModel.value);
    }
});

onMounted(() =>
{
    fetchModel(valueModel.value);

    if (props.overflow)
    {
        repositionDropDown();
        window.addEventListener('scroll', onScrollChange, true);
    }
});

onBeforeUnmount(() =>
{
    if (props.overflow) window.removeEventListener('scroll', onScrollChange, true);
});

const onScrollChange = (e: Event): void =>
{
    if ((e.target as Element).classList.contains('multiselect__content-wrapper')) return;

    deactivate();
};

const deactivate = (): void =>
{
    multiselect.value.deactivate();
};

const clear = (): void =>
{
    selectedModel.value = [];
    deactivate();
};

const onSelect = (): void =>
{
    emit('selected', selected.value);
};

const open = async (): Promise<void> =>
{
    if (props.overflow) repositionDropDownHorizontally();

    opened.value = true;

    if (props.refresh || options.value.length == 0)
    {
        await searchOptions('');
    }

    if (props.overflow) repositionDropDown();
};

const close = (): void =>
{
    opened.value = false;

    if (props.refresh)
    {
        if (selectedModel.value.none())
        {
            options.value = [];
        }
        else
        {
            options.value = selectedModel.value;
        }
    }
};

const repositionDropDown = (): void =>
{
    if (!opened.value) return;

    const wrapperElement = multiselect.value.$refs.list;
    const { left, top, height } = root.value.getBoundingClientRect();

    wrapperElement.style.position = 'fixed';
    wrapperElement.style.left = `${left}px`;
    wrapperElement.style.width = `${root.value.clientWidth}px`;

    if (multiselect.value.$el.classList.contains('multiselect--above'))
    {
        wrapperElement.style.display = 'flex';

        nextTick(() =>
        {
            const wrapperHeight = wrapperElement.getBoundingClientRect().height;

            wrapperElement.style.top = `${top - wrapperHeight}px`;
            wrapperElement.style.bottom = 'auto';
        });
    }
    else
    {
        wrapperElement.style.top = `${top + height}px`;
        wrapperElement.style.bottom = 'auto';
    }

    wrapperElement.style.visibility = 'visible';
};

const repositionDropDownHorizontally = () =>
{
    const wrapperElement = multiselect.value.$refs.list;
    const { left } = root.value.getBoundingClientRect();

    wrapperElement.style.visibility = 'hidden';

    wrapperElement.style.position = 'fixed';
    wrapperElement.style.left = `${left}px`;
    wrapperElement.style.width = `${root.value.clientWidth}px`;
};

defineExpose({
    clear
});
</script>

<template>
    <div ref="root" :class="{'wms-dropdown labeled-input': labeledInput, 'focused': opened, 'disabled' : props.disabled}">
        <div class="input-label" :class="{'active': opened || selected}" v-if="labeledInput">
            <span>
                {{ labeledInput }}
                <span class="fa-solid fa-fw fa-star-of-life fa-2xs text-danger" v-if="props.required"></span>
            </span>
        </div>
        <Multiselect
            v-model="selected"
            :options="options"
            :name="props.name"
            :label="props.label"
            :track-by="props.trackBy"
            :loading="loading"
            @search-change="onSearchChanged"
            @open="open()"
            @close="close()"
            @select="onSelect()"
            :placeholder="labeledInput ? '' : props.placeholder || $t('[[[wybierz...]]]')"
            :select-label="''"
            :selected-label="''"
            :deselect-label="''"
            :multiple="props.multiple"
            :searchable="true"
            :options-limit="props.limit"
            :internal-search="false"
            :clear-on-select="false"
            :close-on-select="props.closeOnSelect"
            :max-height="300"
            :show-no-results="true"
            :hide-selected="false"
            :disabled="props.disabled"
            :default-label="props.defaultLabel"
            ref="multiselect"
        >
            <template #noOptions>{{ $t('[[[Lista jest pusta]]]') }}</template>
            <template #noResult>{{ $t('[[[Nie znaleziono żadnych wyników.]]]') }}</template>
            <template #singleLabel="{ option }"><slot name="selected" :option="option"></slot></template>
            <template #option="{ option }"><slot name="option" :option="option"></slot></template>
            <template #beforeList>
                <li class="multiselect__element" @click="clear">
                    <span class="multiselect__option empty" v-if="!props.emptyOption">{{ $t('[[[Brak wyboru]]]') }}</span>
                    <span class="multiselect__option empty" v-else>{{ props.emptyOption }}</span>
                </li>
            </template>
        </Multiselect>
    </div>
</template>
<style lang="scss" scoped>
:deep(.multiselect__tags) {
    &:has(input:disabled) {
        background-color: var(--bs-secondary-bg);
    }
}

:deep(.multiselect__select) {
    background-color: inherit !important;
}

.labeled-input {
    :deep(.multiselect__tags .multiselect__tags-wrap) {
        --ideo-multiselect-padding: 10px;
    }
}
</style>

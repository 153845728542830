<script lang="ts" setup>
import IdeoDropdown from '../dropdown/IdeoDropdown.vue';
import { ColorPicker } from "vue3-colorpicker";
import "vue3-colorpicker/style.css";

defineOptions({
    components: { ColorPicker }
});

const model = defineModel<string>();
const props = withDefaults(defineProps<{
    disabled?: boolean
}>(), {
    disabled: false
});
</script>

<template>
    <div class="colorpicker" @click.prevent>
        <IdeoDropdown variant="none" :split="false" no-icon :disabled="props.disabled">
            <color-picker
                v-model:pureColor="model"
                :is-widget="true"
                :format="'hex'"
                :shape="'square'"
                :picker-type="'fk'"
                :use-type="'both'"
                :disable-history="false"
                :round-history="false"
                :disable-alpha="true"
                :lang="'En'"
                :theme="'white'"
            />
            <template #button-content>
                &nbsp;
            </template>
        </IdeoDropdown>
    </div>
</template>

<style scoped>
.colorpicker :deep(.dropdown-container) {
    width: 100%;
}
.colorpicker :deep(.dropdown) {
    border: var(--bs-border-width) solid var(--bs-border-color);
    width: 100%;
}
.colorpicker :deep(.dropdown-btn) {
    background-color: v-bind(model);
    border: var(--bs-border-width) solid var(--bs-body-bg);
    text-transform: unset;
    width: 100%;
}
.colorpicker :deep(.vc-colorpicker) {
    border: none;
    box-shadow: none;
}
</style>

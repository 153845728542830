<template>
    <component :is="tagName" v-bind="attrs()" class="text-break" :class="classNames" @click="onClick" @dblclick="onDblClick" v-if="visible">
        <div v-if="flex" class="list-view-item-title">
            <small>
                <i v-if="loaded">{{ title }}</i>
                <placeholder :width="title.length * 6" :height="10" v-else></placeholder>
            </small>
        </div>
        <slot name="default" v-if="loaded"></slot>
        <placeholder :width="[computedMinWidth, computedMaxWidth]" :height="14" v-else></placeholder>
    </component>
</template>

<script lang="ts" setup>
import { computed, inject, useAttrs, type Ref, type ComputedRef } from 'vue';
import { only } from '@/helpers/Utils';
import { Header } from '../helpers';

defineOptions({
    name: 'list-view-item',
    inheritAttrs: false
});

const props = withDefaults(defineProps<{
    index: number;
    header: Header;
    margin?: string;
    minWidth?: number;
}>(), {
    margin: 'mb-1',
    minWidth: undefined
});
const loaded = inject<Ref<boolean>>('loaded');
const layout = inject<Ref<string>>('layout');
const item = inject<ComputedRef<any>>('item');
const rowClick = inject<(item: any, index: number) => void>('row-click');
const rowDblClick = inject<(item: any, index: number) => void>('row-dbl-click');

const table = computed(() => layout.value == 'table');
const flex = computed(() => !table.value);
const tagName = computed(() => table.value ? 'td' : 'div');

const cols = computed(() => $attrs.cols as string);
const colsXs = computed(() => $attrs['cols-xs'] as string || cols.value);
const colsSm = computed(() => $attrs['cols-sm'] as string || colsXs.value);
const colsMd = computed(() => $attrs['cols-md'] as string || colsSm.value);
const colsLg = computed(() => $attrs['cols-lg'] as string || colsMd.value);
const colsClass = computed(() => ([
    props.margin,
    {
        'col': layout.value !== 'comment' &&colsXs.value == null && colsSm.value == null && colsMd.value == null && colsLg.value == null,
        [`col-${colsXs.value}`]: colsXs.value != null,
        [`col-sm-${colsSm.value}`]: colsSm.value != null,
        [`col-md-${colsMd.value}`]: colsMd.value != null,
        [`col-lg-${colsLg.value}`]: colsLg.value != null,
        [$attrs['class'] as string]: 'class' in $attrs
    }
]));
const styles = computed(() => ({
    'min-width': props.minWidth ? `${props.minWidth}px` : undefined
}));
const classNames = computed(() => table.value ? 'pointer' : colsClass.value);
const visible = computed(() => props.header.visible);

const $attrs = useAttrs();
const computedMinWidth = computed(() => parseInt($attrs.width as string) || 100);
const computedMaxWidth = computed(() => parseInt($attrs.width as string) || 175);
const title = computed(() => $attrs.title as string || '');
const attrs = (): Record<string, any> => only($attrs, 'class', 'style');

const onClick = (): void =>
{
    if (table.value && rowClick)
    {
        rowClick(item.value, props.index);
    }
};

const onDblClick = (): void =>
{
    if (table.value && rowDblClick)
    {
        rowDblClick(item.value, props.index);
    }
};
</script>

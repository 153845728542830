export default [
    // Ustawienia
    {
        path: 'ai/settings',
        name: 'core-ai-settings',
        meta: { auth: true },
        component: () => import('@/modules/ai/settings/views/Settings.vue')
    },
    // Historia zapytań AI
    {
        path: 'ai/history',
        name: 'core-ai-history',
        meta: { auth: true },
        props: true,
        component: () => import('@/modules/ai/history/views/HistoryList.vue')
    },
    {
        path: 'ai/history/:id',
        name: 'core-ai-history-details',
        meta: { auth: true },
        props: true,
        component: () => import('@/modules/ai/history/views/HistoryView.vue')
    },
    // Asystent
    {
        path: 'ai/assistants',
        name: 'core-ai-assistants',
        meta: { auth: true },
        component: () => import('@/modules/ai/assistants/views/AssistantsList.vue')
    },
    {
        path: 'ai/assistants/add',
        name: 'core-ai-assistants-add',
        meta: { auth: true },
        component: () => import('@/modules/ai/assistants/views/AssistantsForm.vue')
    },
    {
        path: 'ai/assistants/:id',
        name: 'core-ai-assistants-edit',
        meta: { auth: true },
        props: true,
        component: () => import('@/modules/ai/assistants/views/AssistantsForm.vue')
    },
    // Pliki asystenta
    {
        path: 'ai/files',
        name: 'core-ai-files',
        meta: { auth: true },
        component: () => import('@/modules/ai/files/views/FilesList.vue')
    },
    {
        path: 'ai/files/add',
        name: 'core-ai-files-add',
        meta: { auth: true },
        component: () => import('@/modules/ai/files/views/FilesForm.vue')
    },
    // Funkcje asystenta
    {
        path: 'ai/functions',
        name: 'core-ai-functions',
        meta: { auth: true },
        props: true,
        component: () => import('@/modules/ai/functions/views/FunctionsList.vue')
    },
    {
        path: 'ai/functions/add',
        name: 'core-ai-functions-add',
        meta: { auth: true },
        props: true,
        component: () => import('@/modules/ai/functions/views/FunctionsForm.vue')
    },
    {
        path: 'ai/functions/:id',
        name: 'core-ai-functions-edit',
        meta: { auth: true },
        props: true,
        component: () => import('@/modules/ai/functions/views/FunctionsForm.vue')
    },
    // Formularze
    {
        path: 'ai/heroes',
        name: 'ai-heores',
        meta: { auth: true },
        component: () => import('@/modules/ai/heroes/views/Index.vue')
    }
];

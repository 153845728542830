<template>
    <div class="d-flex">
        <DateTimeButton
            v-model:gte="filter.dateCreatedUtc.from"
            v-model:lte="filter.dateCreatedUtc.to"
            name="dateCreatedUtc"
            :label="$t('[[[Data]]]')"
            :range="true"
            :multi="true"
            :class="{'w-100': isWidgetSmall}"
        />
    </div>
    <div class="chart-widget" :style="widgetStyles">
        <Bar v-if="loaded" :options="chartOptions" :data="chartData" />
    </div>
</template>

<script lang="ts" setup>
import { computed, ref, onMounted, reactive, watch, nextTick, inject, Ref } from 'vue';
import { useAlerts } from '@/plugins/alerts';
import { useLocalization } from '@/plugins/localization';
import { useMixins } from '@/plugins/mixins';
import WidgetsService, { StatusCountItemModel } from "@/modules/core/common/services/WidgetsService";
import { WidgetModel, WidgetConfigModel } from '@/modules/core/dashboards/services/DashboardsService';

import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Tooltip, BarElement, CategoryScale, LinearScale, Colors, ChartData } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(Tooltip, BarElement, CategoryScale, LinearScale, Colors, ChartDataLabels);

const props = withDefaults(defineProps<{
    widget: WidgetModel;
    config: WidgetConfigModel;
}>(), {
    widget: null,
    config: null
});

defineOptions({
    name: 'IssuesByStatusWidget',
    components: {
        Bar
    },
    inheritAttrs: false
});

const { $alert } = useAlerts();
const { $t } = useLocalization();
const { $handleException } = useMixins();

const items = ref<StatusCountItemModel[]>([]);
const loaded = ref<boolean>(false);
const filter = reactive({
    dateCreatedUtc: {
        from: null,
        to: null
    }
});

const isWidgetSmall = computed(() => props.config.width < 4);
const cardContainerHeight = inject<Ref<number>>('widget-container-height');
const widgetStyles = computed(() =>
{
    return {
        minHeight: isWidgetSmall.value ? cardContainerHeight.value + 'px' : '0',
    };
});

watch(() => filter.dateCreatedUtc.from, async (value, old) =>
{
    if (loaded.value === true && value != old)
    {
        await loadData();
    }
});

watch(() => filter.dateCreatedUtc.to, async (value, old) =>
{
    if (loaded.value === true && value != old)
    {
        await loadData();
    }
});

const chartData = computed<ChartData<'bar'>>(() =>
{
    const labels = items.value.map((item: StatusCountItemModel) => item.status);
    const data = items.value.map((item: StatusCountItemModel) => item.count);

    return {
        labels: labels,
        datasets: [{
            label: $t('[[[Ilość]]]'),
            data: data,
            borderRadius: 4,
            backgroundColor: ['#03a4dc', '#f28705', '#ff4255', '#4dbd73'],
            datalabels: {
                align: 'end',
                anchor: 'end'
            }
        }]
    };
});
const chartOptions = computed(() =>
{
    return {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            x: {
                ticks: {
                    minRotation: isWidgetSmall.value ? 90 : 0
                }
            }
        },
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                font: {
                    weight: 'bold'
                }
            }
        },
        layout: {
            padding: {
                top: 20,
            }
        }
    };
});

onMounted(async (): Promise<void> =>
{
    await loadData();
});

const loadData = async (): Promise<void> =>
{
    loaded.value = false;

    try
    {
        const response = await WidgetsService.getIssuesByStatusCount(filter);

        items.value = response.result;

        if (response.meta.dateCreatedUtc)
        {
            filter.dateCreatedUtc = response.meta.dateCreatedUtc;
        }
    }
    catch (ex)
    {
        $handleException(ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });
    }
    finally
    {
        nextTick(() =>
        {
            loaded.value = true;
        });
    }
};
</script>

<style lang="scss" scoped>
</style>

<template>
    <ideo-autocomplete
        v-model="pickedProduct"
        :fetch="(id) => fetchZone(id)"
        :search="(query, limit) => getZonesList(query, limit, withoutUsersAndDefaultZones)"
        :refresh="refresh"
        @changed="onChanged"
        :multiple="multiple"
    >
    </ideo-autocomplete>
</template>

<script lang="ts">
import { mixins, Options } from "vue-class-component";
import { Option } from '@/helpers/Interfaces';
import { Prop } from "@/helpers/Decorators";
import { ZonesAutocompleteMixin } from "./ZonesAutocompleteMixin";

@Options({
})
export default class ZonesPicker extends mixins(ZonesAutocompleteMixin)
{
    @Prop() private modelValue: Option | Option[];
    @Prop() private multiple: boolean;
    @Prop() private refresh: boolean;
    @Prop({default: true}) public withoutUsersAndDefaultZones: boolean;

    public onChanged(val: Option | Option[]) : void
    {
        this.$emit('changed', val);
    }

    public get pickedProduct() : Option | Option[]
    {
        return this.modelValue;
    }

    public set pickedProduct(val : Option | Option[])
    {
        this.$emit('update:modelValue', val);
    }
}
</script>

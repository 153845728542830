<template>
    <component :is="tagName" v-bind="attrs()" :class="classNames">
        <ideo-dropdown ref="dropdownRef" class="list-view-buttons" variant="secondary" :text="$t('[[[Akcje]]]')" dropleft boundary="body" no-flip no-caret @show="onShow" @hide="onHide" v-if="loaded">
            <template #button-content>
                <i :class="icon"></i>
            </template>
            <slot name="default"></slot>
        </ideo-dropdown>
        <placeholder :width="37" :height="32" v-else></placeholder>
    </component>
</template>

<script lang="ts" setup>
import { ref, computed, provide, inject, useAttrs, type Ref, type ComputedRef } from 'vue';
import { only } from '@/helpers/Utils';

export interface Props
{
    icon?: string;
}

defineOptions({
    name: 'list-view-buttons',
    inheritAttrs: false
});

withDefaults(defineProps<Props>(), {
    icon: 'fa-regular fa-ellipsis-vertical',
});

const emit = defineEmits<{
    (e: 'show', item: any): void
    (e: 'hide', item: any): void
}>();

const dropdownRef = ref<any>(null);

provide('dropdownRef', dropdownRef);

const item = inject<ComputedRef<any>>('item');
const loaded = inject<Ref<boolean>>('loaded');
const layout = inject<Ref<string>>('layout');

const table = computed(() => layout.value == 'table');
const tagName = computed(() => table.value ? 'td' : 'div');
const classNames = computed(() => table.value ? 'actions-column text-end' : '');

const $attrs = useAttrs();
const attrs = (): Record<string, any> => only($attrs, 'class', 'style');

function onShow()
{
    emit('show', item.value);
}

function onHide()
{
    emit('hide', item.value);
}
</script>

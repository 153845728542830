import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';

const requireComponents = import.meta.glob('@/components/forms/blueprints/**/Blueprint.vue');
const components = Object.entries(requireComponents).map(([key, value]) => ({
    key: key.replace('/Blueprint.vue', '').split('/').last(),
    value
}));
const requireFilters = import.meta.glob('@/components/forms/blueprints/**/Filter.vue');
const filters = Object.entries(requireFilters).map(([key, value]) => ({
    key: key.replace('/Filter.vue', '').split('/').last(),
    value
}));
const requirePresenters = import.meta.glob('@/components/forms/blueprints/**/Presenter.vue');
const presenters = Object.entries(requirePresenters).map(([key, value]) => ({
    key: key.replace('/Presenter.vue', '').split('/').last(),
    value
}));

const requireTypes = import.meta.glob('@/components/forms/blueprints/**/index.ts', { eager: true });
const definitions = Object.values(requireTypes).select((p: any) => p.Definition).toArray();
const types = Object.assign({}, ...Object.values(requireTypes).map((module: any) =>
{
    const type = upperFirst(camelCase(`${module.Definition.type}-type`));

    return {[type]: module[type]};
}));

export {
    components,
    filters,
    presenters,
    definitions,
    types
};

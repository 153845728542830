import { defineAsyncComponent, Plugin } from 'vue';
import { components, filters, presenters, types } from './Definitions';

// Builder
import { Blueprint } from '../builder/base/blueprints/Blueprint';
import { IdentityCallback } from '../builder/base/types/Identity';
import { FormBuilder, FormBuilderContract } from '../builder/form';
import { FormBlueprintFactory } from '../builder/form/services/FormBlueprintFactory';
import { Entry } from '../builder/form/entries/Entry';

export class FluentFormBuilder
{
    private builder: FormBuilder;
    private options: {
        blueprint?: () => Blueprint,
        entry?: () => Entry,
        blueprintId?: IdentityCallback,
        entryId?: IdentityCallback
    };

    public constructor(builder: FormBuilder)
    {
        this.builder = builder;
        this.options = {};
    }

    public static setup(design: boolean = false, internal: boolean = false) : FluentFormBuilder
    {
        const blueprintFactory = new FormBlueprintFactory(types);
        const formBuilder = new FormBuilder(blueprintFactory);

        formBuilder.design = design;
        formBuilder.internal = internal;

        return new FluentFormBuilder(formBuilder);
    }

    public blueprint(blueprint: () => Blueprint, blueprintId: IdentityCallback = () => 0): FluentFormBuilder
    {
        this.options.blueprint = blueprint;
        this.options.blueprintId = blueprintId;

        return this;
    }

    public entry(entry: () => Entry, entryId: IdentityCallback = () => 0): FluentFormBuilder
    {
        this.options.entry = entry;
        this.options.entryId = entryId;

        return this;
    }

    public make(): FormBuilderContract
    {
        this.builder.setBlueprint(this.options.blueprint);
        this.builder.setBlueprintIdentity(this.options.blueprintId);
        this.builder.setEntry(this.options.entry);
        this.builder.setEntryIdentity(this.options.entryId);
        this.builder.update();

        return this.builder;
    }
}

const DynamicFormsPlugin: Plugin =
{
    install(app, options)
    {
        app.component('DesignPanel', defineAsyncComponent(() => import('@/components/forms/helpers/DesignPanel.vue')));
        app.component('DesignPlaceholder', defineAsyncComponent(() => import('@/components/forms/helpers/DesignPlaceholder.vue')));
        app.component('DesignRuller', defineAsyncComponent(() => import('@/components/forms/helpers/DesignRuller.vue')));
        app.component('DesignStatus', defineAsyncComponent(() => import('@/components/forms/helpers/DesignStatus.vue')));
        app.component('DesignToolbar', defineAsyncComponent(() => import('@/components/forms/helpers/DesignToolbar.vue')));

        app.component('FormComponent', defineAsyncComponent(() => import('@/components/forms/helpers/FormComponent.vue')));
        app.component('FormComponentWrapper', defineAsyncComponent(() => import('@/components/forms/helpers/FormComponentWrapper.vue')));
        app.component('FormErrorMessage', defineAsyncComponent(() => import('@/components/forms/helpers/FormErrorMessage.vue')));
        app.component('FormGroup', defineAsyncComponent(() => import('@/components/forms/helpers/FormGroup.vue')));
        app.component('FormLabel', defineAsyncComponent(() => import('@/components/forms/helpers/FormLabel.vue')));
        app.component('FormHelp', defineAsyncComponent(() => import('@/components/forms/helpers/FormHelp.vue')));

        app.component('FormToolbox', defineAsyncComponent(() => import('@/components/forms/helpers/DesignPanelTarget.vue')));
        app.component('FormFilters', defineAsyncComponent(() => import('@/components/forms/helpers/FormFilters.vue')));
        app.component('FormListView', defineAsyncComponent(() => import('@/components/forms/helpers/FormListView.vue')));

        app.component('FormAccordion', defineAsyncComponent(() => import('@/components/forms/helpers/FormAccordion.vue')));
        app.component('FormAccordionItem', defineAsyncComponent(() => import('@/components/forms/helpers/FormAccordionItem.vue')));

        components.forEach((component: any) =>
        {
            app.component(`${component.key}-blueprint`, defineAsyncComponent(component.value));
        });

        filters.forEach((component: any) =>
        {
            app.component(`${component.key}-filter`, defineAsyncComponent(component.value));
        });

        presenters.forEach((component: any) =>
        {
            app.component(`${component.key}-presenter`, defineAsyncComponent(component.value));
        });
    }
};

export default DynamicFormsPlugin;

<script lang="ts" setup>
import { computed, useAttrs } from 'vue';

defineOptions({
    name: 'ideo-dropdown-header'
});

const attrs = useAttrs();

const props = withDefaults(defineProps<{
    tag?: 'h1'|'h2'|'h3'|'h4'|'h5'|'h6';
    variant?: 'primary'|'secondary'|'success'|'danger'|'warning'|'info'|'light'|'dark'
}>(), {
    tag: 'h6',
    variant: null
});

const customClasses = computed(() => ({
    'dropdown-header': !('class' in attrs),
    [`text-${props.variant}`]: props.variant != null
}));
</script>

<template>
    <component :is="tag" :class="customClasses">
        <slot name="default"></slot>
    </component>
</template>

import * as Validator from 'yup';
import Errors from './Errors';

export { Validator, Errors };

export default class ValidationSchema
{
    public string(): Validator.StringSchema
    {
        return Validator.string();
    }

    public number(): Validator.NumberSchema
    {
        return Validator.number();
    }

    public boolean(): Validator.BooleanSchema
    {
        return Validator.boolean();
    }

    public date(): Validator.DateSchema
    {
        return Validator.date();
    }

    public array(schema: Validator.AnySchema): Validator.ArraySchema<Validator.AnySchema>
    {
        return Validator.array().of(schema);
    }

    public object(shape: any): Validator.ObjectSchema<any>
    {
        return Validator.object().shape(shape);
    }
}

Validator.setLocale({
    mixed: {
        test: '[[[Wprowadzona wartość jest nieprawidłowa]]]',
        required: '[[[To pole jest wymagane]]]'
    },
    string: {
        email: '[[[Nieprawidłowy adres email]]]',
        url: '[[[Nieprawidłowy adres URL]]]',
        min: '[[[Wprowadzona wartość jest za krótka]]]',
        max: '[[[Wprowadzona wartość jest za długa]]]',
        matches: '[[[Wprowadzona wartość nie jest poprawna]]]'
    },
    number: {
        min: '[[[Wprowadzona wartość jest za niska]]]',
        max: '[[[Wprowadzona wartość jest za wysoka]]]',
        lessThan: '[[[Wprowadzona wartość jest za wysoka]]]',
        moreThan: '[[[Wprowadzona wartość jest za niska]]]',
        positive: '[[[Wartość musi być liczbą dodatnią]]]',
        negative: '[[[Wartość musi być liczbą ujemną]]]',
        integer: '[[[Wartość musi być liczbą całkowitą]]]'
    }
});

<script lang="ts" setup>
import { ref, computed, watch, nextTick, onMounted, getCurrentInstance, onBeforeUnmount, onUnmounted } from 'vue';
import { createPopper as createPopperFn, Instance as Popper } from '@popperjs/core';

const PLACEMENTS = {
    'auto': { popover: 'auto', arrow: 'auto' },
    'top': { popover: 'top', arrow: 'center' },
    'topleft': { popover: 'top', arrow: 'left' },
    'topright': { popover: 'top', arrow: 'right' },
    'right': { popover: 'right', arrow: 'middle' },
    'righttop': { popover: 'right', arrow: 'top' },
    'rightbottom': { popover: 'right', arrow: 'bottom' },
    'bottom': { popover: 'bottom', arrow: 'center' },
    'bottomleft': { popover: 'bottom', arrow: 'left' },
    'bottomright': { popover: 'bottom', arrow: 'right' },
    'left': { popover: 'left', arrow: 'middle' },
    'lefttop': { popover: 'left', arrow: 'top' },
    'leftbottom': { popover: 'left', arrow: 'bottom' }
};

const instance = getCurrentInstance();

defineOptions({
    name: 'ideo-popover'
});

const props = withDefaults(defineProps<{
    show?: boolean;
    title?: string;
    local?: boolean;
    triggers?: string,
    boundary?: string;
    target: any;
    placement?: 'auto'|'top'|'topleft'|'topright'|'right'|'righttop'|'rightbottom'|'bottom'|'bottomleft'|'bottomright'|'left'|'lefttop'|'leftbottom';
    rounded?: boolean;
    shadow?: boolean;
}>(), {
    show: undefined,
    title: null,
    triggers: 'click',
    boundary: null,
    placement: 'right',
    shadow: true,
});

const emit = defineEmits<{
    (e: 'shown'): void;
    (e: 'hidden'): void;
}>();

const popover = ref(null);

const popper = ref<Popper>(null);
const visible = ref(false);
const popoverPlacement = ref('auto');
const arrowPlacement = ref('auto');

const resolvedPlacement = computed((): { popover: string, arrow: string } => PLACEMENTS[props.placement]);
const resolvedTriggers = computed((): string[] => props.show == undefined ? props.triggers.toLowerCase().trim().split(' ').sort() : []);

const popoverClasses = computed((): Record<string, boolean> =>
{
    let popoverPlacementClass;

    // due to coreUI changes we have to replace CSS classes
    switch (popoverPlacement.value)
    {
        case 'left':
            popoverPlacementClass = 'start';
            break;
        case 'right':
            popoverPlacementClass = 'end';
            break;
        default:
            popoverPlacementClass = popoverPlacement.value;
    }

    return {
        'popover': true,
        'shadow': props.shadow,
        'rounded-0': !props.rounded,
        [`bs-popover-${popoverPlacementClass}`]: true
    };
});

const arrowClasses = computed((): Record<string, boolean> =>
{
    return {
        'arrow popover-arrow': true,
        [`bs-popover-arrow-${arrowPlacement.value}`]: true
    };
});

const targetElement = (): HTMLElement =>
{
    return typeof props.target == 'string' ? document.getElementById(props.target) : props.target;
};

const onGlobalOpen = (e: any): void =>
{
    if (e.detail.component() != instance)
    {
        close();
    }
};

const onPopoverMouseLeave = (e: any): void =>
{
    if (e?.toElement?.id !== 'ai-generator-button')
        close();
};

const getPopperConfig = (): any =>
{
    let placement = resolvedPlacement.value.popover;

    switch (resolvedPlacement.value.arrow)
    {
        case 'top':
            placement = `${placement}-start`;
            break;
        case 'middle':
            break;
        case 'bottom':
            placement = `${placement}-end`;
            break;
        case 'left':
            placement = `${placement}-start`;
            break;
        case 'center':
            break;
        case 'right':
            placement = `${placement}-end`;
            break;
    }

    const config: any = {
        placement: placement,
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 8]
                }
            }
        ],
        onFirstUpdate: (state: any) =>
        {
            // eslint-disable-next-line prefer-const
            let [popover, arrow] = state.placement.split('-');

            if (['top', 'bottom'].includes(popover))
            {
                switch (arrow)
                {
                    case 'start': arrow = 'left'; break;
                    case 'end': arrow = 'right'; break;
                    default: arrow = 'center'; break;
                }
            }

            if (['left', 'right'].includes(popover))
            {
                switch (arrow)
                {
                    case 'start': arrow = 'top'; break;
                    case 'end': arrow = 'bottom'; break;
                    default: arrow = 'middle'; break;
                }
            }

            popoverPlacement.value = popover;
            arrowPlacement.value = arrow;
        }
    };

    if (props.boundary)
    {
        config.modifiers.push({
            name: 'preventOverflow',
            options: {
                boundary: document.querySelector(props.boundary),
                padding: 0
            }
        });
    }

    return config;
};

const destroyPopper = (): void =>
{
    popper.value && popper.value.destroy();
    popper.value = null;
};

const createPopper = (element: HTMLElement): void =>
{
    destroyPopper();
    nextTick(() =>
    {
        popper.value = createPopperFn(element, popover.value as any, getPopperConfig());
    });
};

const open = (target?: HTMLElement): void =>
{
    visible.value = true;

    createPopper(target || targetElement());

    document.dispatchEvent(new CustomEvent('ideo-popover:open', {
        detail: { component: () => instance }
    }));

    nextTick(() =>
    {
        popover.value.addEventListener('mouseleave', onPopoverMouseLeave);
    });

    emit('shown');
};

const close = (): void =>
{
    if (visible.value)
    {
        popover.value.removeEventListener('mouseleave', onPopoverMouseLeave);
        destroyPopper();
        visible.value = false;

        emit('hidden');
    }
};

watch(() => props.show, (value: boolean): void =>
{
    toggle(value);
});

const toggle = (visible: boolean): void =>
{
    visible ? open() : close();
};

const handleEvent = (e: MouseEvent | FocusEvent): void =>
{
    const type = e.type;
    const target = targetElement();
    const triggers = resolvedTriggers.value;

    if (type === 'click' && triggers.includes('click'))
    {
        toggle(true);
    }
    else if (type === 'mouseenter' && triggers.includes('hover'))
    {
        toggle(true);
    }
    else if (type === 'focusin' && triggers.includes('focus'))
    {
        toggle(true);
    }
    else if ((type === 'focusout' && (triggers.includes('focus') || triggers.includes('blur'))) || (type === 'mouseleave' && triggers.includes('hover')))
    {
        const tip = popover.value as HTMLElement;
        const eventTarget = e.target as HTMLElement;
        const relatedTarget = e.relatedTarget as HTMLElement;

        if (
            // From tip to target
            (tip && tip.contains(eventTarget) && target.contains(relatedTarget)) ||
            // From target to tip
            (tip && target.contains(eventTarget) && tip.contains(relatedTarget)) ||
            // Within tip
            (tip && tip.contains(eventTarget) && tip.contains(relatedTarget)) ||
            // Within target
            (target.contains(eventTarget) && target.contains(relatedTarget))
        )
        {
            // If focus/hover moves within `tip` and `target`, don't trigger a leave
            return;
        }

        // Otherwise trigger a leave
        toggle(false);
    }
};

onMounted(() =>
{
    const target = targetElement();

    for (const trigger of resolvedTriggers.value)
    {
        if (trigger === 'click')
        {
            target.addEventListener('click', handleEvent, { capture: false });
        }
        else if (trigger === 'focus')
        {
            target.addEventListener('focusin', handleEvent, { capture: false });
            target.addEventListener('focusout', handleEvent, { capture: false });
        }
        else if (trigger === 'blur')
        {
            target.addEventListener('focusout', handleEvent, { capture: false });
        }
        else if (trigger === 'hover')
        {
            target.addEventListener('mouseenter', handleEvent, { capture: false });
            target.addEventListener('mouseleave', handleEvent, { capture: false });
        }
    }

    if (!props.local)
        document.addEventListener("ideo-popover:open", onGlobalOpen);
});

onBeforeUnmount(() =>
{
    destroyPopper();
});

onUnmounted(() =>
{
    const target = targetElement();
    const events = ['click', 'focusin', 'focusout', 'mouseenter', 'mouseleave'];

    for (const event of events)
    {
        target && target.removeEventListener(event, handleEvent as any, { capture: false });
    }

    document.removeEventListener('ideo-popover:open', onGlobalOpen);
});

defineExpose({
    open,
    close
});
</script>

<template>
    <div ref="popover" :class="popoverClasses" v-if="visible">
        <div :class="arrowClasses" style=""></div>
        <h3 class="popover-header" v-if="title || 'title' in $slots">
            <slot name="title">{{ title }}</slot>
        </h3>
        <div class="popover-body">
            <slot name="default"></slot>
        </div>
    </div>
</template>

<style lang="scss">
.popover {
    &-body {
        overflow: hidden;
    }
    &-arrow {
        position: absolute;
    }
}

.bs-popover {
    &-arrow {
        &-top,
        &-middle,
        &-bottom {
            height: 100% !important;
            top: 0;
        }

        &-top {
            &:before, &:after {
                top: 0;
            }
        }
        &-middle {
            &:before, &:after {
                top: calc(50% - 8px);
            }
        }
        &-bottom {
            &:before, &:after {
                bottom: 0;
            }
        }

        &-left,
        &-center,
        &-right {
            width: 100% !important;
            left: 0;
        }

        &-left {
            &:before, &:after {
                left: 0;
            }
        }
        &-center {
            &:before, &:after {
                left: calc(50% - 8px);
            }
        }
        &-right {
            &:before, &:after {
                right: 0;
            }
        }
    }
}
</style>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';

defineOptions({
    name: 'action-icon'
});

const props = withDefaults(defineProps<{
    icon: string;
    title: string;
    confirm: string;
}>(), {
    confirm: ''
});

const emit = defineEmits<{
    (e: 'click', event: any): void;
}>();

const loaded = ref(false);

onMounted(() =>
{
    loaded.value = true;
});

const onClick = (e: any): void =>
{
    if (props.confirm.length === 0)
        emit('click', e);
};
</script>

<template>
    <button ref="$el" type="button" @click.prevent.stop="onClick">
        <i :class="icon" :title="title"></i>
        <confirmation :message="confirm" :target="$refs.$el" placement="auto" :value="{}" @confirm="onConfirm" v-if="loaded && confirm.length > 0" />
    </button>
</template>

<style lang="scss" scoped>
button {
    border: none;
    background: transparent;
    padding: 0;
}
</style>

<script lang="ts" setup>
import { ref, computed, getCurrentInstance, onMounted, onUnmounted, onBeforeMount } from 'vue';
import { normalizeClasses } from '@/helpers/Utils';

defineOptions({
    name: 'ideo-tab'
});

const instance = getCurrentInstance();

const props = defineProps<{
    title?: string;
    active?: boolean;
    manual?: boolean;
    hidden?: boolean;
}>();

const emit = defineEmits<{
    (e: 'click', index: number): void;
}>();

const isMounted = ref(false);

const tabs = computed((): any => instance.parent);
const index = computed(() => tabs.value.exposed.tabIndex(instance));
const activeTab = computed(() => tabs.value.exposed.tabActive(instance));
const navLinkClasses = computed(() => ({
    'nav-link': true,
    'active': activeTab.value,
    'manual': props.manual,
    ...(activeTab.value ? normalizeClasses(tabs.value.activeNavItemClass) : {})
}));
const contentClasses = computed(() => ({
    ...(activeTab.value ? normalizeClasses(tabs.value.activeTabClass) : {})
}));

onBeforeMount(() =>
{
    tabs.value.exposed.registerTab(instance);
});

onMounted(() =>
{
    isMounted.value = true;
});

onUnmounted(() =>
{
    tabs.value.exposed.unregisterTab(instance);
});

const onClick = (): void =>
{
    if (props.manual)
        return;

    tabs.value.exposed.setActiveTab(instance);

    emit('click', index.value);
};
</script>

<template>
    <portal :to="`tabs-nav-${tabs.uid}`" v-if="isMounted">
        <li class="nav-item" v-show="!hidden">
            <a :class="navLinkClasses" href="#" @click.stop.prevent="onClick">
                <slot name="title">
                    {{ title }}
                </slot>
            </a>
        </li>
    </portal>
    <div :class="contentClasses" v-show="activeTab" :data-tab="instance.uid">
        <slot name="default"></slot>
    </div>
</template>

<template>
    <div v-if="availableShortcuts.length || activeShortcuts.length" class="d-flex flex-wrap gap-2 mb-3">
        <action-button
            variant="light"
            :icon="!isEditMode ? 'fas fa-pen-to-square' : 'fas fa-check-circle'"
            :text="toggleEditText"
            :class="{ active: isEditMode }"
            @click.stop="toogleEdit"
        />
    </div>
    <div class="shortcuts-widget">
        <ul v-if="activeShortcuts.length" class="active-list">
            <router-link v-for="shortcut in activeShortcuts" :key="shortcut" :to="{name: getShortcut(shortcut).route}" custom v-slot="{ navigate }">
                <li @click="navigate" class="active-list__item btn btn-light" :class="{'in-edit': isEditMode}">
                    <div class="icon-container">
                        <span class="icon" :class="getShortcut(shortcut).icon"></span>
                    </div>
                    <div class="text-container">
                        {{ $t(getShortcut(shortcut).text) }}
                    </div>
                    <button v-if="isEditMode" class="remove-btn" :title="$t('[[[Usuń skrót]]]')" @click.stop.prevent="removeShortcut(shortcut)">
                        <span class="icon fa-solid fa-minus"></span>
                    </button>
                </li>
            </router-link>
        </ul>
        <span v-else>{{ $t('[[[Brak aktywnych skrótów]]]') }}</span>

        <template v-if="isEditMode">
            <hr>
            <h5 class="mb-3">{{ $t('[[[Dostępne skróty]]]') }}</h5>
            <TransitionGroup v-if="availableShortcuts.length" class="available-list" tag="ul" name="fade">
                <li v-for="shortcut in availableShortcuts" :key="shortcut" :title="$t('[[[Dodaj skrót]]]')" class="available-list__item btn btn-light" @click.stop.prevent="addShortcut(shortcut)">
                    <div class="text-container">
                        <span class="icon me-2" :class="getShortcut(shortcut).icon"></span>
                        <span>{{ $t(getShortcut(shortcut).text) }}</span>
                    </div>
                    <div class="add-icon">
                        <span class="icon fa-solid fa-plus"></span>
                    </div>
                </li>
            </TransitionGroup>
            <span v-else>{{ $t('[[[Brak dostępnych skrótów]]]') }}</span>
        </template>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref, onMounted } from 'vue';
import { useAlerts } from '@/plugins/alerts';
import { useLocalization } from '@/plugins/localization';
import { useMixins } from '@/plugins/mixins';
import WidgetsService, { ShortcutsModel, ShortcutsEnum } from "@/modules/core/common/services/WidgetsService";
import ModulesService, { ProcessEnabledModel } from '@/modules/wms/common/services/ModulesService';
import { GetShortcut } from '@/modules/core/home/components/widgets/shortcuts/utils';
import { useEvents } from '@/plugins/events';
import { WidgetModel, WidgetConfigModel } from '@/modules/core/dashboards/services/DashboardsService';

const props = withDefaults(defineProps<{
    widget: WidgetModel;
    config: WidgetConfigModel;
}>(), {
    widget: null,
    config: null
});

defineOptions({
    name: 'ShortcutsWidget',
    inheritAttrs: false,
});

const { $events } = useEvents();
const { $alert } = useAlerts();
const { $t } = useLocalization();
const { $handleException } = useMixins();

const widgets = ref<ShortcutsModel>(null);
const activeModules = ref<string[]>([]);
const activeShortcuts = ref<ShortcutsEnum[]>([]);
const availableShortcuts = ref<ShortcutsEnum[]>([]);
const loaded = ref<boolean>(false);
const isEditMode = ref<boolean>(false);
const toggleEditText = computed(() =>
{
    return `${!isEditMode.value ? $t("[[[Edytuj]]]") : $t("[[[Zakończ edycje]]]")}`;
});

onMounted(async (): Promise<void> =>
{
    await loadActiveModules();
    await loadData();
});

const loadActiveModules = async () =>
{
    try
    {
        const response = await ModulesService.getActiveStatus();

        activeModules.value = response.result.filter((module: ProcessEnabledModel) => module.value).map((module: ProcessEnabledModel) => module.moduleName);
    }
    catch (ex)
    {
        $handleException(ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });
    }
};

const loadData = async (): Promise<void> =>
{
    loaded.value = false;

    try
    {
        const { result } = await WidgetsService.getShortcuts();

        widgets.value = result;

        activeShortcuts.value = checkActiveModules(widgets.value.activeShortcuts);
        availableShortcuts.value = checkActiveModules(widgets.value.availableShortcuts);

        resizeWidget();
    }
    catch (ex)
    {
        $handleException(ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });
    }
    finally
    {
        loaded.value = true;
    }
};

async function addShortcut(shortcut: ShortcutsEnum)
{
    try
    {
        await WidgetsService.addShortcut(shortcut);

        $alert.debounceSuccess($t('[[[Skrót został dodany]]]'));

        loadData();
    }
    catch (ex)
    {
        $handleException(ex, {
            0: (ex: any) => $alert.warning(ex.message)
        });
    }
}

async function removeShortcut(shortcut: ShortcutsEnum)
{
    try
    {
        await WidgetsService.removeShortcut(shortcut);

        $alert.debounceSuccess($t('[[[Skrót został usunięty]]]'));

        loadData();
    }
    catch (ex)
    {
        $handleException(ex, {
            0: (ex: any) => $alert.warning(ex.message)
        });
    }
}

function getShortcut(name: ShortcutsEnum)
{
    return GetShortcut(name);
}

function resizeWidget()
{
    $events.$emit('gridstack::resize-widget', props.widget.id);
}

function toogleEdit()
{
    isEditMode.value = !isEditMode.value;

    resizeWidget();
}

function checkActiveModules(modules: ShortcutsEnum[])
{
    return modules.filter((module: ShortcutsEnum) =>
    {
        switch (module)
        {
            case ShortcutsEnum.Receipts:
            case ShortcutsEnum.ReceiptsToAccept:
                if (activeModules.value.includes('receipt'))
                    return module;

                break;
            case ShortcutsEnum.DirectReceipts:
            case ShortcutsEnum.DirectReceiptsToAccept:
                if (activeModules.value.includes('direct_receipt'))
                    return module;

                break;
            case ShortcutsEnum.IssuesToAccept:
                if (activeModules.value.includes('issue'))
                    return module;

                break;
            case ShortcutsEnum.CouriersIssues:
                if (activeModules.value.includes('courier'))
                    return module;

                break;
            case ShortcutsEnum.OneStageIssues:
                if (activeModules.value.includes('one_stage_issue'))
                    return module;

                break;
            case ShortcutsEnum.DirectIssues:
                if (activeModules.value.includes('direct_issue'))
                    return module;

                break;
            case ShortcutsEnum.Transfers:
            case ShortcutsEnum.QuickTransfers:
                if (activeModules.value.includes('quick_transfers'))
                    return module;

                break;
            case ShortcutsEnum.Collecting:
                if (activeModules.value.includes('collecting'))
                    return module;

                break;
            case ShortcutsEnum.Packing:
                if (activeModules.value.includes('packing'))
                    return module;

                break;
            case ShortcutsEnum.PersonalCollecting:
                if (['issue', 'personal_collection'].every((item: string) => activeModules.value.includes(item)))
                    return module;

                break;
            case ShortcutsEnum.Inventories:
                if (activeModules.value.includes('localization_inventories'))
                    return module;

                break;
            case ShortcutsEnum.Carriers:
            case ShortcutsEnum.DeliveryCalendar:
            case ShortcutsEnum.Products:
                return module;
            default:
                break;
        }
    });
}
</script>

<style lang="scss" scoped>
.active-list {
    padding: 0;
    margin: 0;
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-auto-rows: 1fr;
    gap: 20px;
    list-style-type: none;

    &__item {
        padding: 1.25rem;
        margin: 0 !important;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 6px;
        cursor: pointer;
        text-transform: none !important;
        position: relative;

        &.in-edit {
            pointer-events: none;

            .remove-btn {
                pointer-events: all;
            }
        }

        .remove-btn {
            position: absolute;
            top: -5px;
            right: -5px;
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 0;
            border-radius: 50%;
            background-color: red;

            .icon {
                color: #fff;
                font-weight: bold;
            }
        }

        .icon-container {
            .icon {
                font-size: 1.8rem;
            }
        }
    }
}

.available-list {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px 12px;
    list-style-type: none;

    &__item {
        margin: 0 !important;
        display: flex;
        align-items: center;
        border-radius: 30px;
        text-transform: none !important;

        &:hover {
            .add-icon {
                .icon {
                    rotate: 90deg;
                }
            }
        }

        .add-icon {
            padding-left: 10px;
            margin-left: 10px;
            border-left: 1px solid gray;

            .icon {
                transition: rotate .3s ease-in-out;
                font-weight: bold;
            }
        }
    }
}

.fade-move,
.fade-enter-active,
.fade-leave-active {
    transition: all 0.4s cubic-bezier(0.55, 0, 0.1, 1);
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
    transform: scaleY(0.01) translate(30px, 0);
}

.fade-leave-active {
    position: absolute;
}
</style>

<script lang="ts" setup>
import { ref, reactive, computed } from 'vue';
import { useAlerts } from '@/plugins/alerts';
import { useLocalization } from '@/plugins/localization';
import { useMedia } from '@/plugins/media';
import { Form } from '@/helpers/Form';
import { WIDTH_OPTIONS, HEIGHT_OPTIONS } from '@/modules/core/dashboards/utils';
import DashboardsService, {
    WidgetModel,
    WidgetConfigModel,
} from '@/modules/core/dashboards/services/DashboardsService';
import IdeoModal from '@/components/ideo/modal/IdeoModal.vue';

const emit = defineEmits<{
    (e: 'update', config: WidgetConfigModel, id: string): void;
    (e: 'add', config: WidgetConfigModel): void;
}>();

const props = defineProps<{
    dashboardId: number;
}>();

const { $alert } = useAlerts();
const { $t } = useLocalization();
const media = useMedia();

const modal = ref<IdeoModal | null>(null);
const editMode = ref<boolean>(false);

const widget = reactive(
    Form.create<WidgetModel>({
        id: '',
        height: 0,
        width: 0,
        categories: [],
        description: '',
        icon: '',
        name: '',
        type: '',
    })
);

const config = reactive(
    Form.create<WidgetConfigModel>({
        id: '',
        height: 0,
        width: 0,
        x: 0,
        y: 0,
        sourceConfig: '',
    })
);

const submitBtn = computed(() =>
{
    return {
        variant: 'primary',
        text: editMode.value ? $t('[[[Zapisz widget]]]') : $t('[[[Dodaj widget]]]'),
    };
});

const isPageSize = computed(
    () => config.sourceConfig && typeof config.sourceConfig === 'object' && 'pageSize' in config.sourceConfig
);

const showModal = async (_widget: WidgetModel, mode: boolean = false) =>
{
    modal.value?.show();
    editMode.value = mode;

    await Promise.all([loadWidget(_widget.id), loadConfig(_widget.id)]);
};

const loadWidget = async (id: string) =>
{
    try
    {
        const response = await DashboardsService.getWidget(id);

        widget.withData(response);
    }
    catch (ex: any)
    {
        if (ex.code == 400) $alert.error(ex.message);
        else if (ex.code == 422) widget.$errors.record(ex.data.errors);
    }
};

const loadConfig = async (id: string) =>
{
    try
    {
        const response = await DashboardsService.getWidgetConfig(props.dashboardId, id);

        config.withData(response);
        config.sourceConfig = typeof response.sourceConfig === 'string' ? JSON.parse(response.sourceConfig) : null;
    }
    catch (ex: any)
    {
        if (ex.code == 400) $alert.error(ex.message);
        else if (ex.code == 422) config.$errors.record(ex.data.errors);
    }
};

const onSubmit = async () =>
{
    try
    {
        if (editMode.value)
        {
            config.sourceConfig = JSON.stringify(config.sourceConfig);
            await DashboardsService.updateWidgetConfig(props.dashboardId, config.id, config.data());

            emit('update', config.data(), config.id);
        }
        else
        {
            emit('add', config.data());
        }

        modal.value?.hide();
    }
    catch (ex: any)
    {
        if (ex.code == 400) $alert.error(ex.message);
        else if (ex.code == 422) config.$errors.record(ex.data.errors);
    }
};

const hidden = () =>
{
    widget.clear();
    config.clear();
};

defineExpose({
    showModal,
});
</script>

<template>
    <IdeoModal centered ref="modal" size="lg" :title="$t('[[[Ustawienia widgetu]]]')" @hidden="hidden">
        <template #default>
            <IdeoForm
                id="widget-settings-modal-form"
                @submit.prevent="onSubmit"
                @input="config.$errors.clear($event.target.name)"
            >
                <IdeoFormGroup :label="$t('[[[Nazwa]]]')" required>
                    <IdeoFormInput
                        :model-value="widget.name"
                        :placeholder="$t('[[[Nazwa]]]')"
                        type="text"
                        name="name"
                        disabled
                    />
                </IdeoFormGroup>

                <IdeoFormGroup :label="$t('[[[Opis]]]')">
                    <IdeoFormTextarea
                        v-model="widget.description"
                        :placeholder="$t('[[[Opis]]]')"
                        name="description"
                        rows="4"
                        disabled
                    />
                </IdeoFormGroup>

                <IdeoFormGroup
                    v-if="isPageSize"
                    :label="$t('[[[Liczba wyników na stronie]]]')"
                    :invalid-feedback="config.$errors.first('pageSize')"
                    :state="config.$errors.state('pageSize')"
                    required
                >
                    <IdeoFormInput
                        v-model="(config.sourceConfig as Record<string, any>).pageSize"
                        type="number"
                        name="pageSize"
                    />
                </IdeoFormGroup>

                <div class="row">
                    <div class="col-md-6">
                        <IdeoFormGroup
                            :label="$t('[[[Szerokość]]]')"
                            :invalid-feedback="config.$errors.first('width')"
                            :state="config.$errors.state('width')"
                            :nospace="!media.phone()"
                            required
                        >
                            <IdeoFormSelect
                                v-model="config.width"
                                name="width"
                                :options="WIDTH_OPTIONS"
                                @update:modelValue="config.$errors.clear('width')"
                            />
                        </IdeoFormGroup>
                    </div>
                    <div class="col-md-6">
                        <IdeoFormGroup
                            :label="$t('[[[Wysokość]]]')"
                            :invalid-feedback="config.$errors.first('height')"
                            :state="config.$errors.state('height')"
                            nospace
                            required
                        >
                            <IdeoFormSelect
                                v-model="config.height"
                                name="height"
                                :options="HEIGHT_OPTIONS"
                                @update:modelValue="config.$errors.clear('height')"
                            />
                        </IdeoFormGroup>
                    </div>
                </div>
            </IdeoForm>
        </template>
        <template #modal-footer="{ cancel }">
            <IdeoButton variant="light" @click="cancel">
                {{ $t('[[[Anuluj]]]') }}
            </IdeoButton>
            <IdeoButton
                type="submit"
                form="widget-settings-modal-form"
                :variant="submitBtn.variant"
                :disabled="!config.active()"
            >
                {{ submitBtn.text }}
            </IdeoButton>
        </template>
    </IdeoModal>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { useLocalization } from '@/plugins/localization';

const { $t } = useLocalization();

const props = defineProps<{
    item: any,
    activeId?: any
}>();

const emit = defineEmits<{
    (e: 'select', element: any): void;
}>();

const expanded = ref(false);

onMounted(() =>
{
    expanded.value = props.item?.items?.findIndex((el: any) => el.id === props.activeId) !== -1;
});
</script>

<template>
    <div class="extended-list">
        <div
            class="extended-list-title px-2 cursor-pointer user-select-none"
            :class="{active: expanded}"
            @click="expanded = !expanded"
        >
            <span :class="['caret fas fa-fw', `fa-caret-${expanded ? 'down' : 'right'}`]" />
            <span :class="['icon fas fa-fw', item.icon]" />
            {{ $t(`[[[${item.text}]]]`) }}
        </div>

        <div
            class="extended-list-items ms-4 ps-2 me-1"
            :class="{'mb-2': expanded}"
            v-show="expanded"
        >
            <div
                v-for="(el, index) in item.items"
                :key="index"
                class="d-flex align-items-center justify-content-between py-1"
                :class="{active: activeId === el?.id}"
                @click="emit('select', el)"
            >
                {{ $t(`[[[${el.text}]]]`) }}
                <b>{{ el.count }}</b>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
[data-bs-theme=dark] .extended-list {
    &-title {
        color: var(--bs-gray-400);

        &.active {
            background: var(--bs-gray-600);
            color: var(--bs-gray-100);
        }
    }

    &-items {
        & > div {
            color: var(--bs-gray-500);

            &:not(.active) {
                &:hover {
                    color: var(--bsgray-100);
                }
            }
        }
    }
}

.extended-list {
    &-title {
        padding: 0.3rem 0;
        border-radius: 0.35rem;
        color: var(--bs-gray-600);

        &.active {
            background: var(--bs-primary-bg-subtle);
            color: var(--bs-gray-800);
        }

        .caret {
            font-size: 0.65rem;
        }
        .icon {
            margin-left: 0.5rem;
            margin-right: 0.25rem;
        }
    }

    &-items {
        & > div {
            margin: 0.1rem 0;
            color: var(--bs-gray-600);
            cursor: pointer;

            &.active {
                color: var(--bs-primary);
            }

            b {
                font-size: 0.65rem;
                padding-left: 0.5rem;
            }

            &:not(.active) {
                &:hover {
                    color: var(--bs-gray-800);
                }
            }
        }
    }
}
</style>

<script lang="ts" setup>
import { computed } from 'vue';
import { isString } from 'lodash';
import avatarImg from '@/assets/img/avatar.jpg';

const BLANK_TEMPLATE =
  '<svg width="%{w}" height="%{h}" ' +
  'xmlns="http://www.w3.org/2000/svg" ' +
  'viewBox="0 0 %{w} %{h}" preserveAspectRatio="none">' +
  '<rect width="100%" height="100%" style="fill:%{f};"></rect>' +
  '</svg>';

defineOptions({
    name: 'ideo-image'
});

const props = withDefaults(defineProps<{
    blank?: boolean;
    blankColor?: string;
    blankType?: 'color'|'avatar';
    block?: boolean;
    center?: boolean;
    fluid?: boolean;
    fluidGrow?: boolean;
    height?: number|string;
    left?: boolean;
    right?: boolean;
    rounded?: false|true|'top'|'right'|'bottom'|'left'|'circle'|'0';
    src: string;
    thumbnail?: boolean;
    width?: number|string;
}>(), {
    blankColor: 'transparent',
    blankType: 'color',
    height: undefined,
    width: undefined,
    rounded: false,
});

const height = computed(() => isNaN(Number(props.height)) ? undefined : Number(props.height));
const width = computed(() => isNaN(Number(props.width)) ? undefined : Number(props.width));

const flag = (value: any): boolean =>
{
    return value !== false;
};

const blankImgSrc = (): string =>
{
    switch (props.blankType)
    {
        case 'color':
        {
            const src = encodeURIComponent(
                BLANK_TEMPLATE.replace('%{w}', (width.value || 1).toString())
                    .replace('%{h}', (height.value || 1).toString())
                    .replace('%{f}', props.blankColor)
            );

            return `data:image/svg+xml;charset=UTF-8,${src}`;
        }
        case 'avatar':
        {
            return avatarImg;
        }
    }
};

const source = computed(() => flag(props.blank) ? blankImgSrc() : props.src);

const customClasses = computed(() => ({
    'img-thumbnail': flag(props.thumbnail),
    'img-fluid': flag(props.fluid) || flag(props.fluidGrow),
    'float-start': flag(props.left),
    'float-end': flag(props.right),
    'mx-auto': flag(props.center),
    'd-block': flag(props.block) || flag(props.center),
    'w-100': flag(props.fluidGrow),
    'rounded': flag(props.rounded),
    [`rounded-${props.rounded}`]: isString(props.rounded) && props.rounded.length > 0
}));
</script>

<template>
    <img :src="source" :width="width" :height="height" :class="customClasses">
</template>

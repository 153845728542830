<script lang="ts" setup>
import { ref, computed, inject, onMounted } from 'vue';

defineOptions({
    name: 'ideo-dropdown-item-button'
});

const props = withDefaults(defineProps<{
    tag?: string;
    active?: boolean;
    activeClass?: string;
    disabled?: boolean;
    variant?: null|'primary'|'secondary'|'success'|'danger'|'warning'|'info'|'light'|'dark';
    preventClose?: boolean;
    message?: string;
    messagePlacement?: string;
    value?: any;
}>(), {
    tag: 'button',
    active: false,
    activeClass: 'active',
    disabled: false,
    variant: null,
    preventClose: false,
    message: null,
    messagePlacement: 'right',
    value: null,
});

const emit = defineEmits<{
    (e: 'confirm', item: any): void;
}>();

const hideDropdown = inject<any>('hideDropdown');
const buttonRef = ref<any>(null);
const loaded = ref(false);

const customClasses = computed<Record<string, boolean>>(() => ({
    'dropdown-item': true,
    [props.activeClass]: props.active,
    [`text-${props.variant}`]: props.variant != null
}));

onMounted(() =>
{
    loaded.value = true;
});

const onConfirm = (item: any): void =>
{
    emit('confirm', item);
};

const onClick = (): void =>
{
    if (props.preventClose || props.message)
        return;

    hideDropdown();
};
</script>

<template>
    <component :is="tag" type="button" ref="buttonRef" :class="customClasses" :disabled="disabled" :data-dd="$.uid" @click="onClick">
        <slot name="default"></slot>
        <confirmation v-if="loaded && message" :message="message" :target="buttonRef" :value="value" @confirm="onConfirm" :placement="messagePlacement" />
    </component>
</template>

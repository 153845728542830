<script lang="ts" setup>
import { ref, computed, onBeforeMount } from 'vue';
import { useRoute } from 'vue-router';
import sitemap from '@/settings/sitemap';

defineOptions({
    name: 'action-header'
});

const route = useRoute();

const props = withDefaults(defineProps<{
    headerTitle: string;
    headerIcon: string;
    autoHeader?: boolean;
}>(), {});

const header = ref(null);

const title = computed((): string => header.value ? header.value.name : props.headerTitle);
const icon = computed((): string => header.value ? header.value.icon : props.headerIcon);

onBeforeMount(() =>
{
    if (props.autoHeader)
    {
        const getHeaderNode = (items: any[], route: string): any =>
        {
            return items.reduce((value: any, item: any) =>
            {
                if (item && item.route === route)
                    return item;

                if (item && item.children && item.children.length > 0)
                    return getHeaderNode(item.children, route) || value;

                return value;
            }, null);
        };

        header.value = getHeaderNode(sitemap, route.name.toString());
    }
});
</script>

<template>
    <h5 class="me-1 my-0" v-if="title">
        <i class="me-2" :class="icon" v-if="icon"></i>
        <span>{{ $t(title) }}</span>
    </h5>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount, nextTick } from 'vue';
import { useMixins } from '@/plugins/mixins';
import { SitemapNode, useSitemap } from '@/plugins/sitemap';
import { useRoute } from 'vue-router';
import { LicenceModel } from '@/modules/wms/configuration/settings/services/WmsSettingsService';
import { useEvents } from '@/plugins/events';
import { useSignalR } from '@/plugins/signalr';
import VersionService from '@/modules/wms/common/services/VersionService';
import { DocumentCountModel } from '@/modules/wms/common/services/ModulesService';
import { useLogging } from '@/plugins/logging';
import { useLicences } from '@/plugins/licences';
import { useWmsModulesCounter } from '@/plugins/wmsModuleCounter';
import Logo from '../layout/Logo.vue';
import { LOGO, useThemeStore } from '@/store/theme';
import { ColorTranslator } from 'colortranslator';

const { $redirect } = useMixins();
const { $sitemap } = useSitemap();
const route = useRoute();
const { $events } = useEvents();
const { channel } = useSignalR();
const server = channel('global');
const { $log } = useLogging();
const { $wmsmodulescounter } = useWmsModulesCounter();
const { $licence } = useLicences();

const sitemap = ref<any[]>([]);
const toggled = ref<any[]>([]);

const props = withDefaults(defineProps<{
    collapsed?: boolean;
    hover?: boolean;
}>(), {
    collapsed: false,
    hover: false
});

const collapsed = computed(() => props.collapsed);

const wmsVersion = ref<string>('');
const licences = ref<LicenceModel>(null);

const moreThanOneApplicationEnabled = computed<boolean>(() =>
{
    if (!licences.value.applicationAccess)
        return false;

    const wmsAccess = licences.value.applicationAccess.find(x => x.id == 'wms');
    const mesAccess = licences.value.applicationAccess.find(x => x.id == 'mes');

    if (wmsAccess && wmsAccess.value == true && mesAccess && mesAccess.value == true)
        return true;

    return false;
});

const style = computed(() =>
{
    const { colors, theme } = useThemeStore();

        if (colors.nav.bg && theme == 'light')
        {
            const bg = new ColorTranslator(colors.nav.bg);

            if (bg.L > 80)
                return 'light';
        }

        return 'dark';

})
onMounted(async () =>
{
    await loadLicences();
    await loadWmsVersion();

    sitemap.value = await $sitemap.all();
    toggled.value = await $sitemap.crumbs(route);

    $events.$on("save-settings", saveOnSettings);
});

onBeforeUnmount(() =>
{
    $events.$off("save-settings", saveOnSettings);
    $sitemap.purge();
});

const saveOnSettings = async (): Promise<void> =>
{
    $sitemap.purge();

    await loadLicences();

    sitemap.value = await $sitemap.all();
    toggled.value = await $sitemap.crumbs(route);
};


const active = (node: any) =>
{
    return $sitemap.active(node, route, true);
};

const url = (node: any) =>
{
    return $sitemap.url(node, route);
};

const root = computed((): any =>
{
    return sitemap.value.filter((p: any) => p.visible);
});

const children = (node: any) =>
{
    return node.children.filter((p: any) => p.visible);
};

const toggle = (node: any) =>
{
    if (children(node).length > 0)
    {
        if (expanded(node))
        {
            toggled.value = toggled.value.filter(p => p !== node);
        }
        else
        {
            toggled.value = [];
            $sitemap.path(node).forEach(p =>
                toggled.value.push(p)
            );
        }
    }
    else
    {
        $redirect(url(node));
    }
};

const expanded = (node: any) =>
{
    return toggled.value.includes(node);
};

const updateDocumentsCounterByModule = async (module: string) : Promise<void> =>
{
    try
    {
        const toCheck = [
            module,
            module + "_warehouse",
            module + "_trade"
        ];

        setTimeout(async () =>
        {
            const counts = await $wmsmodulescounter.get();

            for (let index = 0; index < toCheck.length; index++)
            {
                const element = toCheck[index];

                const valueForModule = counts.find(x => x.module == element);

                if (valueForModule)
                {
                    for (let index = 0; index < sitemap.value.length; index++)
                    {
                        if (!sitemap.value[index].visible)
                            continue;

                        setCounterValue(sitemap.value[index], valueForModule);
                    }
                }
            }

        }, 1000);
    }
    catch (ex)
    {
        $log.error(ex);

        return;
    }
};

const setCounterValue = (model: SitemapNode, value: DocumentCountModel) : void =>
{
    if (model.showCounterFromModules && model.showCounterFromModules.includes(value.module))
    {
        nextTick(() =>
        {
            if (!model.counter)
            {
                model.counter = {
                    count: value.count
                };
            }
            else
                model.counter.count = value.count;
        });
    }

    if (!model.children)
        return;

    for (let index = 0; index < model.children.length; index++)
    {
        if (!model.children[index].visible)
            continue;

        setCounterValue(model.children[index], value);
    }
};

const loadWmsVersion = async (): Promise<void> =>
{
    try
    {
        const response = await VersionService.getWmsVersion();

        if (response)
            wmsVersion.value = response.result.version;
    }
    catch (ex)
    {
        //
    }
};

const loadLicences = async (): Promise<void> =>
{
    licences.value = await $licence.getFull();
};

const logoSrc = (appName: string): any =>
{
    return LOGO[appName][style.value].default;
}

server.on.updateDocumentsCounterByModule = updateDocumentsCounterByModule;

defineExpose({
    updateDocumentsCounterByModule
});
</script>

<template>
    <header>
        <Logo v-model:collapsed="collapsed" :hover="hover || mobile"></Logo>
    </header>
    <section class="scroll">
        <ul class="menu" :class="{'collapsed': collapsed, 'hover': hover, 'mobile': mobile}">
            <li v-for="(node1, i) in root" :key="i" :class="{'active': active(node1), 'grouped-node': !node1.clickable && moreThanOneApplicationEnabled, 'grouped-node-disabled': !node1.clickable && !moreThanOneApplicationEnabled}">
                <span class="grouped-node-name" v-if="!node1.clickable">
                    <div v-if="moreThanOneApplicationEnabled" class="app-logo">
                        <img :src="logoSrc(node1.applicationAccess[0])" alt="">
                    </div>
                </span>
                <router-link v-else :to="url(node1)" @click.prevent="toggle(node1)" :aria-label="$t(node1.name)">
                    <i :class="node1.icon"></i>
                    <span>{{ $t(node1.name) }}</span>
                    <span v-if="node1.counter.count > 0" class="counter-wrapper">
                        <span class="counter">{{ node1.counter.count }}</span>
                    </span>
                    <b v-if="children(node1).length > 0 && !expanded(node1)"><i class="fas fa-chevron-left"></i></b>
                    <b v-else-if="children(node1).length > 0 && expanded(node1)"><i class="fas fa-chevron-down"></i></b>
                </router-link>
                <ul :class="{'expanded': expanded(node1) || !node1.clickable, 'grouped-nodes-level': !node1.clickable}" v-if="children(node1).length > 0">
                    <li v-for="(node2, i) in children(node1)" :key="i" :class="{'active': active(node2), 'grouped-node': !node2.clickable && moreThanOneApplicationEnabled, 'grouped-node-disabled': !node2.clickable && !moreThanOneApplicationEnabled}">
                        <span class="grouped-node-name" v-if="!node2.clickable">
                            <span v-if="moreThanOneApplicationEnabled">{{ $t(node2.name) }}</span>
                        </span>
                        <router-link v-else :class="{'second-level-item': !children(node2).length && node2.clickable}" :to="url(node2)" @click.prevent="toggle(node2)" :aria-label="$t(node2.name)">
                            <i :class="node2.icon" v-if="!node1.clickable"></i>
                            <i class="fas fa-fw fa-minus" v-else></i>
                            <span>{{ $t(node2.name) }}</span>
                            <span v-if="node2.counter.count > 0" class="counter-wrapper">
                                <span class="counter">{{ node2.counter.count }}</span>
                            </span>
                            <b v-if="children(node2).length > 0 && !expanded(node2)"><i class="fas fa-chevron-left"></i></b>
                            <b v-else-if="children(node2).length > 0 && expanded(node2)"><i class="fas fa-chevron-down"></i></b>
                        </router-link>
                        <ul :class="{'expanded': expanded(node2) || !node2.clickable, 'grouped-nodes-level': !node2.clickable}" v-if="children(node2).length > 0">
                            <li v-for="(node3, i) in children(node2)" :key="i" :class="{'active': active(node3), 'grouped-node': !node3.clickable && moreThanOneApplicationEnabled, 'grouped-node-disabled': !node3.clickable && !moreThanOneApplicationEnabled}">
                                <span class="grouped-node-name" v-if="!node3.clickable && moreThanOneApplicationEnabled">
                                    {{ $t(node3.name) }}
                                </span>
                                <router-link v-else :class="{'second-level-item': !children(node3).length}" :to="url(node3)" @click.prevent="toggle(node3)" :aria-label="$t(node3.name)">
                                    <i class="fas fa-fw fa-minus" v-if="!node1.clickable || !node2.clickable"></i>
                                    <i class="fas fa-fw fa-circle-small fa-2xs mt-1" v-else></i>
                                    <span>{{ $t(node3.name) }}</span>
                                    <span v-if="node3.counter.count > 0" class="counter-wrapper">
                                        <span class="counter">{{ node3.counter.count }}</span>
                                    </span>
                                    <b v-if="children(node3).length > 0 && !expanded(node3)"><i class="fas fa-chevron-left"></i></b>
                                    <b v-else-if="children(node3).length > 0 && expanded(node3)"><i class="fas fa-chevron-down"></i></b>
                                    <!-- <b v-else></b> -->
                                </router-link>
                                <ul :class="{'expanded': expanded(node3), 'grouped-nodes-level': !node3.clickable && moreThanOneApplicationEnabled}" v-if="children(node3).length > 0">
                                    <li v-for="(node4, i) in children(node3)" :key="i" :class="{'active': active(node4), 'grouped-node': !node4.clickable && moreThanOneApplicationEnabled, 'grouped-node-disabled': !node4.clickable && !moreThanOneApplicationEnabled}">
                                        <span class="grouped-node-name" v-if="!node4.clickable && moreThanOneApplicationEnabled">
                                            {{ $t(node4.name) }}
                                        </span>
                                        <router-link :to="url(node4)" @click.prevent="toggle(node4)" :aria-label="$t(node4.name)">
                                            <i class="fas fa-fw fa-circle-small fa-2xs mt-1"></i>
                                            <span>{{ $t(node4.name) }}</span>
                                            <span v-if="node4.counter.count > 0" class="counter-wrapper">
                                                <span class="counter">{{ node4.counter.count }}</span>
                                            </span>
                                            <!-- <b></b> -->
                                        </router-link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </section>
    <footer class="d-flex justify-content-center align-items-center">
        Asiston CORE {{ wmsVersion }}
    </footer>
</template>

<style lang="scss" scoped>
header {
    height: var(--ideo-header-height);
}
section {
    height: calc(100dvh - var(--ideo-header-height) - var(--ideo-footer-height));
}
footer {
    height: var(--ideo-footer-height);
}
ul.menu {
    --ideo-menu-display: block;
    --ideo-menu-item-space: 4px;
    --ideo-menu-icon-space: 20px;
    --ideo-menu-link-color: var(--ideo-nav-color);
    --ideo-menu-active-color: var(--bs-white);
    --ideo-menu-active-bg: var(--bs-primary);
    --ideo-menu-bg-secondary: rgba(255, 255, 255, 0.05);
    --ideo-menu-radius: var(--bs-border-radius);

    min-height: 100%;
    padding-top: .5rem;
    width: var(--ideo-nav-width-default);

    &.collapsed {
        --ideo-menu-display: none;

        &.hover {
            --ideo-menu-display: block;
        }

        &:not(.hover) .active > a {
            background-color: inherit;

            > span, > i, > b {
                color: inherit;
            }
        }
    }
}

// pierwszy poziom
ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
        position: relative;
        padding: 0;
        margin: 0;

        > a {
            display: flex;
            flex-direction: row;
            align-items: stretch;
            padding: 9px 0;
            margin: calc(var(--ideo-menu-item-space) / 2) var(--ideo-menu-item-space) 0 var(--ideo-menu-item-space);
            border-radius: var(--ideo-menu-radius);
            text-decoration: none;
            font-size: 14px;
            font-weight: 500;
            color: var(--bs-white);

            > i {
                margin: 0 var(--ideo-menu-icon-space) 0 calc(var(--ideo-menu-icon-space) - var(--ideo-menu-item-space));
                min-width: 20px;
                line-height: 1.5em;
            }
            > span {
                display: var(--ideo-menu-display);
                flex-grow: 1;
            }
            > b {
                display: var(--ideo-menu-display);
                margin: 3px 15px 0 10px;
                min-width: 10px;
                text-align: center;
                font-size: .625rem;
            }

            &:hover {
                background-color: var(--ideo-menu-active-bg) !important;

                > span, > i, > b {
                    color: var(--ideo-menu-active-color) !important;
                }
            }
        }

        > .grouped-node-name {
            padding: 0 5px;
            position: absolute;
            left: 8px;
            top: 0;
            transform: translateY(-50%);
            font-size: 14px;
            font-weight: bold;
            color: var(--bs-white);
            background-color: var(--ideo-nav-bg);
        }

        ul .grouped-node-name {
            background-color: #363d4c;

            [data-bs-theme="dark"] & {
                background-color: #2c3034;
            }

            .mobile & {
                background-color: #2b3342;

                [data-bs-theme="dark"] & {
                    background-color: #212529;
                }
            }
        }

        &.active:not(:has(.active)) {
            > a {
                background-color: var(--ideo-menu-active-bg);

                > span, > i, > b {
                    color: var(--ideo-menu-active-color);
                }
            }
        }
    }
}

// drugi poziom
ul ul:not(.grouped-nodes-level) {
    display: var(--ideo-menu-display);
    overflow: hidden;
    height: 0;
    transition: all 0.5s cubic-bezier(0, 1, 0.5, 1);
    background-color: var(--ideo-menu-bg-secondary);
    border-radius: var(--ideo-menu-radius);

    &.expanded {
        height: auto;
        padding: 0;
    }

    &.grouped-nodes-level {
        display: block !important;
    }

    > li {
        > a {
            // padding-left: var(--ideo-nav-width-collapsed);
            font-size: 13px;
        }
        &:first-child {
            margin-top: var(--ideo-menu-item-space);
        }
        &:last-child {
            margin-bottom: var(--ideo-menu-item-space);
        }
    }
}

// trzeci poziom
ul ul:not(.grouped-nodes-level) ul:not(.grouped-nodes-level) {
    background-color: transparent;

    > li {
        margin: 0 !important;

        > a {
            padding: 7px 0 7px 16px; //calc(var(--ideo-nav-width-collapsed) + 12px);
            font-size: 12px;
        }
    }
}

.grouped-node {
    padding: 8px 0 4px 0;
    margin: 10px 6px !important;
    border: 1px solid var(--bs-white);
    border-radius: var(--ideo-menu-radius);
}

ul ul ul.grouped-nodes-level {
    > li {
        > a {
            font-size: 13px;
        }
    }
}

.menu.mobile {
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    li:not(.grouped-node):not(.grouped-node-disabled) {
        border-radius: 5px;
        border: 1px solid #FFF;
    }

    ul {
        &.expanded {
            padding: 0 20px 20px 20px;
        }
    }

    li.grouped-node-disabled {
        & > ul {
            &.expanded {
                padding: 0;
            }
        }
    }

    // pierwszy poziom
    li {
        > a {
            padding: 20px 0;
            margin: 0;
            align-items: center;
            > i {
                margin: 0 20px;
                font-size: 20px;
            }
            > b {
                margin: 0 20px 0 0;
                min-width: 16px;
                text-align: center;
                font-size: 16px;
            }
            &:hover {
                background-color: transparent !important;
                > span, > i, > b {
                    color: var(--ideo-menu-link-color) !important;
                }
            }
        }
        &.active:not(:has(.active)) {
            > a {
                background-color: var(--ideo-menu-active-bg);

                > span, > i, > b {
                    color: var(--ideo-menu-active-color) !important;
                }
            }
        }
    }

    // drugi poziom
    ul {
        transition: none;
        display: flex;
        flex-direction: column;
        gap: 10px;
        background-color: transparent;

        > li {
            > a {
                padding: 20px 0 20px 20px;
            }
        }
    }

    li.grouped-node-disabled {
        ul {
            > li {
                > a {
                    padding: 20px 0;
                }
            }
        }
    }

    // trzeci poziom
    ul ul {
        > li {
            > a {
                padding: 15px 10px;
            }
        }
    }

    .grouped-node {
        padding: 15px 0 0;
        margin: 10px 4px;
        border: 1px solid var(--bs-white);
        border-radius: var(--ideo-menu-radius);
    }
}

.counter-wrapper {
    text-align: right;
    margin: auto 0;

    .counter {
        margin: 0 5px;
        padding: 3px 8px;
        border-radius: var(--ideo-menu-radius);
        background-color: var(--bs-primary);
    }
}

.app-logo {
    width: 56px;
    height: 20px;

    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}
</style>

import { axios } from '@/plugins/axios';
import { Resource } from '@/helpers/Interfaces';

export enum CounterModuleName {
    receipt_warehouse = "receipt_warehouse",
    receipt_trade = "receipt_trade",
    direct_receipt_warehouse = "direct_receipt_warehouse",
    direct_receipt_trade = "direct_receipt_trade",
    transfer = "transfer",
    one_stage_issue = "one_stage_issue",
    collecting = "collecting",
    packing = "packing",
    courier = "courier",
    personal_collection = "quality_control",
    inventories = "inventories"
}

export default class ModulesService
{
    public static async getActiveStatus(): Promise<Resource<ProcessEnabledModel[]>>
    {
        return (await axios.get<Resource<ProcessEnabledModel[]>>('admin/modules/enablestatus')).data;
    }

    public static async getDocumentsCount(): Promise<Resource<DocumentCountModel[]>>
    {
        return (await axios.get<Resource<DocumentCountModel[]>>('admin/modules/countdocuments')).data;
    }
}

export interface ProcessEnabledModel {
    moduleName: string;
    value: boolean;
}
export interface DocumentCountModel {
    module: string
    count: number
}
<template>
    <div class="chart-widget">
        <Doughnut v-if="loaded" :options="chartOptions" :data="chartData" />
    </div>
</template>

<script lang="ts" setup>
import { computed, ref, onMounted } from 'vue';
import { Resource } from '@/helpers/Interfaces';
import { useAlerts } from '@/plugins/alerts';
import { useLocalization } from '@/plugins/localization';
import { useMixins } from '@/plugins/mixins';
import WidgetsService, { PiecesIssuedByCouriersCountModel } from "@/modules/core/common/services/WidgetsService";
import { WidgetModel, WidgetConfigModel } from '@/modules/core/dashboards/services/DashboardsService';

import { Doughnut } from 'vue-chartjs';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const props = withDefaults(defineProps<{
    widget: WidgetModel;
    config: WidgetConfigModel;
}>(), {
    widget: null,
    config: null
});

defineOptions({
    name: 'PiecesIssuedByCouriersWidget',
    components: {
        Doughnut
    },
    inheritAttrs: false
});

const { $alert } = useAlerts();
const { $t } = useLocalization();
const { $handleException } = useMixins();

const items = ref<Resource<PiecesIssuedByCouriersCountModel>[]>([]);
const loaded = ref<boolean>(false);

const chartData = computed<ChartData<'doughnut'>>(() =>
{
    const labels = items.value.map((item: Resource<PiecesIssuedByCouriersCountModel>) => item.result.name);
    const data = items.value.map((item: Resource<PiecesIssuedByCouriersCountModel>) => item.result.numOfPieces);

    return {
        labels: labels,
        datasets: [{
            label: $t('[[[Ilość]]]'),
            data: data,
            // backgroundColor: ['#03a4dc', '#f28705', '#ff4255', '#4dbd73'],
        }]
    };
});
const chartOptions = computed(() =>
{
    return {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            datalabels: {
                display: false
            }
        }
    };
});

onMounted(async (): Promise<void> =>
{
    await loadData();
});

const loadData = async (): Promise<void> =>
{
    loaded.value = false;

    try
    {
        const response = await WidgetsService.getPiecesIssuedByCouriersCount();

        items.value = response;
    }
    catch (ex)
    {
        items.value = [];

        $handleException(ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });
    }
    finally
    {
        loaded.value = true;
    }
};
</script>

<style lang="scss" scoped>
.name {
    font-weight: 600;
}
</style>

import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { ValidatableBlueprint } from '@/components/builder/base/blueprints/ValidatableBlueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { FormBuilderContract } from '@/components/builder/form';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { CustomErrorBlueprint } from '@/components/builder/form/blueprints/CustomErrorBlueprint';
import { ReadonlyBlueprint } from '@/components/builder/form/blueprints/ReadonlyBlueprint';
import { RequiredBlueprint } from '@/components/builder/form/blueprints/RequiredBlueprint';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { Entry, entry, instanceOfEntry } from '@/components/builder/form/entries/Entry';
import { ValidEntry } from '@/components/builder/form/entries/ValidEntry';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { InternallyChoice } from '@/components/builder/form/enums/InternallyChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';
import { HasFilter } from '@/components/builder/form/traits/HasFilter';
import { HasHelp } from '@/components/builder/form/traits/HasHelp';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { HasPlaceholder } from '@/components/builder/form/traits/HasPlaceholder';
import { instanceOfRelational, Relational } from '@/components/builder/form/traits/Relational';
import { ProcessCallback } from '@/components/builder/form/types/ProcessCallback';
import { Option } from '@/helpers/Interfaces';

export const Definition: BlueprintDefinition = {
    type: 'related-parent',
    name: '[[[Kategoria]]]',
    icon: 'fa-tag',
    group: 'relational',
    position: 1,
    disabled: false
};

export class RelatedParentEntry extends ValidEntry<Option<number>[]>
{
    public type: string = Definition.type;
    public data: Option<number>[] = [];

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.data = data;
        }
    }

    public async collect(blueprint: RelatedParentContract, form: FormBuilderContract, preprocess: ProcessCallback): Promise<Entry>
    {
        const result = await preprocess(blueprint, this, form.blueprintId, form.entryId);

        return entry({
            type: this.type,
            data: this.data,
            ...(result ?? {})
        });
    }

    public validate(blueprint: RelatedParentContract, form: FormBuilderContract): boolean
    {
        this.errors = {};

        if (!form.expressions.readonly(blueprint, true) && form.expressions.visible(blueprint, true))
        {
            if (form.expressions.required(blueprint) && (this.data == null || this.data.length == 0))
            {
                this.errors.values = [`[[[Pole "%0" jest wymagane.|||${form.localization.translate(blueprint.label)}]]]`];
            }
            else if (form.expressions.customError(blueprint))
            {
                this.errors.custom = [form.expressions.customErrorMessage(blueprint, form)];
            }
        }

        return this.valid();
    }
}

export const instanceOfRelatedParentEntry = (object: any): object is RelatedParentEntry =>
{
    return instanceOfEntry(object) && 'type' in object && object.type === Definition.type;
};

export interface RelatedParentContract extends Blueprint, VisibleBlueprint, ReadonlyBlueprint, RequiredBlueprint, CustomErrorBlueprint, HasLabel, HasPlaceholder, HasHelp, HasWidth, HasFilter, Relational
{
    multiple: boolean;
}

export class RelatedParentType implements RelatedParentContract, ValidatableBlueprint, EntryFactory<RelatedParentEntry>
{
    public id: string;
    public type: string;
    public name: string;
    public label: Record<string, string>;
    public showLabel: boolean;
    public placeholder: Record<string, string>;
    public moduleId: number;
    public sectionId: string;
    public categoryId: number;
    public formId: number;
    public componentId: string;
    public dependsOn: string[];
    public multiple: boolean;
    public help: Record<string, string>;
    public minWidth: number;
    public width: number;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public required: AlwaysChoice | NeverChoice | WhenChoice;
    public requiredWhen: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: Record<string, string>;
    public errors: ValidationErrors;
    public showFilter: boolean;

    public constructor(id: string, name: string)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.label = { 'pl-PL': 'Kategoria' };
        this.showLabel = true;
        this.placeholder = {};
        this.moduleId = null;
        this.sectionId = '';
        this.categoryId = null;
        this.formId = null;
        this.componentId = '';
        this.dependsOn = [];
        this.multiple = false;
        this.help = {};
        this.minWidth = 1;
        this.width = 0;
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.customErrorMessage = {};
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.required = NeverChoice.Never;
        this.requiredWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
        this.showFilter = false;
    }

    public setDefaultWidth(width: number): void
    {
        this.width = Math.min(3, Math.max(this.minWidth, width));
    }

    public createEntry(data: any): RelatedParentEntry
    {
        return new RelatedParentEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        if (!this.categoryId)
            this.errors.categoryId = ['[[[Wymagane jest wybranie kategorii]]]'];

        if (!this.formId)
            this.errors.formId = ['[[[Wymagane jest wybranie formularza]]]'];

        if (!this.componentId)
            this.errors.componentId = ['[[[Wymagane jest wybranie komponentu]]]'];

        return {
            [this.name]: this.errors
        };
    }
}

export const instanceOfRelatedParentType = (object: any): object is RelatedParentType =>
{
    return instanceOfRelational(object) && object.type == Definition.type && (object as any).multiple == false;
};

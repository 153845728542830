<script lang="ts" setup>
import { ref, computed, watch, useSlots, getCurrentInstance, onMounted, onBeforeUnmount, nextTick } from 'vue';
import { normalizeClasses } from '@/helpers/Utils';
import { useEvents } from '@/plugins/events';

defineOptions({
    name: 'ideo-tabs'
});

const { $events } = useEvents();
const slots = useSlots();
const instance = getCurrentInstance();

const contentRef = ref(null);
const tabs = ref([]);
const activeTabIndex = ref(0);

const props = withDefaults(defineProps<{
    modelValue?: number;
    linear?: boolean;
    activeNavItemClass?: Record<string, boolean> | string[] | string;
    activeTabClass?: Record<string, boolean> | string[] | string;
    align?: null|'start'|'center'|'end';
    card?: boolean;
    contentClass?: Record<string, boolean> | string[] | string;
    end?: boolean;
    fill?: boolean;
    justified?: boolean;
    navClass?: Record<string, boolean> | string[] | string;
    navWrapperClass?: Record<string, boolean> | string[] | string;
    pills?: boolean;
    underline?: boolean;
    vertical?: boolean;
}>(), {
    modelValue: 0,
    activeNavItemClass: () => ({}),
    activeTabClass: () => ({}),
    contentClass: () => ({}),
    navClass: () => ({}),
    navWrapperClass: () => ({}),
    align: null
});

const emit = defineEmits<{
    (e: 'update:modelValue', index: number): void;
}>();

const uid = computed(() => instance.uid);


const flag = (value: any): boolean =>
{
    return value !== false;
};

const wrapperClasses = computed((): Record<string, boolean> =>
{
    const vertical = flag(props.vertical);
    const card = flag(props.card) && !props.vertical;

    return {
        card,
        row: vertical
    };
});

const navWrapperClasses = computed((): Record<string, boolean> =>
{
    const vertical = flag(props.vertical);
    const card = flag(props.card) && !props.vertical;
    const end = flag(props.end);
    const top = !end;

    return {
        'card-header': card && top,
        'card-footer': card && end,
        'col-auto d-flex align-items-stretch': vertical,
        ...normalizeClasses(props.navWrapperClass)
    };
});

const navClasses = computed((): Record<string, boolean> =>
{
    const vertical = flag(props.vertical);
    const card = flag(props.card) && !vertical;
    const underline = flag(props.underline);
    const pills = flag(props.pills) && !underline;
    const linear = flag(props.linear);
    const tabs = !pills && !underline && !linear;
    const end = flag(props.end);
    const top = !end;
    const fill = flag(props.fill);
    const justified = flag(props.justified);

    return {
        'nav': true,
        'flex-column': vertical,
        'nav-underline': underline,
        'nav-pills': pills,
        'nav-tabs': tabs && !vertical,
        'nav-tabs-end': tabs && end && !vertical,
        'card-header-tabs': tabs && card && top,
        'card-footer-tabs': tabs && card && end,
        'card-header-pills': pills && card && top,
        'card-footer-pills': pills && card && end,
        'card-header-underline': underline && card && top,
        'card-footer-underline': underline && card && end,
        'nav-fill': fill,
        'nav-justified': justified,
        'justify-content-center': props.align == 'center',
        'justify-content-end': props.align == 'end',
        'tabs-linear': linear,
        ...normalizeClasses(props.navClass)
    };
});

const contentClasses = computed((): Record<string, boolean> =>
{
    const vertical = flag(props.vertical);
    const card = flag(props.card) && !vertical;

    return {
        'card-body': card,
        'col': vertical,
        ...normalizeClasses(props.contentClass)
    };
});

const tabIndex = (tab: any): number =>
{
    return tabs.value.indexOf(tab);
};

const tabActive = (tab: any): boolean =>
{
    return tabIndex(tab) == activeTabIndex.value;
};

const setActiveTab = (tab: any): void =>
{
    activeTabIndex.value = tabIndex(tab);

    emit('update:modelValue', activeTabIndex.value);
};

const registerTab = (tab: any): void =>
{
    if (!tabs.value.includes(tab))
    {
        tabs.value.push(tab);

        if (tab.props.active !== false)
        {
            setActiveTab(tab);
        }
    }
};

const unregisterTab = (tab: any): void =>
{
    if (tabs.value.includes(tab))
    {
        tabs.value = tabs.value.filter(p => p != tab);

        if (tabs.value.length > 0)
        {
            setActiveTab(tabs.value[0]);
        }
    }
};

watch(() => props.modelValue, (value: number, old: number) =>
{
    if (value != old)
        activeTabIndex.value = value;
}, { immediate: true });

const onErrorOccured = (): void =>
{
    nextTick(() =>
    {
        const invalid = contentRef.value.querySelector('.invalid');

        if (invalid)
        {
            const parentEl = invalid.closest('[data-tab]');

            const index = tabs.value.findIndex(el => el.uid == parentEl.getAttribute('data-tab'));

            if (index !== -1)
                setActiveTab(tabs.value[index]);
        }
    });
};

onMounted(() =>
{
    $events.$on('error-occured', onErrorOccured);
});

onBeforeUnmount(() =>
{
    $events.$off('error-occured', onErrorOccured);
});

defineExpose({
    tabActive,
    tabIndex,
    setActiveTab,
    registerTab,
    unregisterTab
});
</script>

<template>
    <div :class="wrapperClasses">
        <div :class="navWrapperClasses" v-if="!flag(end)">
            <ul :class="navClasses">
                <portal-target :name="`tabs-nav-${uid}`" multiple />
                <li class="nav-item" v-if="'tabs-end' in slots">
                    <slot name="tabs-end"></slot>
                </li>
            </ul>
        </div>
        <div :class="contentClasses" ref="contentRef">
            <slot name="default"></slot>
        </div>
        <div :class="navWrapperClasses" v-if="flag(end)">
            <ul :class="navClasses" :id="`tabs-nav-${uid}`"></ul>
        </div>
    </div>
</template>

<style scoped>
ul.nav:not(:has(> li)) {
    display: none;
}
</style>

<style lang="scss">
.tabs-linear {
    gap: 1rem;

    li.nav-item {
        .nav-link {
            text-transform: none;
            text-align: left;
            color: var(--bs-gray-500);
            border-bottom: 4px solid var(--bs-gray-500);
            padding-left: 0;
            padding-right: 0;

            &.active {
                color: var(--bs-primary);
                border-color: var(--bs-primary);
            }
            &:not(.active) {
                font-weight: 400 !important;
            }
        }

        &[data-active="true"] {
            .nav-link {
                border-color: var(--bs-primary);
            }
        }
    }
}
</style>

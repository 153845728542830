import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { ValidatableBlueprint } from '@/components/builder/base/blueprints/ValidatableBlueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { FormBuilderContract } from '@/components/builder/form';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { CustomErrorBlueprint } from '@/components/builder/form/blueprints/CustomErrorBlueprint';
import { ReadonlyBlueprint } from '@/components/builder/form/blueprints/ReadonlyBlueprint';
import { RequiredBlueprint } from '@/components/builder/form/blueprints/RequiredBlueprint';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { entry, Entry, instanceOfEntry } from '@/components/builder/form/entries/Entry';
import { ValidEntry } from '@/components/builder/form/entries/ValidEntry';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { InternallyChoice } from '@/components/builder/form/enums/InternallyChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';
import { HasHelp } from '@/components/builder/form/traits/HasHelp';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { ProcessCallback } from '@/components/builder/form/types/ProcessCallback';

export const Definition: BlueprintDefinition = {
    type: 'localization',
    name: '[[[Lokalizacja]]]',
    icon: 'fa-map-marker-alt',
    group: 'special',
    position: 6
};

export class LocalizationData
{
    public latitude?: number = null;
    public longitude?: number = null;
    public zoom?: number = null;
}

export class LocalizationEntry extends ValidEntry<LocalizationData>
{
    public type: string = Definition.type;
    public data: LocalizationData = {};

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.data = data;
        }
    }

    public async collect(
        blueprint: LocalizationContract,
        form: FormBuilderContract,
        preprocess: ProcessCallback
    ): Promise<Entry>
    {
        return entry({
            type: this.type,
            data: {
                latitude: this.data.latitude,
                longitude: this.data.longitude,
                zoom: this.data.zoom
            }
        });
    }

    public validate(blueprint: LocalizationContract, form: FormBuilderContract): boolean
    {
        this.errors = {};

        if (!form.expressions.readonly(blueprint, true) && form.expressions.visible(blueprint, true))
        {
            if (form.expressions.required(blueprint) && (this.data.latitude == undefined || this.data.longitude == undefined))
            {
                this.errors.value = [`[[[Pole "%0" jest wymagane.|||${form.localization.translate(blueprint.label)}]]]`];
            }
        }

        return this.valid();
    }
}

export const instanceOfLocalizationEntry = (object: any): object is LocalizationEntry =>
{
    return instanceOfEntry(object) && 'type' in object && object.type === Definition.type;
};

export interface LocalizationContract extends Blueprint, VisibleBlueprint, ReadonlyBlueprint, RequiredBlueprint, CustomErrorBlueprint, HasLabel, HasHelp, HasWidth
{
    zoom: number;
    centerLatitude: number;
    centerLongitude: number;
}

export class LocalizationType implements LocalizationContract, ValidatableBlueprint, EntryFactory<LocalizationEntry>
{
    public id: string;
    public type: string;
    public name: string;
    public label: Record<string, string>;
    public showLabel: boolean;
    public help: Record<string, string>;
    public minWidth: number;
    public width: number;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public errors: ValidationErrors;
    public required: AlwaysChoice | NeverChoice | WhenChoice;
    public requiredWhen: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: Record<string, string>;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public zoom: number;
    public centerLatitude: number;
    public centerLongitude: number;

    public constructor(id: string, name: string)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.label = { 'pl-PL': 'Mapa' };
        this.help = {};
        this.showLabel = true;
        this.minWidth = 6;
        this.width = 6;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.customErrorMessage = {};
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.required = NeverChoice.Never;
        this.requiredWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.zoom = 1;
        this.centerLatitude = 0;
        this.centerLongitude = 0;
    }

    public setDefaultWidth(width: number): void
    {
        this.width = 6;
    }

    public createEntry(data: any): LocalizationEntry
    {
        return new LocalizationEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        return {
            [this.name]: this.errors
        };
    }
}

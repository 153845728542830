import { VNode } from 'vue';

export interface Header
{
    key?: string;
    name?: string;
    title: string;
    sort?: string;
    width?: number;
    visible?: boolean;
    checkbox?: boolean;
    position?: number;
    disabled?: boolean;
    type: 'item'|'header'|'image'|'label'|'buttons'|'checkbox'|'handle'|'hierarchy';
    class?: string;
}

export const getProxy = (): typeof Proxy =>
{
    const base = {
        [Symbol.toPrimitive]: (hint: string): any =>
        {
            switch (hint)
            {
                case 'string': return '';
                case 'number': return 0;
                default: return null;
            }
        },
        [Symbol.toStringTag]: (): any => '',
        '__v_isRef': null as any
    };
    const handler: ProxyHandler<any> = {
        get: (target, property, receiver) =>
        {
            return Reflect.has(target, property) ? Reflect.get(target, property, receiver) : new Proxy(base, handler);
        },
        has: (target, property) =>
        {
            return Reflect.has(target, property);
        }
    };

    return new Proxy(base, handler);
};

export const travel = (elements: VNode[], tagNames: string[]): VNode[] =>
{
    let results: VNode[] = [];

    elements.forEach((element) =>
    {
        const type: any = element.type;

        if (typeof type !== 'symbol' && tagNames.includes(type.name))
        {
            results.push(element);
        }
        else if (element.children && Array.isArray(element.children) && element.children.length > 0)
        {
            results = [...results, ...travel(element.children as VNode[], tagNames)];
        }
    });

    return results;
};

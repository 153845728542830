import { axios } from '@/plugins/axios';
import merge from 'lodash/merge';
import Pager from '@/helpers/Pager';
import { Pagination, Resource, Statement } from '@/helpers/Interfaces';

/**
 * RolesService
 */
export default class LocalizationZonesService
{
    /**
     * @param filter FilterModel
     * @param pager Pager
     *
     * @returns Promise<Pagination<Resource<ListItemModel>>>
     */
    public static async getList(filter: FilterModel, pager: Pager): Promise<Pagination<Resource<ListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<ListItemModel>>>('admin/configuration/zones', {
            params: merge({}, filter, pager.data(), {
                zoneTypePublicIds: [...filter.zoneTypePublicIds],
                buildingsPublicIds: [...filter.buildingsPublicIds],
                warehousesPublicIds: [...filter.warehousesPublicIds]
            })
        })).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async fetch(id: string): Promise<Resource<FormModel>>
    {
        return (await axios.get<Resource<FormModel>>(`admin/configuration/zones/${id}`)).data;
    }

    /**
     * @param model FormModel
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async create(model: FormModel): Promise<Resource<FormModel>>
    {
        return (await axios.post<Resource<FormModel>>(`admin/configuration/zones`, model)).data;
    }

    /**
     * @param id number
     * @param model FormModel
     *
     * @returns Promise<Statement>
     */
    public static async update(id: string, model: FormModel): Promise<Statement>
    {
        return (await axios.put<Statement>(`admin/configuration/zones/${id}`, model)).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Statement>
     */
    public static async remove(id: string): Promise<Statement>
    {
        return (await axios.delete(`admin/configuration/zones/${id}`)).data as Statement;
    }

    public static async getAutocompleteList(search: string, limit: number = 10, withoutUsersAndDefaultZones: boolean = true): Promise<ZoneAutocompleteModel[]>
    {
        const { data } = await axios.get<ZoneAutocompleteModel[]>('admin/configuration/zones/autocomplete', {
            params: {
                search: search,
                limit: limit,
                withoutUsersAndDefaultZones: withoutUsersAndDefaultZones
            }
        });

        return data;
    }

    public static async fetchAutocompleteModel(id: string): Promise<ZoneAutocompleteModel>
    {
        const { data } = await axios.get<ZoneAutocompleteModel>(`admin/configuration/zones/autocomplete/${id}`);

        return data;
    }
}

export interface ListItemModel
{
    publicId: string;
    name: string;
    zoneTypeDisplayName: string;
    buildingName: string;
    isActive: boolean;
}

export interface FilterModel
{
    name: string;
    zoneTypePublicIds: string[];
    buildingsPublicIds: string[];
    warehousesPublicIds?: string[];
    isActive: boolean | null;
    containingLocalizationCode: string;
}

export interface FormModel
{
    publicId: string;
    name: string;
    zoneTypePublicId: string;
    buildingPublicId: string;
    warehousePublicId?: string;
    isActive: boolean;
}

export interface ZoneAutocompleteModel {
    publicId: string;
    name: string;
}

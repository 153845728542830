import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { ValidatableBlueprint } from '@/components/builder/base/blueprints/ValidatableBlueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { FormBuilderContract } from '@/components/builder/form';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { CustomErrorBlueprint } from '@/components/builder/form/blueprints/CustomErrorBlueprint';
import { ReadonlyBlueprint } from '@/components/builder/form/blueprints/ReadonlyBlueprint';
import { RequiredBlueprint } from '@/components/builder/form/blueprints/RequiredBlueprint';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { Entry, entry, instanceOfEntry } from '@/components/builder/form/entries/Entry';
import { ValidEntry } from '@/components/builder/form/entries/ValidEntry';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { InternallyChoice } from '@/components/builder/form/enums/InternallyChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';
import { HasFilter } from '@/components/builder/form/traits/HasFilter';
import { HasHelp } from '@/components/builder/form/traits/HasHelp';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { ProcessCallback } from '@/components/builder/form/types/ProcessCallback';
import { $i18n } from '@/plugins/localization';

export const Definition: BlueprintDefinition = {
    type: 'likert',
    name: '[[[Ankieta]]]',
    icon: 'fa-star-half-alt',
    group: 'special',
    position: 7
};

export enum LikertTypes {
    Satisfaction = 'Satisfaction', // Very Unsatisfied, Unsatisfied, Neutral, Satisfied, Very Satisfied
    Agreement = 'Agreement', // Strongly Disagree, Disagree, Neutral, Agree, Strongly Agree
    Importance = 'Importance', // Not at all Important, Somewhat Important, Neutral, Important, Very Important
    Comparison = 'Comparison', // Much Worse, Somewhat Worse, About the Same, Somewhat Better, Much Better
    Statement = 'Statement', // Definitely Not, Probably Not, Not Sure, Probably Yes, Definitely Yes
    Scale = 'Scale', // 1, 2, 3, 4, 5
    Custom = 'Custom'
}

export class LikertEntry extends ValidEntry<Record<number, number>>
{
    public type: string = Definition.type;
    public data: Record<number, number> = null;

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.data = data;
        }
    }

    public async collect(blueprint: LikertContract, form: FormBuilderContract, preprocess: ProcessCallback): Promise<Entry>
    {
        const result = await preprocess(blueprint, this, form.blueprintId, form.entryId);

        return entry({
            type: this.type,
            data: this.data ?? this.defaultValues(blueprint),
            ...(result ?? {})
        });
    }

    public validate(blueprint: LikertContract, form: FormBuilderContract): boolean
    {
        this.errors = {};

        const values = this.data ?? this.defaultValues(blueprint);

        if (!form.expressions.readonly(blueprint, true) && form.expressions.visible(blueprint, true))
        {
            if (form.expressions.required(blueprint) && (values == null || Object.keys(values).length != blueprint.questions.length))
            {
                this.errors.value = [`[[[Pole "%0" jest wymagane.|||${form.localization.translate(blueprint.label)}]]]`];
            }
            else if (form.expressions.customError(blueprint))
            {
                this.errors.custom = [form.expressions.customErrorMessage(blueprint, form)];
            }
        }

        return this.valid();
    }

    private defaultValues(blueprint: LikertContract): Record<number, number>
    {
        const result = {} as Record<number, number>;

        if (blueprint.defaultValue !== null && `${blueprint.defaultValue}` !== "")
        {
            blueprint.questions.forEach(q => { result[Number(q)] = Number(blueprint.defaultValue ?? 0); });
        }

        return result;
    }
}

export const instanceOfLikertEntry = (object: any): object is LikertEntry =>
{
    return instanceOfEntry(object) && 'type' in object && object.type === Definition.type;
};

export interface LikertContract extends Blueprint, VisibleBlueprint, ReadonlyBlueprint, RequiredBlueprint, CustomErrorBlueprint, HasLabel, HasHelp, HasWidth, HasFilter
{
    fieldType: LikertTypes;
    questions: Record<string, string>[];
    choices: Record<string, string>[];
    defaultValue: number;
    allowNoChoice: boolean;
    noChoiceLabel: Record<string, string>;
    defaultChoices(type: LikertTypes): void;
}

export class LikertType implements LikertContract, ValidatableBlueprint, EntryFactory<LikertEntry>
{
    public id: string;
    public type: string;
    public name: string;
    public label: Record<string, string>;
    public showLabel: boolean;
    public help: Record<string, string>;
    public minWidth: number;
    public width: number;
    public fieldType: LikertTypes;
    public questions: Record<string, string>[];
    public choices: Record<string, string>[];
    public defaultValue: number;
    public allowNoChoice: boolean;
    public noChoiceLabel: Record<string, string>;
    public layout: number;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public required: AlwaysChoice | NeverChoice | WhenChoice;
    public requiredWhen: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: Record<string, string>;
    public errors: ValidationErrors;
    public showFilter: boolean;

    public constructor(id: string, name: string)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.label = { 'pl-PL': 'Ankieta' };
        this.showLabel = true;
        this.questions = [
            { 'pl-PL': 'Pytanie 1' },
            { 'pl-PL': 'Pytanie 2' },
            { 'pl-PL': 'Pytanie 3' }
        ];
        this.choices = [];
        this.layout = 0;
        this.defaultValue = null;
        this.allowNoChoice = false;
        this.noChoiceLabel = { 'pl-PL': 'Brak odpowiedzi' };
        this.help = {};
        this.fieldType = LikertTypes.Satisfaction;
        this.minWidth = 6;
        this.width = 6;
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.customErrorMessage = {};
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.required = NeverChoice.Never;
        this.requiredWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
        this.showFilter = false;

        this.defaultChoices(this.fieldType);
    }

    public defaultChoices(type: LikertTypes): void
    {
        switch (type)
        {
            case LikertTypes.Satisfaction:
                this.choices = [{ 'pl-PL': 'Bardzo niezadowolony' }, { 'pl-PL': 'Niezadowolony' }, { 'pl-PL': 'Obojętny' }, { 'pl-PL': 'Zadowolony' }, { 'pl-PL': 'Bardzo zadowolony' }];
                break;
            case LikertTypes.Agreement:
                this.choices = [{ 'pl-PL': 'Zdecydowanie się nie zgadzam' }, { 'pl-PL': 'Raczej się nie zgadzam' }, { 'pl-PL': 'Nie mam zdania' }, { 'pl-PL': 'Raczej się zgadzam' }, { 'pl-PL': 'Zdecydowanie się zgadzam' }];
                break;
            case LikertTypes.Importance:
                this.choices = [{ 'pl-PL': 'Zdecydowanie nieważne' }, { 'pl-PL': 'Nieważne' }, { 'pl-PL': 'Neutralne' }, { 'pl-PL': 'Ważne' }, { 'pl-PL': 'Zdecydowanie ważne' }];
                break;
            case LikertTypes.Comparison:
                this.choices = [{ 'pl-PL': 'Bardzo źle' }, { 'pl-PL': 'Źle' }, { 'pl-PL': 'Średnio' }, { 'pl-PL': 'Dobrze' }, { 'pl-PL': 'Bardzo dobrze' }];
                break;
            case LikertTypes.Statement:
                this.choices = [{ 'pl-PL': 'Zdecydowanie nie' }, { 'pl-PL': 'Nie' }, { 'pl-PL': 'Nie wiem' }, { 'pl-PL': 'Tak' }, { 'pl-PL': 'Zdecydowanie tak' }];
                break;
            case LikertTypes.Scale:
                this.choices = [{ 'pl-PL': '1' }, { 'pl-PL': '2' }, { 'pl-PL': '3' }, { 'pl-PL': '4' }, { 'pl-PL': '5' }];
                break;
            case LikertTypes.Custom:
                this.choices = [{ 'pl-PL': 'Odpowiedź 1' }, { 'pl-PL': 'Odpowiedź 2' }, { 'pl-PL': 'Odpowiedź 3' }, { 'pl-PL': 'Odpowiedź 4' }, { 'pl-PL': 'Odpowiedź 5' }];
                break;
        }
    }

    public setDefaultWidth(width: number): void
    {
        this.width = 6;
    }

    public createEntry(data: any): LikertEntry
    {
        return new LikertEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        const defaultLocale = $i18n.defaultLocale();

        if (this.questions.filter(q => q[defaultLocale] == null || q[defaultLocale].length == 0).length > 0)
            this.errors.questions = ['[[[Treści pytań są wymagane]]]'];

        if (this.choices.filter(q => q[defaultLocale] == null || q[defaultLocale].length == 0).length > 0)
            this.errors.choices = ['[[[Treści odpowiedzi są wymagane]]]'];

        return {
            [this.name]: this.errors
        };
    }
}

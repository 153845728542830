<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { useLocalization } from '@/plugins/localization';
import { useAlerts } from '@/plugins/alerts';
import { useMixins } from '@/plugins/mixins';
import AiService from '@/modules/core/common/services/AiService';
import AiImage from '@/assets/img/ai/ai-image.png';

const props = withDefaults(defineProps<{
    label: string,
    disabled?: boolean
}>(), {
    disabled: false
});
const emit = defineEmits<{
    (e: 'click'): void
}>();

const { $t } = useLocalization();
const { $alert } = useAlerts();
const { $handleException } = useMixins();

const enabled = ref<boolean>(false);

onMounted(async (): Promise<void> =>
{
    try
    {
        enabled.value = await AiService.isEnabled();
    }
    catch (ex)
    {
        $handleException(ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });
    }
});

function onClick()
{
    emit('click');
}
</script>

<template>
    <action-button
        toolbar
        variant="fancy"
        id="ai-generator-button"
        icon="far fa-microchip-ai"
        :text="props.label"
        :disabled="props.disabled"
        @click.prevent="onClick"
    />

    <ideo-popover
        class="popover popover-ai-hint"
        placement="bottom"
        target="ai-generator-button"
        triggers="hover"
        :shadow="false"
        :rounded="true"
        v-if="!enabled"
    >
        <div>
            <img :src="AiImage" alt="..." />
            <div class="p-3">
                <div class="fs-5 mb-1 fw-bolder">
                    {{ $t('[[[Wybróbuj możliwości AI]]]') }}
                </div>
                <div class="fs-6 mb-3">
                    {{ $t('[[[Przejdź do konfiguracji, aby włączyć narzędzia]]]') }}
                    <b>{{ $t('[[[Asystenta AI]]]') }}</b>.
                </div>
                <action-button
                    variant="fancy"
                    icon="far fa-microchip-ai"
                    :text="$t('[[[Konfiguracja asystenta ai]]]')"
                    :to="{name:'core-ai-settings'}"
                />
            </div>
        </div>
    </ideo-popover>
</template>

<style lang="scss">
.popover-ai-hint {
    --popover-padding: 2px;

    background: linear-gradient(135deg, rgba(23,162,184,1) 0%, rgba(68,135,186,1) 49%, rgba(161,77,188,1) 100%);
    max-width: 400px;
    padding: var(--popover-padding);
    border: none;

    img {
        display: block;
        width: 100%;
        max-height: 150px;
        object-fit: cover;
    }

    .popover {
        &-body {
            background: rgb(var(--bs-tertiary-bg-rgb));
            padding: 0;
            border-radius: calc(var(--bs-popover-border-radius) - var(--popover-padding));
        }
    }
}
</style>

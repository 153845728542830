<script lang="ts" setup>
import { ref, computed, nextTick, onMounted, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
import { useEvents } from '@/plugins/events';
import { useAlerts } from '@/plugins/alerts';
import { useLocalization } from '@/plugins/localization';
import { Form } from '@/helpers/Form';
import DashboardsService, {
    ListItemModel,
    FilterModel,
    WidgetModel,
    WidgetConfigModel,
    WidgetTypeEnum,
} from '@/modules/core/dashboards/services/DashboardsService';
import Pager from '@/helpers/Pager';
import { GridStack, GridStackOptions, GridItemHTMLElement, GridStackNode } from 'gridstack';
import 'gridstack/dist/gridstack.css';
import Loader from '@/components/common/Loader.vue';
import BaseWidget from '@/modules/core/dashboards/components/BaseWidget.vue';
import AddWidgetModal from '@/modules/core/dashboards/components/modals/AddWidgetModal.vue';
import WidgetSettingsModal from '@/modules/core/dashboards/components/modals/WidgetSettingsModal.vue';
import { useAuthStore } from '@/store/auth';


const { $alert } = useAlerts();
const { $t } = useLocalization();
const route = useRoute();
const { $events } = useEvents();
const authStore = useAuthStore();

let grid: GridStack | null = null;
const CELL_HEIGHT = 135;
const COLUMN_NUMBER = 12;

const isMounted = ref(false);
const isLoaded = ref(false);
const widgets = ref<Array<WidgetConfigModel>>([]);

const dashboardId = ref(0);
const currentDashboardId = ref(0);
const dashboardOptions = ref<ListItemModel[]>([]);

const addWidgetModal = ref<InstanceType<typeof AddWidgetModal> | null>(null);
const widgetSettingsModal = ref<InstanceType<typeof WidgetSettingsModal> | null>(null);

const isDesignMode = computed(() => route.name === 'core-dashboards-arrangement');
const paramDashboardId = computed(() => route.params.id as string);
const isSave = computed(() => currentDashboardId.value !== dashboardId.value);

const openAddWidgetModal = (): void =>
{
    addWidgetModal.value?.showModal();
};

const openWidgetSettingsModal = (widget: WidgetModel, editMode: boolean): void =>
{
    widgetSettingsModal.value?.showModal(widget, editMode);
};

const selectWidget = (widget: WidgetModel, editMode: boolean = false): void =>
{
    openWidgetSettingsModal(widget, editMode);
};

const getDashboardsOptions = async (): Promise<void> =>
{
    try
    {
        const filter = Form.create<FilterModel>({
            name: '',
            dateCreatedFromUtc: null,
            dateCreatedToUtc: null,
            onlyMine: false,
            isDefault: false,
            isActive: true,
            accessibilityType: null,
            createdBy: 0,
        });
        const pager = new Pager(1, 999, 'DateCreatedUtc', 'ASC');

        const response = await DashboardsService.getList(filter.data(), pager);

        dashboardOptions.value = response.items.map((item) => item.result);
    }
    catch (ex: any)
    {
        if (ex.code == 400) $alert.error($t('[[[Nie udało się pobrać opcji aranżacji]]]'));
    }
};

const getDashboard = async (): Promise<void> =>
{
    if (!authStore.identity || !authStore.identity.publicId)
        return;

    try
    {
        isLoaded.value = false;

        if (isDesignMode.value)
        {
            const response = (await DashboardsService.getDashboard(paramDashboardId.value)).widgets;

            dashboardId.value = +paramDashboardId.value;
            currentDashboardId.value = +paramDashboardId.value;

            widgets.value = response ?? [];
        }
        else
        {
            try
            {
                const response = (await DashboardsService.getUserDashboard()).result;

                dashboardId.value = response.id;
                currentDashboardId.value = response.id;

                widgets.value = response.config.widgets ?? [];
            }
            catch
            {
                // brak dodanego dashboardu
            }
        }
    }
    catch (ex: any)
    {
        if (ex.code == 400) $alert.error($t('[[[Nie udało się pobrać widgetu/ów]]]'));
    }
    finally
    {
        isLoaded.value = true;
    }
};

const attachDashboard = async () =>
{
    try
    {
        await DashboardsService.attachDashboard(dashboardId.value);
        $alert.success($t('[[[Udało się zmienić aranżację]]]'));

        init();
    }
    catch (ex: any)
    {
        if (ex.code == 400) $alert.error(ex.message);
    }
};

const addWidget = async (_widget: WidgetConfigModel, x?: number, y?: number) =>
{
    if (widgets.value.some((widget) => widget.id === _widget.id))
    {
        $alert.warning($t('[[[Nie możesz dodać tego samego widgetu dwa razy]]]'));

        return;
    }

    const widget: WidgetConfigModel = {
        id: _widget.id,
        x: x ?? 0,
        y: y ?? 0,
        width: _widget.width,
        height: _widget.height,
        sourceConfig: _widget.sourceConfig,
    };

    widgets.value.push(widget);

    await nextTick();

    grid.makeWidget(`#x${widget.id}`);
};

const updateWidget = (_widget: WidgetConfigModel, id: string): void =>
{
    const widget = widgets.value.find((w) => w.id === id);

    if (!widget) return;

    widget.width = +_widget.width;
    widget.height = +_widget.height;
    widget.sourceConfig = _widget.sourceConfig;

    grid.update(`#x${widget.id}`, { h: +_widget.height, w: +_widget.width });

    if (_widget.id == WidgetTypeEnum.Shortcuts)
    {
        resizeToContent(widget.id);
    }
};

const updateWidgetPosition = (_: Event, items: GridItemHTMLElement | GridStackNode | GridStackNode[]): any =>
{
    if (Array.isArray(items))
    {
        items.forEach((item) =>
        {
            const widget = widgets.value.find((w) => w.id === `${item.id}`.slice(1));

            if (!widget) return;

            widget.x = item.x;
            widget.y = item.y;
        });
    }
};

const deleteWidget = async (id: string): Promise<void> =>
{
    grid.removeWidget(`#x${id}`, false);

    const index = widgets.value.findIndex((item) => item.id === id);

    widgets.value.splice(index, 1);
};

const saveSettings = async (): Promise<void> =>
{
    try
    {
        const _grid = grid.save();

        if (Array.isArray(_grid))
        {
            const model = {
                widgets: _grid.map((item) =>
                {
                    const id = `${item.id}`.slice(1);
                    let sourceConfig = widgets.value.find((w) => w.id === id)?.sourceConfig;

                    sourceConfig = typeof sourceConfig === 'object' ? JSON.stringify(sourceConfig) : sourceConfig;

                    return {
                        id,
                        x: item.x,
                        y: item.y,
                        width: item.w,
                        height: item.h,
                        sourceConfig,
                    };
                }),
            };

            await DashboardsService.saveDashboard(dashboardId.value, model);
            $alert.success($t('[[[Udało się zapisać ustawienia widgetów]]]'));
        }
    }
    catch (ex: any)
    {
        if (ex.code == 400) $alert.error(ex.message);
    }
};

const resizeToContent = (widgetId: string): void =>
{
    const widgetID = `x${widgetId}`;

    const widget = grid?.engine?.nodes?.find((grid) => grid.id == widgetID);

    grid.update(widget.el, { sizeToContent: true, resizeToContentParent: '.grid-stack-item-content' });

    widget.el.querySelector('.grid-stack-item-content').classList.add('overflow-y-hidden', 'resizeable-grid-item');
    widget.el.querySelector('.grid-stack-item-content .content').classList.remove('scroll');

    nextTick(() =>
    {
        grid.resizeToContent(widget.el);
    });
};

const initGrid = async (): Promise<void> =>
{
    const gridConfig: GridStackOptions = {
        acceptWidgets: true,
        staticGrid: !isDesignMode.value, // Disable drag
        cellHeight: CELL_HEIGHT, // Height of one column
        float: false,
        columnOpts: {
            breakpoints: [{ w: 768, c: 1 }],
            columnMax: COLUMN_NUMBER
        },
    };

    grid = GridStack.init(gridConfig);

    await nextTick();

    grid.on('change', (event: Event, items: GridItemHTMLElement | GridStackNode | GridStackNode[]) =>
        updateWidgetPosition(event, items)
    );
};

const init = async (fetch: boolean = false) =>
{
    isMounted.value = false;

    if (!isDesignMode.value && fetch) await getDashboardsOptions();

    await getDashboard();
    isMounted.value = true;
    initGrid();
};

onMounted(() =>
{
    init(true);
    $events.$on('gridstack::resize-widget', resizeToContent);
});

onUnmounted(() =>
{
    grid?.destroy();
    $events.$off('gridstack::resize-widget', resizeToContent);
});
</script>

<template>
    <div class="dashboard">
        <div class="py-2 px-3 mt-n3 mx-n3 dashboard-header position-relative z-3">
            <div class="d-flex align-items-center flex-wrap">
                <action-bar :toggle-footer="true" v-if="isDesignMode">
                    <action-button back variant="outline-dark" icon="far fa-arrow-left" :text="$t('[[[Powrót]]]')" :to="{name: 'core-dashboards'}" />
                    <action-button variant="primary" icon="far fa-check" :text="$t('[[[Zapisz aranżację]]]')" @click="saveSettings" />
                    <action-button variant="success" icon="far fa-plus" :text="$t('[[[Dodaj widget]]]')" @click.stop="openAddWidgetModal" />
                </action-bar>
                <div v-if="!isDesignMode && dashboardOptions.length" class="d-flex gap-2">
                    <IdeoFormSelect
                        v-model="dashboardId"
                        :options="dashboardOptions"
                        value-field="id"
                        text-field="name"
                    />
                    <IdeoButton v-if="isSave" variant="success" @click="attachDashboard">{{ $t('[[[Zapisz]]]') }}</IdeoButton>
                </div>
            </div>
        </div>
        <Transition name="fade">
            <div v-if="isLoaded" class="dashboard-container position-relative d-flex flex-column flex-fill">
                <div class="grid-stack position-relative z-2 h-100">
                    <BaseWidget
                        v-for="widget in widgets"
                        :key="widget.id"
                        :config="widget"
                        :is-design-mode="isDesignMode"
                        @edit-widget="selectWidget($event, true)"
                        @delete-widget="deleteWidget($event)"
                    />
                </div>
                <div v-if="isDesignMode && !phone && widgets.length" class="grid-placeholder">
                    <div v-for="item in COLUMN_NUMBER" :key="item" class="column"></div>
                </div>
                <div
                    v-if="!widgets.length"
                    class="dashboard-empty z-2 h-100 flex-1 d-flex align text-center flex-column justify-content-center align-items-center"
                >
                    <i class="dashboard-icon fa-duotone fa-grid-horizontal"></i>
                    <h2 class="dashboard-h2 dashboard-h2-margin-bottom">
                        {{ $t('[[[Ten dashboard nie zawiera żadnych widgetów]]]') }}
                    </h2>
                    <p v-if="isDesignMode" class="dashboard-p">
                        {{ $t('[[[Dodaj jeden lub więcej widgetów, aby uzyskać wgląd w postępy swojego zespołu.]]]') }}
                    </p>
                    <action-button variant="success" icon="far fa-plus" :text="$t('[[[Dodaj widget]]]')" @click.stop="openAddWidgetModal" v-if="isDesignMode" />
                </div>
            </div>
            <div v-else class="position-absolute top-50 start-50 translate-middle">
                <Loader />
            </div>
        </Transition>
        <template v-if="isDesignMode">
            <AddWidgetModal ref="addWidgetModal" @select-widget="selectWidget" />
            <WidgetSettingsModal
                ref="widgetSettingsModal"
                :dashboard-id="dashboardId"
                @add="addWidget"
                @update="updateWidget"
            />
        </template>
    </div>
</template>

<style lang="scss" scoped>
.grid-placeholder {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    padding: 10px;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(v-bind(COLUMN_NUMBER), 1fr);

    .column {
        background: var(--bs-gray-200);
        opacity: 0.5;
        border-radius: 5px;
    }
}

.dashboard {
    display: flex;
    flex-direction: column;
    height: 100%;

    &-header {
        min-height: 49.5px;
    }

    &-h2 {
        font-size: 1.5rem;
        font-weight: 600;
        margin: 0;

        &-margin-bottom {
            margin-bottom: 10px;
        }
    }

    &-p {
        margin-bottom: 10px;
    }

    &-container {
        margin: -10px;
    }

    &-icon {
        font-size: 9rem;
        margin-bottom: 3px;
        color: var(--bs-gray-500);
    }

    &-empty {
        padding: 3.5rem 1rem;
        position: absolute;
        left: 50%;
        pointer-events: none;
        transform: translateX(-50%);
        width: 100%;
    }

    &-button {
        pointer-events: all;
    }
}
</style>

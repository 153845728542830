import { $i18n } from "@/plugins/localization";

export class LocalizationManager
{
    public translate(value: Record<string, string>): string
    {
        return $i18n.currentTranslation(value);
    }

    public codes(): string[]
    {
        return $i18n.codes();
    }
}

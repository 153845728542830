import { SchemaManager } from './SchemaManager';

// Blueprints
import { Blueprint } from '../blueprints/Blueprint';
import { AggregateBlueprint, instanceOfAggregateBlueprint } from '../blueprints/AggregateBlueprint';

// Traits
import { HasWidth, instanceOfHasWidth } from '../traits/HasWidth';

// Definitions
import { Definition as TableDefinition } from '@/components/forms/blueprints/table';

// --------------------------------------------------

export class DimensionsManager
{
    private schema: SchemaManager;

    public constructor(schemaManager: SchemaManager)
    {
        this.schema = schemaManager;
    }

    public maxRowSpace(): number
    {
        return 6;
    }

    /**
     * Wyliczenie rozmiaru wiersza (jest uzależnione od rozmiaru rodzica)
     * @param row AggregateBlueprint
     * @returns number
     */
    public rowSpace(row: AggregateBlueprint): number
    {
        const parent = this.schema.parent(row);

        if (parent != null && instanceOfHasWidth(parent))
            return parent.width;

        return this.maxRowSpace();
    }

    /**
     * Pokazuje ile wolnego miejsca pozostało w wierszu
     * @param parent AggregateBlueprint
     * @returns number
     */
    public space(parent: AggregateBlueprint): number
    {
        return this.rowSpace(parent) - parent.components.reduce((sum, component) => sum + (component as any).width, 0);
    }

    /**
     * Pokazuje ile wolnego miejsca można wygospodarować w wierszu po zmniejszeniu poszczególnych kontrolek
     * @param parent AggregateBlueprint
     * @param except Blueprint
     * @returns number
     */
    public available(parent: AggregateBlueprint, except: Blueprint = null): number
    {
        if (parent.type === TableDefinition.type) return this.maxRowSpace();

        return this.rowSpace(parent) - parent.components.filter(p => p != except).reduce((sum, component) => sum + (component as any).minWidth, 0) - (except != null ? (except as any).width : 0);
    }

    public autoarrange(parent: AggregateBlueprint, always: boolean = true): void
    {
        if (always == true || (always == false && this.space(parent) < 0))
        {
            for (let i = 0; i < parent.components.length; i++)
            {
                const item = parent.components[i];

                if (instanceOfHasWidth(item) && parent.type === TableDefinition.type)
                {
                    item.width = 6;
                }
                else if (instanceOfHasWidth(item))
                {
                    item.width = item.minWidth;
                }
            }

            while (this.space(parent) > 0)
            {
                for (let i = 0; i < parent.components.length; i++)
                {
                    const item = parent.components[i];

                    if (this.space(parent) > 0 && instanceOfHasWidth(item))
                    {
                        this.enlarge(item);
                    }
                }
            }
        }
    }

    public rearrange(parent: AggregateBlueprint, except: Blueprint = null): void
    {
        for (let i = parent.components.length; i > 0; i--)
        {
            if (parent.components[i - 1] == except)
            {
                continue;
            }

            if (this.shrink(parent.components[i - 1] as any))
            {
                break;
            }
        }
    }

    public smaller(component: HasWidth): boolean
    {
        const minWidth = [component.minWidth];

        if (instanceOfAggregateBlueprint(component))
        {
            minWidth.push(...component.components.map(p => component.width - this.available(component)));
        }

        return component.width > Math.max(...minWidth);
    }

    public larger(component: HasWidth): boolean
    {
        const parent = this.schema.parent(component as any) as AggregateBlueprint;

        return this.space(parent) > 0 || this.available(parent, component as any) > 0;
    }

    public shrink(component: HasWidth): boolean
    {
        if (component.width > component.minWidth)
        {
            component.width--;

            while (component.exceptWidth && component.exceptWidth.includes(component.width))
            {
                component.width--;
            }

            return true;
        }

        return false;
    }

    public enlarge(component: HasWidth): boolean
    {
        const parent = this.schema.parent(component as any) as AggregateBlueprint;

        if (this.space(parent) == 0 && this.available(parent) > 0)
        {
            this.rearrange(parent, component as any);
        }

        let space = this.space(parent);

        if (space > 0)
        {
            let width = component.width;

            space--;
            width++;

            while (component.exceptWidth && component.exceptWidth.includes(width))
            {
                space--;
                width++;
            }

            if (space >= 0)
            {
                component.width = width;

                return true;
            }
        }

        return false;
    }
}

<script lang="ts" setup>
import { computed, useSlots } from 'vue';
import IdeoInputGroupText from './IdeoInputGroupText.vue';

defineOptions({
    name: 'ideo-input-group',
    components: {
        'ideo-input-group-text': IdeoInputGroupText
    }
});

const slots = useSlots();

const props = withDefaults(defineProps<{
    prepend?: string;
    append?: string;
}>(), {
    prepend: null,
    append: null,
});

const hasPrependSlot = computed(() => 'prepend' in slots);
const hasPrependTextSlot = computed(() => 'prepend-text' in slots);
const hasPrependText = computed(() => hasPrependTextSlot.value || (props.prepend != null && props.prepend != ''));
const hasPrepend = computed(() => hasPrependSlot.value || hasPrependText.value);
const hasAppendSlot = computed(() => 'append' in slots);
const hasAppendTextSlot = computed(() => 'append-text' in slots);
const hasAppendText = computed(() => hasAppendTextSlot.value || (props.append != null && props.append != ''));
const hasAppend = computed(() => hasAppendSlot.value || hasAppendText.value);
</script>

<template>
    <div class="input-group">
        <slot name="prepend" v-if="hasPrepend && hasPrependSlot"></slot>
        <ideo-input-group-text v-else-if="hasPrepend && hasPrependText">
            <slot name="prepend-text">{{ prepend }}</slot>
        </ideo-input-group-text>

        <slot name="default"></slot>

        <slot name="append" v-if="hasAppend && hasAppendSlot"></slot>
        <ideo-input-group-text v-else-if="hasAppend && hasAppendText">
            <slot name="append-text">{{ append }}</slot>
        </ideo-input-group-text>
    </div>
</template>

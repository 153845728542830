<script setup lang="ts">
import { ref, computed, onMounted, nextTick } from 'vue';
import AuthService from '@/modules/core/auth/services/AuthService';
import { useThemeStore } from '@/store/theme';
import { useAuth } from '@/plugins/auth';
import { useLocalization } from '@/plugins/localization';

defineOptions({
    name: 'Locales'
});

const themeStore = useThemeStore();
const { $auth } = useAuth();
const { $i18n, $t } = useLocalization();
const isMounted = ref(false);

const locale = computed({
    get: () => themeStore.locale,
    set: (value: string) => themeStore.locale = value
});

const locales = computed(() =>
    Object.entries($i18n.languages()).map(([key, value]) => ({ value: key, text: $t(value) }))
);

const preferredLanguage = computed(() => $auth.user()?.preferredLanguage);

onMounted(async () =>
{
    if (!locale.value)
    {
        locale.value = preferredLanguage.value != null ? preferredLanguage.value : $i18n.locale();
    }

    if (locale.value && preferredLanguage.value && locale.value !== preferredLanguage.value)
    {
        await onChange();
    }

    await nextTick(() =>
    {
        $i18n.setLocale(locale.value);
        isMounted.value = true;
    });
});

async function onChange(): Promise<void>
{
    if ($auth.check())
    {
        await AuthService.updatePreferredLanguage(locale.value);
    }

    await nextTick(() =>
    {
        $i18n.setLocale(locale.value);
    });
}
</script>

<template>
    <div class="mx-2">
        <ideo-form-select
            v-if="isMounted"
            name="locales"
            v-model="locale"
            :options="locales"
            @change="onChange()"
        >
        </ideo-form-select>
    </div>
</template>

<style lang="scss" scoped>
select.form-control {
    width: 95%;
    margin-left: 5px;
}
</style>

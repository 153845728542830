<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { onClickOutside } from '@vueuse/core';
import Draggable from 'vuedraggable';
import { Header } from '../helpers';
import { useLocalization } from '@/plugins/localization';
import { setColumnsState } from '@/plugins/viewstate';

export interface Props
{
    headers: Header[];
}

const props = withDefaults(defineProps<Props>(), {});
const visible = defineModel<Record<string, boolean>>('visible');
const positions = defineModel<Record<string, number>>('positions');

const { $t } = useLocalization();
const dropdown = ref<HTMLElement>(null);
const show = ref(false);
const onToggle = (): void =>
{
    show.value = !show.value;
};

onClickOutside(dropdown, () =>
{
    show.value = false;
},
{ignore: ['.modal']});

const search = ref('');
const resetSearch = (): void =>
{
    search.value = '';
};
const canDrag = (): boolean =>
{
    return search.value.length == 0;
};

const columns = computed({
    get: () => props.headers
        .where(p =>
            (p.title || $t('[[[<Brak nazwy>]]]')).toLowerCase().includes(search.value.toLowerCase()) &&
            !['checkbox', 'hierarchy', 'handle', 'buttons'].includes(p.type) &&
            !p.disabled
        )
        .orderBy(p => intermediate.value.positions[p.key] ?? p.position)
        .toArray(),
    set: (value) => value
        .forEach((p, index) => intermediate.value.positions[p.key] = index)
});
const intermediate = ref({
    visible: [] as string[],
    positions: {} as Record<string, number>
});

const update = (): void =>
{
    visible.value = {
        ...props.headers.where(p => intermediate.value.visible.includes(p.key)).select(p => p.key).record(p => p, _ => true),
        ...props.headers.where(p => !intermediate.value.visible.includes(p.key)).select(p => p.key).record(p => p, _ => false)
    };
    positions.value = intermediate.value.positions;

    setColumnsState({
        visible: visible.value,
        positions: positions.value
    });
};
const reset = (): void =>
{
    visible.value = {};
    positions.value = {};

    setColumnsState({
        visible: visible.value,
        positions: positions.value
    });
};

watch(() => props.headers, () =>
{
    intermediate.value.visible = props.headers.where(p => p.visible).select(p => p.key).toArray();
    intermediate.value.positions = props.headers.record(p => p.key, p => p.position);
},
{immediate: true});
</script>

<template>
    <portal to="list-view-settings">
        <div class="d-flex dropdown" ref="dropdown">
            <button type="button" class="btn btn-secondary px-2" @click.stop="onToggle()">
                <i class="far fa-fw fa-table"></i>
            </button>
            <div class="dropdown-menu shadow p-0" :class="{'show': show}">
                <div class="p-2">
                    <ideo-input-group class="mb-2">
                        <ideo-form-input v-model="search" type="text" name="search" :placeholder="$t('[[[Wyszukaj...]]]')"></ideo-form-input>
                        <template #append>
                            <ideo-button icon="fas fa-fw fa-times" @click="resetSearch()"></ideo-button>
                        </template>
                    </ideo-input-group>
                    <div class="columns scroll pt-2">
                        <draggable v-model="columns" item-key="key" tag="div" handle=".handle" :move="canDrag">
                            <template #item="{element}">
                                <div class="d-flex">
                                    <i class="fa-regular fa-grip-dots-vertical p-1 handle"></i>
                                    <ideo-form-checkbox :value="element.key" v-model="intermediate.visible">
                                        {{ element.title || $t('[[[<Brak nazwy>]]]') }}
                                    </ideo-form-checkbox>
                                </div>
                            </template>
                        </draggable>
                    </div>
                    <div class="d-flex justify-content-center py-3" v-if="columns.length == 0">
                        {{ $t('[[[Nie znaleziono kolumn.]]]') }}
                    </div>
                </div>
                <div class="text-end border-top p-2">
                    <ideo-button variant="light" icon="far fa-fw fa-circle-check me-2" @click="reset()">{{ $t('[[[Domyślne]]]') }}</ideo-button>
                    <ideo-button variant="primary" icon="far fa-fw fa-check me-2" @click="update()">{{ $t('[[[Potwierdź]]]') }}</ideo-button>
                </div>
            </div>
        </div>
    </portal>
</template>

<style lang="scss" scoped>
.dropdown > .btn {
    min-height: 34px;
}
.dropdown-menu {
    min-width: var(--ideo-dropdown-width);
    top: 100%;
    right: 4px;

    > * {
        margin: -1px;
    }
}
.columns {
    max-height: 295px;
}
.handle {
    cursor: pointer;
}
</style>

import { v4 as uuidv4 } from 'uuid';
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';

// Blueprints
import { Blueprint } from '../blueprints/Blueprint';
import { instanceOfValidatableBlueprint } from '../blueprints/ValidatableBlueprint';
import { AggregateBlueprint, instanceOfAggregateBlueprint } from '../blueprints/AggregateBlueprint';
import { RootAbstract, RootBlueprint, instanceOfRootBlueprint } from '../blueprints/RootBlueprint';

// Managers
import { EventManager, Events } from '../managers/EventManager';

// Services
import { BlueprintFactory } from '../services/BlueprintFactory';

import { $events } from '@/plugins/events';

// --------------------------------------------------

export class SchemaManager
{
    private factory: BlueprintFactory;
    private events: EventManager;
    private blueprint: RootBlueprint;
    private identity: () => number;

    public constructor(blueprintFactory: BlueprintFactory, eventManager: EventManager)
    {
        this.factory = blueprintFactory;
        this.events = eventManager;
        this.setBlueprint(null);
    }

    public get id(): number
    {
        return this.identity ? this.identity() : 0;
    }

    public setIdentity(callback: () => number): void
    {
        this.identity = callback;
    }

    public getBlueprint(): Blueprint
    {
        return this.blueprint;
    }

    public setBlueprint(blueprint: Blueprint|null): void
    {
        if (blueprint == null)
            blueprint = this.factory.createRoot(this.newId(), 'document');

        if (!instanceOfRootBlueprint(blueprint))
            throw new Error("Argument 'blueprint' must be of type RootBlueprint.");

        if (!(blueprint instanceof RootAbstract))
            blueprint = this.prepareBlueprint(blueprint);

        this.blueprint = blueprint as RootBlueprint;
    }

    private prepareBlueprint(contract: Blueprint): Blueprint
    {
        let blueprint: Blueprint = null;

        try
        {
            blueprint = this.factory.fromContract(contract);

            if (instanceOfAggregateBlueprint(blueprint))
            {
                for (let i = 0; i < blueprint.components.length; i++)
                {
                    blueprint.components[i] = this.prepareBlueprint(blueprint.components[i]);
                }

                blueprint.components = blueprint.components.filter(p => p !== null);
            }
        }
        catch (ex)
        {
            // eslint-disable-next-line no-console
            console.log(`Blueprint does not exist: ${contract.type}.`);
        }

        return blueprint;
    }

    public replaceBlueprint(blueprint: Blueprint, factory: (original: Blueprint) => Blueprint): Blueprint
    {
        const parent = this.parent(blueprint);

        if (parent !== null)
        {
            const index = parent.components.findIndex(p => p.id ==  blueprint.id);

            if (index !== -1)
            {
                parent.components.splice(index, 1, factory(blueprint));

                return parent.components[index];
            }
        }

        return null;
    }

    public isValid(): boolean
    {
        if (instanceOfValidatableBlueprint(this.blueprint))
        {
            const result = this.blueprint.validate();
            const entries = Object.entries(result).filter(([name, errors]) => Object.keys(errors).length > 0);

            if (entries.length > 0)
            {
                const [entry] = entries;
                const [name] = entry;
                const blueprint = this.find(name);

                this.events.emit(Events.FOCUS, blueprint);

                $events.$emit('errors-modal', result.document);

                return false;
            }
        }

        return true;
    }

    public errorMessage(blueprint: Blueprint, property: string): string
    {
        if (instanceOfValidatableBlueprint(blueprint) && property in blueprint.errors && blueprint.errors[property].length > 0)
        {
            return blueprint.errors[property][0];
        }

        return null;
    }

    public newId(): string
    {
        return uuidv4().toString();
    }

    public designer(type: string): string
    {
        return `${type}-blueprint`;
    }

    public presenter(type: string): string
    {
        return `${type}-presenter`;
    }

    public components(type: string|string[] = null, except: Blueprint = null): Blueprint[]
    {
        if (type != null && typeof type == 'string')
        {
            type = [type];
        }

        const extract = (components: Blueprint[]): Blueprint[] =>
        {
            return components.reduce((value: Blueprint[], item: Blueprint) =>
            {
                if (item != except && (type == null || type.includes(item.type)))
                {
                    value.push(item);
                }

                if (instanceOfAggregateBlueprint(item) && item.components.length > 0)
                {
                    value.push(...extract(item.components));
                }

                return value;
            },
            []);
        };

        return extract(this.blueprint.components);
    }

    public names(type: string = null, except: Blueprint = null): string[]
    {
        return this.components(type, except).map(p => p.name);
    }

    public name(type: string, names: string[] = null): string
    {
        let count = 0;
        let name: string = null;

        names = names || this.names(type);

        do
        {
            name = upperFirst(camelCase(`${type}${(count + 1).toString()}`));
            count++;
        }
        while (names.includes(name));

        return name;
    }

    public exists(name: string): boolean
    {
        return this.names().includes(name);
    }

    public unique(name: string, except: Blueprint): boolean
    {
        return !this.names(null, except).includes(name);
    }

    public find(name: string): Blueprint
    {
        const find = (blueprint: Blueprint, name: string): Blueprint =>
        {
            let result: Blueprint = null;

            if (blueprint.name == name)
            {
                result = blueprint;
            }
            else if (instanceOfAggregateBlueprint(blueprint))
            {
                for (let i = 0; i < blueprint.components.length; i++)
                {
                    result = result || find(blueprint.components[i], name);
                }
            }

            return result;
        };

        return find(this.blueprint, name);
    }

    public pick(id: string): Blueprint
    {
        const pick = (blueprint: Blueprint, id: string): Blueprint =>
        {
            let result: Blueprint = null;

            if (blueprint.id == id)
            {
                result = blueprint;
            }
            else if (instanceOfAggregateBlueprint(blueprint))
            {
                for (let i = 0; i < blueprint.components.length; i++)
                {
                    result = result || pick(blueprint.components[i], id);
                }
            }

            return result;
        };

        return pick(this.blueprint, id);
    }

    public parent(component: Blueprint, type: string = null): AggregateBlueprint
    {
        const find = (parent: AggregateBlueprint, component: Blueprint): AggregateBlueprint =>
        {
            let result: AggregateBlueprint = null;

            if (parent.components.includes(component))
            {
                if (type === null || type == parent.type)
                {
                    result = parent;
                }
            }
            else
            {
                for (let i = 0; i < parent.components.length; i++)
                {
                    const item = parent.components[i];

                    if (instanceOfAggregateBlueprint(item))
                    {
                        result = find(item, component) || result;
                    }
                }
            }

            return result;
        };

        return find(this.blueprint, component);
    }

    public descendants(component: AggregateBlueprint): Blueprint[]
    {
        const descendants = (components: Blueprint[]): Blueprint[] =>
        {
            const result = [] as Blueprint[];

            components.forEach(c =>
            {
                result.push(c);

                if (instanceOfAggregateBlueprint(c) && c.components.length > 0)
                {
                    result.push(...descendants(c.components));
                }
            });

            return result;
        };

        return descendants(component.components);
    }

    public next(component: Blueprint): Blueprint
    {
        const parent = this.parent(component);
        const index = parent.components.indexOf(component);

        return index < parent.components.length - 1 ? parent.components[index + 1] : null;
    }

    public prev(component: Blueprint): Blueprint
    {
        const parent = this.parent(component);
        const index = parent.components.indexOf(component);

        return index > 0 ? parent.components[index - 1] : null;
    }
}

<script lang="ts" setup>
import { computed } from 'vue';

defineOptions({
    name: 'ideo-button-group'
});

const props = withDefaults(defineProps<{
    size?: 'sm'|'md'|'lg',
    vertical?: boolean;
}>(), {
    size: 'md'
});

const customClass = computed(() => ({
    'btn-group': true,
    'btn-group-sm': props.size == 'sm',
    'btn-group-lg': props.size == 'lg',
    'btn-group-vertical': props.vertical
}));
</script>

<template>
    <div :class="customClass">
        <slot name="default"></slot>
    </div>
</template>

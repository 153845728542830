<script lang="ts" setup>
import { computed, provide, ref } from 'vue';
import DashboardsService, { GridItemAttributes, WidgetModel, WidgetConfigModel, WidgetTypeEnum } from '@/modules/core/dashboards/services/DashboardsService';
// import ActiveSubstitutions from '@/modules/core/dashboards/components/widgets/ActiveSubstitutions.vue';
// import AiUsage from '@/modules/core/dashboards/components/widgets/AiUsage.vue';
import UnfinishedTransfers from "@/modules/core/dashboards/components/widgets/UnfinishedTransfers.vue";
import UnfinishedIssues from "@/modules/core/dashboards/components/widgets/UnfinishedIssues.vue";
import UsersOnline from "@/modules/core/dashboards/components/widgets/UsersOnline.vue";
import PiecesIssuedToCouriers from "@/modules/core/dashboards/components/widgets/PiecesIssuedToCouriers.vue";
import ActiveInventories from "@/modules/core/dashboards/components/widgets/ActiveInventories.vue";
import Inventories from "@/modules/core/dashboards/components/widgets/Inventories.vue";
import ReceiptStatuses from "@/modules/core/dashboards/components/widgets/ReceiptStatuses.vue";
import ReceiptsProductsSum from "@/modules/core/dashboards/components/widgets/ReceiptsProductsSum.vue";
import TodaysIssues from "@/modules/core/dashboards/components/widgets/TodaysIssues.vue";
import MostIssuedProducts from "@/modules/core/dashboards/components/widgets/MostIssuedProducts.vue";
import TransfersByStatus from "@/modules/core/dashboards/components/widgets/TransfersByStatus.vue";
import PiecesIssuedByCouriers from "@/modules/core/dashboards/components/widgets/PiecesIssuedByCouriers.vue";
import IssuesByStatus from "@/modules/core/dashboards/components/widgets/IssuesByStatus.vue";
import IssuesProductsSum from "@/modules/core/dashboards/components/widgets/IssuesProductsSum.vue";
import Shortcuts from "@/modules/core/dashboards/components/widgets/shortcuts/Shortcuts.vue";

const emit = defineEmits<{
    (e: 'edit-widget', value: WidgetConfigModel): void;
    (e: 'delete-widget', id: string): void;
}>();

const props = defineProps<{
    config: WidgetConfigModel;
    isDesignMode: boolean;
}>();

const widget = ref<WidgetModel>(null);
const widgetContainer = ref<HTMLDivElement>(null);
const widgetHeight = computed<number>(() => widgetContainer.value?.offsetHeight);

provide('card-default-container-height', widgetHeight);

const gridStackAttributes = computed<GridItemAttributes>(() => ({
    id: `x${props.config.id}`,
    'gs-id': `x${props.config.id}`,
    'gs-x': props.config.x,
    'gs-y': props.config.y,
    'gs-w': props.config.width,
    'gs-h': props.config.height,
    'gs-no-resize': true,
}));

const component = computed(() =>
{
    switch (widget.value.id)
    {
        case WidgetTypeEnum.UnfinishedTransfers:
            return UnfinishedTransfers;
        case WidgetTypeEnum.UnfinishedIssues:
            return UnfinishedIssues;
        case WidgetTypeEnum.UsersOnline:
            return UsersOnline;
        case WidgetTypeEnum.PiecesIssuedToCouriers:
            return PiecesIssuedToCouriers;
        case WidgetTypeEnum.ActiveInventories:
            return ActiveInventories;
        case WidgetTypeEnum.Inventories:
            return Inventories;
        case WidgetTypeEnum.ReceiptStatuses:
            return ReceiptStatuses;
        case WidgetTypeEnum.ReceiptsProductsSum:
            return ReceiptsProductsSum;
        case WidgetTypeEnum.TodaysIssues:
            return TodaysIssues;
        case WidgetTypeEnum.MostIssuedProducts:
            return MostIssuedProducts;
        case WidgetTypeEnum.IssuesByStatus:
            return IssuesByStatus;
        case WidgetTypeEnum.TransfersByStatus:
            return TransfersByStatus;
        case WidgetTypeEnum.PiecesIssuedByCouriers:
            return PiecesIssuedByCouriers;
        case WidgetTypeEnum.IssuesProductsSum:
            return IssuesProductsSum;
        case WidgetTypeEnum.Shortcuts:
            return Shortcuts;

        default: return null;
    }
});

const changeSettings = () => emit('edit-widget', props.config);
const deleteWidget = (id: string) => emit('delete-widget', id);

const fetchData = async () =>
{
    const response = await DashboardsService.getWidget(props.config.id);

    widget.value = response;
};

const init = () =>
{
    fetchData();
};

init();
</script>

<template>
    <div class="grid-stack-item" v-bind="gridStackAttributes" ref="widgetContainer">
        <div class="grid-stack-item-content" :class="{ move: isDesignMode }">
            <div v-if="widget" class="widget">
                <div class="header">
                    <h4>{{ widget.name }}</h4>

                    <IdeoDropdown v-if="isDesignMode" variant="none" dropleft no-caret class="options">
                        <template #button-content>
                            <i class="icon far fa-ellipsis-v"></i>
                        </template>
                        <template #default>
                            <IdeoDropdownItemButton @click="changeSettings">
                                <i class="icon far fa-gear"></i>
                                <span>{{ $t('[[[Ustawienia]]]') }}</span>
                            </IdeoDropdownItemButton>
                            <IdeoDropdownItemButton
                                :message="$t('[[[Czy na pewno chcesz usunąć widget?]]]')"
                                :value="widget.id"
                                message-placement="left"
                                @confirm="deleteWidget"
                            >
                                <i class="icon far fa-trash-alt"></i> {{ $t('[[[Usuń]]]') }}
                            </IdeoDropdownItemButton>
                        </template>
                    </IdeoDropdown>
                </div>
                <div class="content scroll">
                    <template v-if="component">
                        <component :is="component" :widget="widget" :config="config"></component>
                    </template>
                    <span v-else>{{ $t('[[[Nie znaleziono pasującego widgetu]]]') }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.grid-stack-item-content {
    display: flex;
    flex-direction: column;
    position: relative;

    &.resizeable-grid-item {
        display: block;
    }

    .widget {
        background: var(--bs-body-bg);
        padding: 25px 15px;
        border: 1px solid var(--bs-border-color-translucent);
        border-radius: 5px;
        flex-grow: 1;
        display: flex;
        overflow: hidden;
        flex-direction: column;

        &.smaller-padding {
            padding: 10px;
        }

        .header {
            margin-bottom: 15px;
            justify-content: space-between;
            display: flex;
            gap: 10px;
            align-items: center;

            &.smaller-margin {
                margin-bottom: 10px;
            }

            h4 {
                line-height: 1.5rem;
                font-size: 1.25rem;
                font-weight: 700;
                margin: 0;

                &.smaller-header {
                    line-height: 1.5rem;
                    font-size: 1rem;
                }
            }
        }

        .icon {
            font-size: 1rem;
        }

        .options {
            margin: -6px -12px;

            :deep(button) {
                margin: 0;

                &:active {
                    border-color: transparent;
                }
            }
        }

        .content {
            flex: 1 1 100%;
            display: flex;
            flex-direction: column;
        }
    }

    &.move {
        cursor: move;
    }

    :deep(.link-more) {
        display: inline-block;
        margin-top: 15px;
        font-size: 1rem;
        line-height: 1.75rem;
        color: var(--bs-primary);
    }
}
</style>

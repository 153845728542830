import { axios } from '@/plugins/axios';
import merge from 'lodash/merge';
import { Resource, Statement } from '@/helpers/Interfaces';
import { Product as ProductItemModel } from '@/modules/wms/products/services/ProductsService';

export enum ShortcutsEnum {
    ReceiptsToAccept = "ReceiptsToAccept",
    DirectReceiptsToAccept= "DirectReceiptsToAccept",
    IssuesToAccept = "IssuesToAccept",
    Receipts = "Receipts",
    DirectReceipts = "DirectReceipts",
    DeliveryCalendar = "DeliveryCalendar",
    QuickTransfers = "QuickTransfers",
    Transfers = "Transfers",
    Collecting = "Collecting",
    Packing = "Packing",
    CouriersIssues = "CouriersIssues",
    OneStageIssues = "OneStageIssues",
    PersonalCollecting = "PersonalCollecting",
    Inventories = "Inventories",
    Products = "Products",
    Carriers = "Carriers",
    DirectIssues = "DirectIssues"
}

/**
 * WidgetsService
 */
export default class WidgetsService
{
    /**
     *
     * @returns Promise<Resource<WidgetCounterModel>>
     */
    public static async getUnfinishedTransfersCount(): Promise<Resource<WidgetCounterModel>>
    {
        return (await axios.get<Resource<WidgetCounterModel>>('admin/transfer/widgets/unfinishedtransferscount')).data;
    }

    /**
     *
     * @returns Promise<Resource<WidgetCounterModel>>
     */
    public static async getUnfinishedIssuesCount(): Promise<Resource<WidgetCounterModel>>
    {
        return (await axios.get<Resource<WidgetCounterModel>>('admin/issue/widgets/unfinishedissuescount')).data;
    }

    /**
     *
     * @returns Promise<Resource<WidgetCounterModel>>
     */
    public static async getActiveInventoriesCount(): Promise<Resource<WidgetCounterModel>>
    {
        return (await axios.get<Resource<WidgetCounterModel>>('admin/inventories/groups/widgets/activeinventoriescount')).data;
    }

    /**
     *
     * @returns Promise<Resource<WidgetCounterModel>>
     */
    public static async getInventoriesCount(): Promise<Resource<WidgetCounterModel>>
    {
        return (await axios.get<Resource<WidgetCounterModel>>('admin/inventories/groups/widgets/inventoriescount')).data;
    }

    /**
     *
     * @returns Promise<WidgetCounterModel>
     */
    public static async getUsersOnlineCount(): Promise<WidgetCounterModel>
    {
        return (await axios.get<WidgetCounterModel>('admin/users/widgets/countactiveusers')).data;
    }

    /**
     *
     * @returns Promise<Resource<PiecesIssuedToCouriersCountModel>[]>
     */
    public static async getPiecesIssuedToCouriersCount(): Promise<Resource<PiecesIssuedToCouriersCountModel>[]>
    {
        return (await axios.get<Resource<PiecesIssuedToCouriersCountModel>[]>('admin/courier/pieces/widgets/piecesissuedtocourierscount')).data;
    }

    /**
     *
     * @returns Promise<Resource<PiecesIssuedByCouriersCountModel>[]>
     */
    public static async getPiecesIssuedByCouriersCount(): Promise<Resource<PiecesIssuedByCouriersCountModel>[]>
    {
        return (await axios.get<Resource<PiecesIssuedByCouriersCountModel>[]>('admin/courier/pieces/widgets/piecesissuedbycourierstats')).data;
    }

    /**
     *
     * @returns Promise<Resource<WidgetCounterModel>>
     */
    public static async getReceiptsProductsSum(): Promise<Resource<WidgetCounterModel>>
    {
        return (await axios.get<Resource<WidgetCounterModel>>('admin/receipt/widgets/receiptsproductssum')).data;
    }

    /**
     *
     * @returns Promise<Resource<StatusCountItemModel>[]>
     */
    public static async getReceiptsStatusCount(): Promise<Resource<StatusCountItemModel>[]>
    {
        return (await axios.get<Resource<StatusCountItemModel>[]>('admin/receipt/widgets/receiptsstatusestatistics')).data;
    }

    /**
     *
     * @returns Promise<Resource<StatusCountItemModel>[]>
     */
    public static async getTransfersByStatusCount(): Promise<Resource<StatusCountItemModel>[]>
    {
        return (await axios.get<Resource<StatusCountItemModel>[]>('admin/transfer/widgets/transfersbystatuscount')).data;
    }

    /**
     *
     * @returns Promise<Resource<WidgetCounterModel>>
     */
    public static async getTodaysIssuesCount(): Promise<Resource<WidgetCounterModel>>
    {
        return (await axios.get<Resource<WidgetCounterModel>>('admin/issue/widgets/todayissuescount')).data;
    }

    /**
     *
     * @returns Promise<Resource<WidgetSumModel>>
     */
    public static async getIssuedProductsSum(): Promise<Resource<WidgetSumModel>>
    {
        return (await axios.get<Resource<WidgetSumModel>>('admin/issue/widgets/issuesproductsum')).data;
    }

    /**
     *
     * @returns Promise<Resource<MostIssuedProductItemModel>[]>
     */
    public static async getMostIssuedProductsList(filter: MostIssuedProductsFilterModel): Promise<Resource<MostIssuedProductItemModel>[]>
    {
        return (await axios.get<Resource<MostIssuedProductItemModel>[]>('admin/issue/widgets/mostissuedproducts', {
            params: merge({}, filter)
        })).data;
    }

    /**
     *
     * @returns Promise<Resource<StatusCountItemModel>[]>
     */
    public static async getIssuesByStatusCount(filter: any): Promise<Resource<StatusCountItemModel[]>>
    {
        return (await axios.get<Resource<StatusCountItemModel[]>>('admin/issue/widgets/issuesbystatus',{
            params: merge({}, filter)
        })).data;
    }

    /**
     * @returns Promise<Resource<ShortcutsModel>>
     */
    public static async getShortcuts(): Promise<Resource<ShortcutsModel>>
    {
        return (await axios.get<Resource<ShortcutsModel>>('admin/common/widgets/shortcuts')).data;
    }

    public static async addShortcut(shortcutName: ShortcutsEnum): Promise<Statement>
    {
        return (await axios.post<Statement>(`admin/common/widgets/shortcuts`, shortcutName,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
            })).data;
    }

    public static async removeShortcut(shortcutName: ShortcutsEnum): Promise<Statement>
    {
        return (await axios.delete<Statement>(`admin/common/widgets/shortcuts`,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                data: shortcutName,
            })).data;
    }
}

export interface WidgetCounterModel
{
    count: number
}
export interface WidgetSumModel
{
    sum: number
}
export interface PiecesIssuedToCouriersCountModel
{
    publicId: string
    name: string
    numOfAllPieces: number
    numOfIssuedPieces: number
}
export interface StatusCountItemModel
{
    status: string
    count: number
}
export interface MostIssuedProductItemModel
{
    product: ProductItemModel
    count: number
}
export interface MostIssuedProductsFilterModel
{
    limit: number
}
export interface PiecesIssuedByCouriersCountModel
{
    publicId: string
    name: string
    numOfPieces: number
}
export interface ShortcutsModel
{
    activeShortcuts: ShortcutsEnum[]
    availableShortcuts: ShortcutsEnum[]
}
export interface ShortcutItemModel
{
    name: ShortcutsEnum
    text: string
    icon: string
    route: string
}

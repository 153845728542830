<script lang="ts" setup>
import { computed, useAttrs } from 'vue';

defineOptions({
    name: 'ideo-dropdown-item',
    inheritAttrs: false
});

const attrs = useAttrs();

const props = withDefaults(defineProps<{
    active?: boolean;
    activeClass?: string;
    disabled?: boolean;
    variant?: 'primary'|'secondary'|'success'|'danger'|'warning'|'info'|'light'|'dark';
}>(), {
    activeClass: 'active',
    variant: null
});

const tag = computed(() => ('to' in attrs) ? 'router-link' : 'a');
const to = computed(() => ('to' in attrs) ? attrs.to : undefined);
const getAttrs = computed(() =>
{
    return tag.value === 'a' ? {
        ...attrs,
        href: attrs.href || '#'
    } : attrs;
});

const customClasses = computed(() => ({
    'dropdown-item': true,
    'disabled': props.disabled,
    [props.activeClass]: props.active,
    [`text-${props.variant}`]: props.variant != null
}));
</script>

<template>
    <component :is="tag" :class="customClasses" :to="to" :data-dd="$.uid" v-bind="getAttrs">
        <slot name="default"></slot>
    </component>
</template>

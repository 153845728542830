<template>
    <div v-bind="attrs()">
        <list-view-utils-card v-for="(item, index) in items" :key="`row-${index}`" :index="index" :item="item">
            <slot name="row" :item="item" :index="index"></slot>
        </list-view-utils-card>
        <div v-if="items.length == 0">
            <div class="text-center p-3 mt-1 mb-3 bg-body-tertiary">{{ $t(emptyLabel) }}</div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, inject, useAttrs } from 'vue';
import Pager from '@/helpers/Pager';
import Card from '../utils/Card.vue';
import { only } from '@/helpers/Utils';

export interface Props
{
    showHeader: boolean;
    headers: any[];
    rows: any[];
    pager: Pager;
}

defineOptions({
    name: 'list-view-card-layout',
    components: {
        'list-view-utils-card': Card
    },
    inheritAttrs: false
});

const props = withDefaults(defineProps<Props>(), {});
const loaded = inject<boolean>('loaded');
const emptyLabel = inject<string>('empty-label');
const $attrs = useAttrs();
const attrs = (): Record<string, any> => only($attrs, 'class', 'style');

const items = computed(() =>
{
    if (loaded)
        return props.rows;

    return [];
});
</script>

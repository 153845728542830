<script lang="ts" setup>
import { ref, computed } from 'vue';
import { normalizeClasses } from '@/helpers/Utils';
import IdeoPopover from '@/components/ideo/popover/IdeoPopover.vue';

defineOptions({
    name: 'confirmation'
});

const props = withDefaults(defineProps<{
    value: number|string|Record<string, any>;
    target: any;
    message: string;
    placement?: string;
    id?: string|null;
    wrapperClass?: Record<string, boolean> | string[] | string;
    actionClass?: Record<string, boolean> | string[] | string;
}>(), {
    placement: 'left',
    wrapperClass: '',
    actionClass: '',
    id: null
});

const emit = defineEmits<{
    (e: 'confirm', value: number|string|Record<string, any>): void,
    (e: 'cancel'): void
}>();

const popover = ref(null);

const open = (target?: HTMLElement): void =>
{
    popover.value.open(target);
};

const close = (): void =>
{
    popover.value.close();
};

const confirm = (): void =>
{
    close();
    emit('confirm', props.value);
};

const cancel = (): void =>
{
    close();
};

const wrapperClasses = computed(() => ({
    'd-flex align-items-center': true,
    ...normalizeClasses(props.wrapperClass)
}));

const actionClasses = computed(() => ({
    'ms-auto': true,
    ...normalizeClasses(props.actionClass)
}));

defineExpose({
    open
});
</script>

<template>
    <ideo-popover ref="popover" class="popover" :id="id" :target="target" :placement="placement" triggers="blur click">
        <div :class="wrapperClasses">
            <b class="me-2" v-html="message.replaceAll('\n', '<br>')"></b>
            <div :class="actionClasses">
                <ideo-button-group class="rounded overflow-hidden" size="sm">
                    <ideo-button variant="danger" @mousedown.stop.prevent="confirm">
                        {{ $t('[[[Tak]]]') }}
                    </ideo-button>
                    <ideo-button variant="secondary" @mousedown.stop.prevent="cancel">
                        {{ $t('[[[Nie]]]') }}
                    </ideo-button>
                </ideo-button-group>
            </div>
        </div>
    </ideo-popover>
</template>

<style lang="scss" scoped>
.popover {
    border-radius: 4px;
    min-width: 250px;
    box-shadow: 0px 0px 21px -17px rgba(66, 68, 90, 1);
}
</style>

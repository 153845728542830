<script lang="ts" setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import { useEvents } from "@/plugins/events";
import { useLocalization } from "@/plugins/localization";

const { $i18n } = useLocalization();
const { $events } = useEvents();

const locale = ref($i18n.locale());
const locales = computed(() => $i18n.languages());

const shortLocale = (locale: string) => locale.split("-")[0];
const changeLocale = (value: string, emit: boolean = false) =>
{
    locale.value = value;

    emit && $events.$emit("localeChange", value);
};

onMounted(() =>
    $events.$on("localeChange", (locale: string) => changeLocale(locale))
);

onUnmounted(() =>
    $events.$off("localeChange", (locale: string) => changeLocale(locale))
);
</script>

<template>
    <div class="locales-group">
        <slot :locale="locale" :short-locale="shortLocale(locale)" />
        <div class="locales">
            <button
                v-for="(value, key) in locales"
                :key="key"
                :title="$t(value)"
                type="button"
                class="btn"
                :class="{ active: key === locale }"
                @click="changeLocale(key, true)"
            >
                {{ shortLocale(key) }}
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.locales-group {
    position: relative;

    .locales {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 2px;
        padding: 1px;
        display: flex;
        background: var(--bs-body-bg);

        .btn {
            outline: none;
            border: none;
            padding: 0;
            text-transform: uppercase;
            border-right: 1px solid var(--bs-border-color);
            width: 26px;
            display: flex;
            justify-content: center;
            line-height: 1.25;
            color: var(--bs-body-color);
            margin: 0;

            &:last-child {
                border-right: none;
            }

            &.active {
                font-weight: 700;
            }
        }
    }
}
</style>

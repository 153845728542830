<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import { SitemapNode } from '@/plugins/sitemap';
import { useSitemap } from '@/plugins/sitemap';

defineOptions({
    name: 'Breadcrumbs'
});

const { $sitemap } = useSitemap();
const route = useRoute();

const crumbs = ref<SitemapNode[]>([]);
const extraCrumb = ref<string>('');

const allCrumbs = computed(() =>
{
    if (extraCrumb.value)
    {
        return crumbs.value;
    }

    return crumbs.value.slice(0, -1);
});

const lastCrumb = computed<SitemapNode | null>(() =>
{
    if (extraCrumb.value)
    {
        return {
            name: extraCrumb.value,
            visible: true,
            allowed: true
        };
    }

    return crumbs.value[crumbs.value.length - 1] || null;
});

function url(node: any): any
{
    return $sitemap.url(node, route);
}

watch(() => $sitemap.extraCrumb, (value: string) =>
{
    extraCrumb.value = value;
});

watch(route, async (newRoute) =>
{
    $sitemap.append('');
    crumbs.value = await $sitemap.crumbs(newRoute);
}, { immediate: true });
</script>


<template>
    <div id="breadcrumbs" class="d-flex px-3 pt-2 me-1 flex-column">
        <div class="flex-fill d-flex align-items-center">
            <h3 class="text-body-emphasis d-none d-lg-block m-0" v-if="lastCrumb">
                {{ $t(lastCrumb.name) }}
            </h3>
            <div class="ms-lg-auto">
                <portal-target name="breadcrumbs" />
                <template v-if="allCrumbs.length > 0">
                    <template v-for="(item, index) in allCrumbs" :key="index">
                        <router-link class="link" :to="url(item)">{{ $t(item.name) }}</router-link>
                    </template>
                    <router-link class="link active" v-if="lastCrumb" :to="url(lastCrumb)">{{ $t(lastCrumb.name) }}</router-link>
                </template>
            </div>
        </div>
        <div class="under-breadcrumbs">
            <portal-target name="under-breadcrumbs" />
        </div>
    </div>
</template>

<style lang="scss">
#breadcrumbs {
    height: var(--ideo-breadcrumbs-height);

    .link {
        color: var(--bs-body-color);

        &:not(.separator) {
            cursor: pointer;
        }

        &.active {
            color: var(--bs-primary);
            font-weight: 500;
        }

        &:not(:last-child)::after {
            content: "\f324";
            font-family: var(--fa-font-family);
            font-size: 8px;
            vertical-align: 1px;
            display: inline-block;
            color: var(--bs-gray-400);
            padding: 0 8px;
        }
    }
}
</style>

<script lang="ts" setup>
import { ref, computed, useSlots } from 'vue';
import { useMedia } from '@/plugins/media';

defineOptions({
    name: 'action-bar',
    components: {
        'vnodes': (props: Record<string, any>, context: any) => props.vnodes
    }
});

const slots = useSlots();
const { mobile } = useMedia();

const props = withDefaults(defineProps<{
    breakpoint?: boolean;
    headerTitle?: string;
    headerIcon?: string;
    autoHeader?: boolean;
    toggleFooter?: boolean;
    toggleHeader?: boolean;
}>(), {
    breakpoint: undefined,
    headerTitle: '',
    headerIcon: ''
});

const dropdown = ref(false);
const floating = ref(false);

const condition = computed((): boolean =>
{
    if (props.breakpoint !== undefined)
        return props.breakpoint;
    else
        return mobile();
});

const all = (): any[] =>
{
    return slots.default().filter((p: any) => p.type.name === 'action-button');
};

const toolbar = (): any[] =>
{
    return all().filter((p: any) => p.props.toolbar !== undefined && p.props.toolbar !== false);
};

const back = (): any[] =>
{
    return all().filter((p: any) => p.props.back !== undefined && p.props.back !== false);
};

const firstToolbar = (): any[] =>
{
    const items = toolbar();

    return items.length > 0 ? [items[0]] : [];
};

const otherToolbar = (): any[] =>
{
    return toolbar().slice(1);
};

const firstToolbarClass = (): string =>
{
    return `btn btn-${firstToolbar()[0].props.variant}`;
};

const firstBack = (): any[] =>
{
    const items = back();

    return items.length > 0 ? [items[0]] : [];
};

const other = (): any[] =>
{
    const toolbarItems = toolbar();
    const backItems = firstBack();

    return all().filter((p: any) => !toolbarItems.includes(p) && !backItems.includes(p));
};
</script>

<template>
    <div :class="{'action-bar d-flex px-3': condition && (back().length > 0 || toolbar().length > 0)}">
        <slot name="default" v-if="!condition"></slot>
        <div class="flex-fill d-flex align-items-center" v-if="condition">
            <portal-target name="navbar-toggler-button" v-if="condition && (back().length > 0 || toolbar().length > 0)" />
            <div v-if="back().length > 0">
                <vnodes :vnodes="firstBack()" />
            </div>
            <div class="flex-fill">
                <action-header :auto-header="autoHeader" :header-title="headerTitle" :header-icon="headerIcon" />
            </div>
            <div>
                <vnodes :vnodes="firstToolbar()" v-if="toolbar().length == 1" />
                <div class="dropdown btn-group d-block" :class="{'show': dropdown}" v-if="toolbar().length > 1">
                    <vnodes :vnodes="firstToolbar()" />
                    <button type="button" class="dropdown-toggle dropdown-toggle-split" :class="firstToolbarClass()" @click="dropdown = !dropdown"></button>
                    <div class="dropdown-menu dropdown-menu-right" :class="{'show': dropdown}">
                        <vnodes :vnodes="otherToolbar()" />
                    </div>
                </div>
            </div>
        </div>
        <div class="floating rounded-pill shadow" v-if="condition && other().length == 1">
            <vnodes :vnodes="other()" />
        </div>
        <div class="floating rounded-pill shadow btn-group dropup" :class="{'show': floating}" v-if="condition && other().length > 1">
            <action-button icon="fas fa-ellipsis-v" variant="dark" @click="floating = !floating" />
            <div class="dropdown-menu dropdown-menu-right" :class="{'show': floating}">
                <vnodes :vnodes="other()" />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.floating {
    position: fixed;
    right: 15px;
    bottom: 10px;
    z-index: 10002;

    &.dropup {
        .dropdown-menu {
            top: auto;
            bottom: 100%;
            margin-top: 0;
            margin-bottom: 0.125rem;
        }
    }
}

.dropdown-menu-right {
    right: 0;
    left: auto;
}

.action-bar {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    min-width: var(--ideo-app-min-width);
    height: calc(var(--ideo-header-height));
    padding: 10px 0;
    background-color: var(--bs-white);
    border-top: none;
    z-index: 99;

    .dropdown-menu {
        z-index: 9999;
    }

    .dropdown-toggle-split {
        &::before {
            box-shadow: none !important;
        }
    }
}

[data-bs-theme="dark"] {
    .action-bar {
        background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
    }
}

</style>

<script lang="ts" setup>
import { computed, getCurrentInstance } from 'vue';
import Checkbox from 'primevue/checkbox';
import ToggleSwitch from 'primevue/toggleswitch';

defineOptions({
    name: 'ideo-form-checkbox'
});

const model = defineModel<any>({
    required: false,
    default: null,
    set(value)
    {
        emit('change', value);

        return value;
    }
});
const props = withDefaults(defineProps<{
    name?: string;
    checked?: any;
    value?: any;
    uncheckedValue?: any;
    indeterminate?: boolean;
    switch?: boolean;
    disabled?: boolean;
    inline?: boolean;
    noPadding?: boolean;
}>(), {
    name: null,
    checked: undefined,
    value: true,
    uncheckedValue: false,
    indeterminate: false,
    switch: false,
    disabled: false,
    inline: false,
    noPadding: false,
});

const emit = defineEmits<{
    (e: 'change', state: boolean): void;
    (e: 'click', event: any): void;
}>();

const instance = getCurrentInstance();
const uid = computed(() => instance.uid.toString());
const internal = computed<any>({
    get: () => props.checked !== undefined ? props.checked : model.value,
    set: (value) => props.checked !== undefined ? emit('change', value) : model.value = value
});
const proxy = computed<boolean>({
    get()
    {
        if (Array.isArray(internal.value))
        {
            return internal.value.includes(props.value);
        }
        else
        {
            return internal.value == props.value;
        }
    },
    set(value)
    {
        if (Array.isArray(internal.value))
        {
            let items = internal.value.filter(p => p != props.value);

            if (value)
                items = [...items, props.value];

            internal.value = items;
        }
        else
        {
            internal.value = value ? props.value : props.uncheckedValue;
        }
    }
});

const onClick = (e: any): void =>
{
    emit('click', e);
};

const customClass = computed(() => ({
    'd-inline': props.inline === true,
    'ms-2': !props.noPadding,
    'mb-0': props.noPadding
}));
</script>

<template>
    <div class="form-check" :class="customClass">
        <Checkbox :input-id="uid" v-model="proxy" :name="props.name" :binary="true" :indeterminate="props.indeterminate" :disabled="props.disabled" @click.stop="onClick" v-if="!props.switch" />
        <ToggleSwitch :input-id="uid" v-model="proxy" :name="props.name" :disabled="props.disabled" @click.stop="onClick" v-else />
        <label :for="uid" class="form-check-label" v-if="'default' in $slots" :class="{'me-3': noPadding === false, 'mb-0': noPadding === true}">
            <slot name="default"></slot>
        </label>
    </div>
</template>

<style>
.form-check:not(:has(label)) {
    margin-bottom: 0;
}
</style>

<template>
    <div v-bind="attrs()">
        <div v-if="hasCheckbox" class="mb-3">
            <ideo-form-checkbox
                v-model="checkedAll"
                @change="checkAllItems"
            >
                {{ $t('[[[Zaznacz wszystkie]]]')}}
            </ideo-form-checkbox>
        </div>
        <VueDraggable
            v-model="items"
            :item-key="(el: any, index: number) => index"
            draggable=".draggable"
            :move="canSort"
        >
            <template #item="{ element: item, index }">
                <list-view-utils-flex :index="index" :item="item" :headers="props.headers">
                    <slot name="row" :item="item" :index="index"></slot>
                </list-view-utils-flex>
            </template>
        </VueDraggable>
        <div v-if="rows.length == 0">
            <div class="text-center p-3 mt-1 mb-3 bg-body-tertiary">{{ $t(emptyLabel) }}</div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { inject, computed, ref, useAttrs } from 'vue';
import VueDraggable from 'vuedraggable';
import Pager from '@/helpers/Pager';
import Flex from '../utils/Flex.vue';
import { only } from '@/helpers/Utils';

export interface Props
{
    showHeader: boolean;
    headers: any[];
    rows: any[];
    pager: Pager;
}

defineOptions({
    name: 'list-view-flex-layout',
    components: {
        'list-view-utils-flex': Flex
    },
    inheritAttrs: false
});

const emit = defineEmits<{
    (e: 'check', value: boolean): void
}>();
const props = withDefaults(defineProps<Props>(), {});
const emptyLabel = inject<string>('empty-label');
const $attrs = useAttrs();
const attrs = (): Record<string, any> => only($attrs, 'class', 'style');

const onDrag = inject<any>('onDrag');
const draggable = inject<boolean>('draggable');
const headers = computed(() =>
{
    return props.headers
        .where(p => !p.disabled && p.visible !== false)
        .orderBy(p => p.position)
        .toArray();
});
const hasCheckbox = computed(() => headers.value.some((item: any) => item.checkbox))
const checkedAll = ref(false);

const canSort = (): boolean =>
{
    return draggable;
};

const items = computed({
    get()
    {
        return props.rows;
    },
    set(value: any[])
    {
        onDrag(value);
    }
});

const checkAllItems = (value: boolean): void =>
{
    emit('check', value);
};
</script>

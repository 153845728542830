<template>
    <component :is="tagName" v-bind="attrs()" :class="classNames" @click="onClick">
        <template v-if="loaded">
            <div class="text-nowrap" :style="{'margin-left': `${(props.level - 1) * 16}px`}">
                <i class="me-3" :class="icon" @click.prevent.stop="onToggle"></i>
                <slot name="default"></slot>
            </div>
        </template>
        <placeholder :width="[minWidth, maxWidth]" :height="14" v-else />
    </component>
</template>

<script lang="ts" setup>
import { computed, ComputedRef, inject, useAttrs, type Ref } from 'vue';
import { only } from '@/helpers/Utils';
import { Header } from '../helpers';

defineOptions({
    name: 'list-view-hierarchy',
    inheritAttrs: false
});

const props = withDefaults(defineProps<{
    index: number;
    header: Header;
    hasChildren?: boolean,
    expanded?: (item: any) => boolean
    level?: number
}>(), {
    hasChildren: false,
    expanded: (item: any) => false,
    level: 0
});
const emit = defineEmits<{
    (e: 'click', ev: any): void;
}>();

function onToggle()
{
    if (props.hasChildren)
        emit('click', item.value);
}

const onClick = (): void =>
{
    if (table.value && rowClick)
    {
        rowClick(item.value, props.index);
    }
};

const icon = computed(() =>
{
    if (!props.hasChildren)
        return 'fa-solid fa-fw';
    else if (props.expanded(item.value))
        return 'fa-solid fa-fw fa-chevron-down';
    else
        return 'fa-solid fa-fw fa-chevron-right';
});

const loaded = inject<Ref<boolean>>('loaded');
const layout = inject<Ref<string>>('layout');
const item = inject<ComputedRef<any>>('item');
const rowClick = inject<(item: any, index: number) => void>('row-click');

const table = computed(() => layout.value == 'table');
const tagName = computed(() => table.value ? 'td' : 'div');

const cols = computed(() => $attrs.cols as string);
const colsXs = computed(() => $attrs['cols-xs'] as string || cols.value);
const colsSm = computed(() => $attrs['cols-sm'] as string || colsXs.value);
const colsMd = computed(() => $attrs['cols-md'] as string || colsSm.value);
const colsClass = computed(() => ({
    'mb-1': true,
    'col': colsXs.value == null && colsSm.value == null && colsMd.value == null,
    [`col-${colsXs.value}`]: colsXs.value != null,
    [`col-sm-${colsSm.value}`]: colsSm.value != null,
    [`col-md-${colsMd.value}`]: colsMd.value != null
}));
const classNames = computed(() => table.value ? 'pointer' : colsClass.value);

const $attrs = useAttrs();
const minWidth = computed(() => parseInt($attrs.width as string) || 100);
const maxWidth = computed(() => parseInt($attrs.width as string) || 175);
const attrs = (): Record<string, any> => only($attrs, 'class', 'style');
</script>

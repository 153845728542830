/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import EmptyLayout from '@/views/layouts/EmptyLayout.vue';

export default [
    {
        // Konfiguracja
        path: 'configuration',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            {
                path: 'buildings',
                name: 'wms-configuration-buildings',
                meta: { auth: true },
                component: () => import('@/modules/wms/configuration/localizationBuildings/views/LocalizationBuildingsList.vue')
            },
            {
                path: 'buildings/add',
                name: 'wms-configuration-buildings-add',
                meta: { auth: true },
                component: () => import('@/modules/wms/configuration/localizationBuildings/views/LocalizationBuildingsForm.vue')
            },
            {
                path: 'buildings/:publicId',
                name: 'wms-configuration-buildings-edit',
                meta: { auth: true },
                component: () => import('@/modules/wms/configuration/localizationBuildings/views/LocalizationBuildingsForm.vue')
            },
            {
                path: 'tracks',
                name: 'configuration-tracks',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/wms/configuration/tracks/views/TrackZoneList.vue')
            },
            {
                path: 'tracks/rows/:publicId',
                name: 'configuration-tracks-rows',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/wms/configuration/tracks/views/TrackRowsList.vue')
            },
            {
                path: 'tracks/localizations/:publicId',
                name: 'configuration-tracks-localizations',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/wms/configuration/tracks/views/TrackLocalizationList.vue')
            },
            {
                path: 'zones',
                name: 'wms-configuration-zones',
                meta: { auth: true },
                component: () => import('@/modules/wms/configuration/localizationZones/views/LocalizationZonesList.vue'),
            },
            {
                path: 'zones/add',
                name: 'wms-configuration-zones-add',
                meta: { auth: true },
                component: () => import('@/modules/wms/configuration/localizationZones/views/LocalizationZonesForm.vue')
            },
            {
                path: 'zones/:publicId',
                name: 'wms-configuration-zones-edit',
                meta: { auth: true },
                component: () => import('@/modules/wms/configuration/localizationZones/views/LocalizationZonesForm.vue')
            },
            {
                path: 'zones/location/:publicId',
                name: 'wms-configuration-zones-location-edit',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/wms/configuration/localizationZones/views/Location.vue')
            },
            {
                path: 'zones/import/excel',
                name: 'wms-configuration-zones-import-excel',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/wms/configuration/localizationZones/views/ImportFromExcel.vue')
            },
            {
                path: 'printers',
                name: 'wms-configuration-printers',
                meta: { auth: true },
                component: () => import('@/modules/wms/configuration/printers/views/PrintersList.vue'),
            },
            {
                path: 'printers/add',
                name: 'wms-configuration-printers-add',
                meta: { auth: true },
                component: () => import('@/modules/wms/configuration/printers/views/PrinterForm.vue')
            },
            {
                path: 'printers/:publicId',
                name: 'wms-configuration-printers-edit',
                meta: { auth: true },
                component: () => import('@/modules/wms/configuration/printers/views/PrinterForm.vue')
            },
            {
                path: 'couriers',
                name: 'wms-configuration-couriers',
                meta: { auth: true },
                component: () => import('@/modules/wms/configuration/couriers/views/CouriersList.vue'),
            },
            {
                path: 'couriers/:publicId',
                name: 'wms-configuration-couriers-edit',
                meta: { auth: true },
                component: () => import('@/modules/wms/configuration/couriers/views/CourierDetails.vue'),
            },
            {
                path: 'printers-template',
                name: 'wms-configuration-printers-template',
                meta: { auth: true },
                component: () => import('@/modules/wms/configuration/printersTemplate/views/TemplatesList.vue'),
            },
            {
                path: 'printers-template/:nameTemplate',
                name: 'wms-configuration-printers-template-edit',
                meta: { auth: true },
                component: () => import('@/modules/wms/configuration/printersTemplate/views/TemplateDetails.vue'),
            },
            {
                path: 'wms-settings',
                name: 'wms-settings',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/wms/configuration/settings/views/WmsSettings.vue')
            },
            {
                path: 'carriers',
                name: 'wms-carriers',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/wms/configuration/carriers/views/CarriersList.vue')
            },
            {
                path: 'carriers/add',
                name: 'wms-carriers-add',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/wms/configuration/carriers/views/CarriersForm.vue')
            },
            {
                path: 'carriers/edit/:id',
                name: 'wms-carriers-edit',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/wms/configuration/carriers/views/CarriersForm.vue')
            },
            {
                path: 'carriers/kinds',
                name: 'wms-carriers-kinds',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/wms/configuration/carriers/views/CarriersKindList.vue')
            },
            {
                path: 'carriers/kinds/add',
                name: 'wms-carriers-kinds-add',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/wms/configuration/carriers/views/CarriersKindForm.vue')
            },
            {
                path: 'carriers/kinds/edit/:id',
                name: 'wms-carriers-kinds-edit',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/wms/configuration/carriers/views/CarriersKindForm.vue')
            },
            {
                path: 'processes',
                name: 'wms-configuration-processes',
                meta: { auth: true },
                component: () => import('@/modules/wms/configuration/processes/views/ProcessesList.vue')
            },
            {
                path: 'processes/:id',
                name: 'wms-configuration-processes-details',
                meta: { auth: true },
                component: () => import('@/modules/wms/configuration/processes/views/ProcessesDetails.vue')
            },
            {
                path: 'barcodes',
                name: 'wms-configuration-barcodes',
                meta: { auth: true },
                component: () => import('@/modules/wms/configuration/barcodes/views/BarcodesList.vue'),
            },
            {
                path: 'barcodes/add',
                name: 'wms-configuration-barcodes-add',
                meta: { auth: true },
                component: () => import('@/modules/wms/configuration/barcodes/views/BarcodeForm.vue')
            },
            {
                path: 'barcodes/:publicId',
                name: 'wms-configuration-barcodes-edit',
                meta: { auth: true },
                component: () => import('@/modules/wms/configuration/barcodes/views/BarcodeForm.vue')
            },
            {
                path: 'zonetypes',
                name: 'wms-configuration-zonetypes',
                meta: { auth: true },
                component: () => import('@/modules/wms/configuration/localizationZoneTypes/views/LocalizationZoneTypesList.vue'),
            },
            {
                path: 'zonetypes/add',
                name: 'wms-configuration-zonetypes-add',
                meta: { auth: true },
                component: () => import('@/modules/wms/configuration/localizationZoneTypes/views/LocalizationZoneTypeForm.vue')
            },
            {
                path: 'zonetypes/:publicId',
                name: 'wms-configuration-zonetypes-edit',
                meta: { auth: true },
                component: () => import('@/modules/wms/configuration/localizationZoneTypes/views/LocalizationZoneTypeForm.vue')

            },
            {
                path: 'localizationskinds',
                name: 'wms-configuration-localizations-kinds',
                meta: { auth: true },
                component: () => import('@/modules/wms/configuration/localizationKinds/views/LocalizationKindsList.vue'),
            },
            {
                path: 'localizationskinds/add',
                name: 'wms-configuration-localizations-kinds-add',
                meta: { auth: true },
                component: () => import('@/modules/wms/configuration/localizationKinds/views/LocalizationKindForm.vue')
            },
            {
                path: 'localizationskinds/:publicId',
                name: 'wms-configuration-localizations-kinds-edit',
                meta: { auth: true },
                component: () => import('@/modules/wms/configuration/localizationKinds/views/LocalizationKindForm.vue')

            }
        ]
    },
    // Produkty
    {
        path: 'products',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            {
                path: 'list',
                name: 'wms-products-list',
                meta: { auth: true },
                component: () => import('@/modules/wms/products/views/ProductsList.vue'),
            },
            {
                path: ':publicId',
                name: 'wms-products-edit',
                meta: { auth: true },
                component: () => import('@/modules/wms/products/views/ProductForm.vue')
            }
        ]
    },
    // Informator
    {
        path: 'informator',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            {
                path: 'search',
                name: 'wms-informator-search',
                meta: { auth: true },
                component: () => import('@/modules/wms/informator/views/Informator.vue'),
            },
        ]
    },
    // Przyjęcia
    {
        path: 'receipts',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            {
                path: 'receipt',
                name: 'wms-receipts-receipt-list',
                meta: { auth: true },
                component: () => import('@/modules/wms/receipts/receipt/views/ReceiptsList.vue'),
            },
            {
                path: 'receipt/:publicId',
                name: 'wms-receipts-receipt-details',
                meta: { auth: true },
                component: () => import('@/modules/wms/receipts/receipt/views/ReceiptForm.vue'),
            },
            {
                path: 'direct-receipt',
                name: 'wms-receipts-direct-receipt-list',
                meta: { auth: true },
                component: () => import('@/modules/wms/receipts/direct-receipt/views/DirectReceiptsList.vue'),
            },
            {
                path: 'direct-receipt/:publicId',
                name: 'wms-receipts-direct-receipt-details',
                meta: { auth: true },
                component: () => import('@/modules/wms/receipts/direct-receipt/views/DirectReceiptDetails.vue'),
            },
            {
                path: 'direct-receipt/form',
                name: 'wms-receipts-direct-receipt-form',
                meta: { auth: true },
                component: () => import('@/modules/wms/receipts/direct-receipt/views/DirectReceiptForm.vue'),
            },
            {
                path: 'calendar',
                name: 'wms-receipts-calendar',
                meta: { auth: true },
                component: () => import('@/modules/wms/receipts/calendar/views/CalendarView.vue'),
            }
        ]
    },
    // Wydania
    {
        path: 'issue',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            {
                path: 'couriers',
                name: 'wms-issue-couriers-list',
                meta: { auth: true },
                component: () => import('@/modules/wms/issue/couriers/views/IssueCouriersList.vue'),
            },
            {
                path: 'couriers/:publicId',
                name: 'wms-issue-couriers-details',
                meta: { auth: true },
                component: () => import('@/modules/wms/issue/couriers/views/IssueCouriersDocuments.vue'),
            },
            {
                path: 'couriers/documents/:publicId',
                name: 'wms-issue-document-details',
                meta: { auth: true },
                component: () => import('@/modules/wms/issue/couriers/views/IssueDocuments.vue'),
            }
        ]
    },
    // Akceptacje
    {
        path: 'approvals',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            {
                path: 'receipts',
                name: 'wms-approvals-receipts-list',
                meta: { auth: true },
                component: () => import('@/modules/wms/approvals/receipts/views/ApprovalsList.vue'),
            },
            {
                path: 'receipts/:publicId',
                name: 'wms-approvals-receipts-details',
                meta: { auth: true },
                component: () => import('@/modules/wms/approvals/receipts/views/ApprovalForm.vue'),
            },
            {
                path: 'direct-receipts',
                name: 'wms-approvals-direct-receipts-list',
                meta: { auth: true },
                component: () => import('@/modules/wms/approvals/direct-receipts/views/ApprovalsList.vue'),
            },
            {
                path: 'direct-receipts/:publicId',
                name: 'wms-approvals-direct-receipts-details',
                meta: { auth: true },
                component: () => import('@/modules/wms/approvals/direct-receipts/views/ApprovalForm.vue'),
            },
            {
                path: 'issue',
                name: 'wms-approvals-issue-list',
                meta: { auth: true },
                component: () => import('@/modules/wms/approvals/issue/views/IssueApprovalList.vue'),
            },
            {
                path: 'issue/:publicId',
                name: 'wms-approvals-issue-details',
                meta: { auth: true },
                component: () => import('@/modules/wms/approvals/issue/views/IssueApprovalForm.vue'),
            },
            {
                path: 'direct-issues',
                name: 'wms-approvals-direct-issues-list',
                meta: { auth: true },
                component: () => import('@/modules/wms/approvals/direct-issues/views/ApprovalsList.vue'),
            },
            {
                path: 'direct-issues/:publicId',
                name: 'wms-approvals-direct-issues-details',
                meta: { auth: true },
                component: () => import('@/modules/wms/approvals/direct-issues/views/ApprovalForm.vue'),
            },
        ]
    },
    // Kontrola jakości
    {
        path: 'quality-control',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            {
                path: 'list',
                name: 'wms-quality-control-list',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "wms" */ '@/modules/wms/quality-control/views/QualityControlList.vue'),
            },
            {
                path: ':publicId',
                name: 'wms-quality-control-details',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "wms" */ '@/modules/wms/quality-control/views/QualityControlDetails.vue'),
            }
        ]
    },
    // Przesunięcia
    {
        path: 'transfers',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            {
                path: 'list',
                name: 'wms-transfers-list',
                meta: { auth: true },
                component: () => import('@/modules/wms/transfers/regularTransfer/views/TransfersList.vue'),
            },
            {
                path: 'quick-transfer/:publicId?',
                name: 'wms-transfers-quick-transfer-form',
                meta: { auth: true },
                component: () => import('@/modules/wms/transfers/quickTransfer/views/QuickTransferForm.vue'),
            },
            {
                path: 'create',
                name: 'wms-transfers-create-transfer-form',
                meta: { auth: true },
                component: () => import('@/modules/wms/transfers/create/views/CreateTransferForm.vue'),
            },
            {
                path: 'edit/:publicId',
                name: 'wms-transfers-edit-transfer-form',
                meta: { auth: true },
                component: () => import('@/modules/wms/transfers/create/views/CreateTransferForm.vue'),
            },
            {
                path: 'regular-transfer/:publicId',
                name: 'wms-transfers-regular-transfer-form',
                meta: { auth: true },
                component: () => import('@/modules/wms/transfers/regularTransfer/views/RegularTransferForm.vue'),
            }
        ]
    },
    // Inwentaryzacje
    {
        path: 'inventories-groups',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            {
                path: '',
                name: 'wms-inventories-groups',
                meta: { auth: true },
                component: () => import('@/modules/wms/inventories/views/GroupList.vue')
            },
            {
                path: 'add',
                name: 'wms-inventories-groups-add',
                meta: { auth: true },
                component: () => import('@/modules/wms/inventories/views/GroupForm.vue')
            },
            {
                path: ':groupPublicId/partial-inventories',
                name: 'wms-partial-inventories',
                meta: { auth: true },
                component: () => import('@/modules/wms/inventories/views/PartialList.vue'),
            },
            {
                path: ':groupPublicId/partial-inventories/:partialPublicId',
                name: 'wms-partial-inventories-edit',
                meta: { auth: true },
                component: () => import('@/modules/wms/inventories/views/PartialForm.vue'),
            }
        ]
    },
    // Wydania
    {
        path: 'issue',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            {
                path: 'onestage',
                name: 'wms-issue-onestage-list',
                meta: { auth: true },
                component: () => import('@/modules/wms/issue/oneStage/views/IssueList.vue'),
            },
            {
                path: 'onestage/:publicId',
                name: 'wms-issue-onestage-edit',
                meta: { auth: true },
                component: () => import('@/modules/wms/issue/oneStage/views/IssueForm.vue'),
            },
            {
                path: 'collecting',
                name: 'wms-issue-collecting-list',
                meta: { auth: true },
                component: () => import('@/modules/wms/issue/collecting/views/IssueCollectingList.vue'),
            },
            {
                path: 'collecting/:publicId',
                name: 'wms-issue-collecting-edit',
                meta: { auth: true },
                component: () => import('@/modules/wms/issue/collecting/views/standard/IssueCollectingForm.vue'),
            },
            {
                path: 'aggregated-collecting/:publicId/:lockOnMounted?',
                name: 'wms-issue-aggregated-collecting-edit',
                meta: { auth: true },
                component: () => import('@/modules/wms/issue/collecting/views/aggregated/IssueAggregatedCollectingForm.vue')
            },
            {
                path: 'packing',
                name: 'wms-issue-packing-list',
                meta: { auth: true },
                component: () => import('@/modules/wms/issue/packing/views/IssuePackingList.vue'),
            },
            {
                path: 'personal-collecting',
                name: 'wms-issue-personal-collecting-list',
                meta: { auth: true },
                component: () => import('@/modules/wms/issue/personal-collecting/views/IssuePersonalCollectingList.vue'),
            },
            {
                path: 'personal-collecting/:publicId',
                name: 'wms-issue-personal-collecting-edit',
                meta: { auth: true },
                component: () => import('@/modules/wms/issue/personal-collecting/views/IssuePersonalCollectingForm.vue'),
            },
            {
                path: 'packing/:publicId',
                name: 'wms-issue-packing-edit',
                meta: { auth: true },
                component: () => import('@/modules/wms/issue/packing/views/IssuePackingForm.vue'),
            },
            {
                path: 'direct-issue',
                name: 'wms-issue-direct-issue-list',
                meta: { auth: true },
                component: () => import('@/modules/wms/issue/direct-issue/views/DirectIssueList.vue'),
            },
            {
                path: 'direct-issue/:publicId',
                name: 'wms-issue-direct-issue-details',
                meta: { auth: true },
                component: () => import('@/modules/wms/issue/direct-issue/views/DirectIssueDetails.vue'),
            },
            {
                path: 'direct-issue/form',
                name: 'wms-issue-direct-issue-form',
                meta: { auth: true },
                component: () => import('@/modules/wms/issue/direct-issue/views/DirectIssueForm.vue'),
            },
        ]
    },
    // Zwroty
    {
        path: 'returns',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            {
                path: 'list',
                name: 'wms-returns-list',
                meta: { auth: true },
                component: () => import('@/modules/wms/returns/return/views/ReturnsList.vue'),
            },
            {
                path: ':publicId',
                name: 'wms-returns-edit',
                meta: { auth: true },
                component: () => import('@/modules/wms/returns/return/views/ReturnForm.vue'),
            },
        ]
    },
    // Raporty
    {
        path: 'reports',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            {
                path: 'system',
                name: 'wms-reports-system-list',
                meta: { auth: true },
                component: () => import('@/modules/wms/reports/system/ReportsList.vue'),
            },
            {
                path: 'system/:viewName',
                name: 'wms-reports-system-details',
                meta: { auth: true },
                component: () => import('@/modules/wms/reports/system/ComponentView.vue'),
            },
            {
                path: 'ai',
                name: 'wms-reports-ai',
                meta: { auth: true },
                component: () => import('@/modules/wms/reports/ai/views/ReportList.vue'),
            },
            {
                path: 'ai/add',
                name: 'wms-reports-ai-add',
                meta: { auth: true },
                component: () => import('@/modules/wms/reports/ai/views/ReportForm.vue'),
            },
            {
                path: 'ai/:id',
                name: 'wms-reports-ai-view',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/wms/reports/ai/views/ReportForm.vue'),
            },
            {
                path: 'ai/:id/execute',
                name: 'wms-reports-ai-execute',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/wms/reports/ai/views/ResultsView.vue'),
            }
        ]
    },
    {
        path: 'packing-calculator',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            {
                path: 'calculator',
                name: 'packing-calculator',
                component: () => import('@/modules/wms/packingCalculator/views/ContainerPackingVisualization.vue')
            }
        ]
    }
];

<script lang="ts" setup>
import { ref, reactive } from 'vue';
import { useLocalization } from '@/plugins/localization';
import { useAlerts } from '@/plugins/alerts';
import { debounce } from 'lodash';
import { Form } from '@/helpers/Form';
import DashboardsService, {
    WidgetCategoryModel,
    WidgetModel,
    AddWidgetFilterModel,
} from '@/modules/core/dashboards/services/DashboardsService';
import IdeoModal from '@/components/ideo/modal/IdeoModal.vue';

const emit = defineEmits<{
    (e: 'select-widget', widget: WidgetModel): void;
}>();

const { $alert } = useAlerts();
const { $t } = useLocalization();

const modal = ref<IdeoModal | null>(null);
const categories = ref<WidgetCategoryModel[]>([]);
const widgets = ref<WidgetModel[]>([]);

const filter = reactive(
    Form.create<AddWidgetFilterModel>({
        search: '',
        categoryId: '',
    })
);

const showModal = async () =>
{
    modal.value?.show();
    init();
};

const selectWidget = (widget: WidgetModel) =>
{
    emit('select-widget', widget);
    modal.value?.hide();
};

const updateCategory = (id: string) =>
{
    filter.categoryId = id;

    fetchWidgets();
};

const updateSearch = (e: InputEvent) =>
{
    const value = (e.target as HTMLInputElement).value;

    filter.search = value;

    fetchWidgets();
};

const deboundeSearch = debounce(updateSearch, 500);

const fetchCategories = async () =>
{
    try
    {
        const response = await DashboardsService.getWidgetCategories();

        categories.value = response;
    }
    catch (ex: any)
    {
        if (ex.code == 400) $alert.error($t('[[[Nie udało się pobrać widgetu/ów]]]'));
    }
};

const fetchWidgets = async () =>
{
    try
    {
        const response = await DashboardsService.getWidgets(filter.data());

        widgets.value = response;
    }
    catch (ex: any)
    {
        if (ex.code == 400) $alert.error($t('[[[Nie udało się pobrać widgetu/ów]]]'));
    }
};

const init = async () =>
{
    await fetchCategories();

    categories.value?.length && updateCategory(categories.value[0].id);
};

defineExpose({
    showModal,
});
</script>

<template>
    <IdeoModal centered ref="modal" :title="$t('[[[Dodaj widget]]]')" size="lg" :hide-footer="true" @hidden="filter.reset()">
        <template #default>
            <div class="d-flex flex-column flex-lg-row wrapper">
                <nav class="nav">
                    <ul>
                        <li
                            v-for="category in categories"
                            :key="category.id"
                            :class="{ active: filter.categoryId === category.id }"
                            @click="updateCategory(category.id)"
                        >
                            <i :class="category.icon"></i>
                            <span>{{ category.name }}</span>
                        </li>
                    </ul>
                </nav>
                <div class="flex-fill">
                    <div class="search">
                        <i class="far fa-search"></i>
                        <input
                            :model-value="filter.search"
                            type="text"
                            class="form-control"
                            :placeholder="$t('[[[Wyszukaj widget]]]')"
                            @input="deboundeSearch"
                        />
                    </div>

                    <div class="widget-wrapper">
                        <button v-for="widget in widgets" :key="widget.id" class="widget" @click="selectWidget(widget)">
                            <i :class="`far ${widget.icon}`"></i>
                            <span>{{ widget.name }}</span>
                            <p v-if="widget.description">{{ widget.description }}</p>
                        </button>
                    </div>

                    <span v-if="!widgets.length">{{ $t('[[[Brak wyników]]]') }}</span>
                </div>
            </div>
        </template>
    </IdeoModal>
</template>

<style lang="scss" scoped>
.header {
    height: 60px;
    display: flex;
    align-items: center;
    margin-top: -16px;
    margin-bottom: 5px;

    h2 {
        font-size: 1.25rem;
        line-height: 1.5rem;
        font-weight: 700;
    }
}

.wrapper {
    min-height: 350px;
    width: 100%;
    gap: 30px;

    .nav {
        margin-top: 10px;
        width: 226px;
        flex-shrink: 0;

        ul {
            list-style: none;
            padding: 0;
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin: 0;

            li {
                cursor: pointer;
                height: 20px;
                display: flex;
                align-items: center;
                gap: 15px;
                font-size: 1rem;
                color: var(--bs-gray-800);

                &.active {
                    color: var(--bs-primary);
                }

                i {
                    width: 20px;
                    height: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }

    .search {
        position: relative;
        margin-bottom: 30px;

        i {
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            font-size: 1rem;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
        }

        input {
            height: 38px;
            padding: 8px 10px;
            padding-left: 45px;
            line-height: 1;
        }
    }

    .widget-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;

        .widget {
            padding: 15px 10px;
            border-radius: 5px;
            display: flex;
            border: 1px solid var(--bs-gray-300);
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            gap: 15px;
            background: var(--bs-body-bg);

            i {
                font-size: 1.5rem;
                color: #b56fc9;
            }

            span {
                font-weight: 700;
                font-size: 1rem;
                font-weight: 700;
                color: var(--bs-gray-600);
            }

            p {
                color: var(--bs-gray-600);
                margin: 0;
            }
        }
    }
}
</style>

<script lang="ts" setup>
import { computed, getCurrentInstance } from 'vue';
import RadioButton from 'primevue/radiobutton';

defineOptions({
    name: 'ideo-form-radio'
});

const model = defineModel<any>({
    required: false,
    default: null,
    set(value)
    {
        emit('change', value);

        return value;
    }
});
const props = withDefaults(defineProps<{
    name?: string;
    checked?: any;
    value?: any;
    disabled?: boolean;
    inline?: boolean;
    noPadding?: boolean;
}>(), {
    name: null,
    checked: undefined,
    value: null,
    disabled: false,
    inline: false,
    noPadding: false,
});

const emit = defineEmits<{
    (e: 'change', value: any): void;
}>();

const instance = getCurrentInstance();
const uid = computed(() => instance.uid.toString());
const internal = computed({
    get: () => props.checked !== undefined ? props.checked : model.value,
    set: (value) => props.checked !== undefined ? emit('change', value) : model.value = value
});
const proxy = computed<boolean>({
    get: () => internal.value == props.value,
    set: (value) => internal.value = props.value
});

const customClass = computed(() => ({
    'd-inline': props.inline === true,
    'ms-2': !props.noPadding,
    'mb-0': props.noPadding
}));
</script>

<template>
    <div class="form-check" :class="customClass">
        <RadioButton :input-id="uid" :name="name" :binary="true" v-model="proxy" :value="props.value" :disabled="disabled" />
        <label :for="uid" class="form-check-label" :class="{'me-3': 'default' in $slots}">
            <slot name="default"></slot>
        </label>
    </div>
</template>

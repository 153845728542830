<script lang="ts" setup>
import { ref, computed, watch, useSlots } from 'vue';
import Pager from '@/helpers/Pager';
import { isObject } from 'lodash';
import ListViewBase from '@/components/list/ListViewBase.vue';
import { Header, getProxy } from './helpers';
import { useProgress } from '@/plugins/progress';

export interface Props
{
    layout?: 'auto'|'table'|'flex'|'card';
    showHeader?: boolean;
    items: any[];
    columns?: {
        visible: Record<string, boolean>,
        positions: Record<string, number>
    };
    pager?: Pager;
    emptyLabel?: string;
    preload?: boolean;
    loading?: boolean;
    rowHover?: boolean;
    draggable?: boolean;
    rowClick?: (item: any, i: number, e: PointerEvent) => void;
    rowDblClick?: (item: any) => void;
    rowClass?: (item: any) => Record<string, boolean> | string[] | string;
    customisable?: boolean;
    absoluteArrows?: boolean;
}

defineOptions({
    name: 'list-view',
    components: {
        'list-view-base': ListViewBase
    }
});

const props = withDefaults(defineProps<Props>(), {
    layout: 'auto',
    showHeader: true,
    columns: () => ({visible: {}, positions: {}}),
    pager: () => new Pager(1, 20),
    emptyLabel: '[[[Brak wyników]]]',
    preload: true,
    rowClick: (item: any, i: number, e: PointerEvent) => {},
    rowClass: (item: any) => ({}),
    rowHover: true,
    rowDblClick: (item: any) => {},
    draggable: false,
    customisable: true,
    absoluteArrows: true
});

const emit = defineEmits<{
    (e: 'check', value: boolean): void,
    (e: 'drag', value: any): void,
    (e: 'change'): void
}>();

const onChange = (): void =>
{
    emit('change');
};

const onDrag = (value: any): void =>
{
    emit('drag', value);
};

const checkItems = (value: boolean): void =>
{
    emit('check', value);
};

const $progress = useProgress();
const $slots = useSlots();
const paging = ref(false);
const loaded = ref(!props.preload);
const loading = computed(() => props.loading || $progress.loading);
const hasUnderRowSlot = computed(() => !!$slots.underRow);

const headers = computed<Header[]>(() =>
{
    if ('row' in $slots)
    {
        const data = getProxy();
        const cols = [] as any[];

        calculateHeaders($slots.row({ item: data }), cols);

        // const cols = $slots.row({ item: data }).filter((p: any) => isObject(p.type) && p.type.name.startsWith('list-view-'));

        return cols.map((p: any) => ({...(p.props || {}), type: p.type.name.replace('list-view-', '')}));
    }

    return [];
});

const calculateHeaders = (arr: any[], result: any[]) : void =>
{
    if (!(typeof arr === 'string' || arr instanceof String))
    {
        arr.forEach((element: any) =>
        {
            if (isObject(element.type) && element.type.name?.startsWith('list-view-'))
            {
                result.push(element);

                return;
            }
            else if (element.children?.length > 0)
            {
                calculateHeaders(element.children, result);
            }
        });
    }
};

const rows = computed(() =>
{
    const preloading = props.layout !== 'card' && props.preload;

    if (preloading && props.pager.loading)
    {
        const data = getProxy();

        return Array.from(Array(5), () => data);
    }

    return props.items || [];
});

watch(() => [props.pager.pageIndex, props.pager.pageSize, props.pager.sorting, props.pager.order], () =>
{
    paging.value = true;
});

// watch(() => props.items, () =>
// {
//     if (paging.value == true)
//     {
//         paging.value = false;
//         loaded.value = true;
//     }

//     if (loaded.value == true && props.items.length == 0)
//     {
//         loaded.value = false;
//     }
//     else if (loaded.value == false)
//     {
//         loaded.value = true;
//     }
// });

// watch(() => loading.value, (value) =>
// {
//     if (value == false && !props.loading)
//     {
//         loaded.value = true;
//     }
// });

watch(() => props.pager.loading, (value) =>
{
    if (!props.preload)
    {
        loaded.value = true;

        return;
    }

    if (typeof value !== 'boolean')
        return;

    loaded.value = !value;
},{ immediate: true });
</script>

<template>
    <list-view-base
        :loaded="loaded" :layout="props.layout"
        :headers="headers" :show-header="props.showHeader" :columns="props.columns"
        :items="rows" :pager="props.pager" :empty-label="props.emptyLabel" :row-hover="rowHover"
        :row-click="props.rowClick" :row-dbl-click="props.rowDblClick" :row-class="props.rowClass" :draggable="draggable"
        :customisable="props.customisable" :absolute-arrows="props.absoluteArrows"
        @change="onChange()"
        @check="checkItems"
        @drag="onDrag"
    >
        <template #row="{item, index}">
            <slot name="row" :item="item" :index="index"></slot>
        </template>
        <template #underRow="{item}" v-if="hasUnderRowSlot">
            <slot name="underRow" :item="item"></slot>
        </template>
    </list-view-base>
</template>

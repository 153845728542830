import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { ValidatableBlueprint } from '@/components/builder/base/blueprints/ValidatableBlueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { ReadonlyBlueprint } from '@/components/builder/form/blueprints/ReadonlyBlueprint';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { InternallyChoice } from '@/components/builder/form/enums/InternallyChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { HasHelp } from '@/components/builder/form/traits/HasHelp';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { Relational } from '@/components/builder/form/traits/Relational';

export const Definition: BlueprintDefinition = {
    type: 'related-documents',
    name: '[[[Dokumenty]]]',
    icon: 'fa-table',
    group: 'relational',
    position: 3,
    disabled: true // Kontrolka wyłączona, nie obsługuje uprawnień, nie nadaje się na produkcję
};

export interface RelatedDocumentsContract extends Blueprint, VisibleBlueprint, ReadonlyBlueprint, HasLabel, HasHelp, HasWidth, Relational
{
}

export class RelatedDocumentsType implements RelatedDocumentsContract, ValidatableBlueprint
{
    public id: string;
    public type: string;
    public name: string;
    public label: Record<string, string>;
    public showLabel: boolean;
    public moduleId: number;
    public sectionId: string;
    public categoryId: number;
    public formId: number;
    public componentId: string;
    public dependsOn: string[];
    public help: Record<string, string>;
    public minWidth: number;
    public width: number;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public errors: ValidationErrors;

    public constructor(id: string, name: string)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.label = { 'pl-PL': 'Dokumenty' };
        this.showLabel = true;
        this.moduleId = null;
        this.sectionId = '';
        this.categoryId = null;
        this.formId = null;
        this.componentId = '';
        this.dependsOn = [];
        this.help = {};
        this.minWidth = 6;
        this.width = 6;
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
    }

    public setDefaultWidth(width: number): void
    {
        this.width = 6;
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        if (!this.moduleId)
            this.errors.moduleId = ['[[[Wymagane jest wybranie modułu]]]'];

        if (!this.sectionId)
            this.errors.sectionId = ['[[[Wymagane jest wybranie sekcji]]]'];

        if (!this.componentId)
            this.errors.componentId = ['[[[Wymagane jest wybranie komponentu]]]'];

        return {
            [this.name]: this.errors
        };
    }
}

<script lang="ts" setup>
import { $t } from '@/plugins/localization';

const model = defineModel<boolean>({ default: false });

withDefaults(defineProps<{
    active?: boolean;
    onIcon?: string;
    offIcon?: string;
    title?: string;
}>(), {
    active: true,
    onIcon: 'fa-lock',
    offIcon: 'fa-lock-open',
    title: $t('[[[Ta opcja służy do włączenia lub wyłączenia dziedziczenia w danym polu]]]')
});

const handleClick = (): void =>
{
    model.value = !model.value;
};
</script>

<template>
    <div class="disabled-group" :class="{'active': active}">
        <slot :state="model" />

        <button v-if="active" class="switch-btn btn btn-link" @click.prevent="handleClick" :title="title">
            <i :class="['far', model ? onIcon : offIcon]"></i>
        </button>
    </div>
</template>

<style lang="scss">
.disabled-group.active {
    position: relative;

    .form-group {
        label {
            padding-left: 20px;
        }

        > .form-check {
            padding-left: calc(22px - 0.5rem);
        }
    }

    .switch-btn {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 19.5px;
        padding: 0;
        color: inherit;

        &:hover {
            text-decoration: none;
            color: inherit;
        }
    }
}
</style>

<script lang="ts" setup>
import { computed } from 'vue';

defineOptions({
    name: 'ideo-alert'
});

const props = withDefaults(defineProps<{
    show?: boolean;
    variant?: 'primary'|'secondary'|'success'|'danger'|'warning'|'info'|'light'|'dark'
}>(), {
    show: false,
    variant: 'secondary'
});

const flag = (value: any): boolean =>
{
    return value !== false;
};

const customClasses = computed((): Record<string, boolean> => ({
    'alert': true,
    [`alert-${props.variant}`]: true
}));
</script>

<template>
    <div :class="customClasses" v-if="flag(show)">
        <slot name="default"></slot>
    </div>
</template>

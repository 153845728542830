<script lang="ts" setup>
import { ref, computed, onMounted, getCurrentInstance } from 'vue';
import { useMixins } from '@/plugins/mixins';
import { useMedia } from '@/plugins/media';
import ConfirmationModal from '@/components/common/ConfirmationModal.vue';

const instance = getCurrentInstance();
const { $redirect } = useMixins();

defineOptions({
    name: 'action-button'
});

const props = withDefaults(defineProps<{
    icon?: string;
    text?: string;
    to?: Record<string, any>|null;
    title?: string|null;
    tag?: string;
    loading?: boolean;
    confirm?: string;
    confirmAttrs?: any;
    variant?: string;
    nowrap?: boolean;
    back?: boolean;
    submit?: boolean;
    toolbar?: boolean;
    disabled?: boolean;
}>(), {
    icon: null,
    tag: 'button',
    loading: false,
    confirm: '',
    confirmAttrs: {},
    variant: 'primary',
    nowrap: true,
    title: null,
    text: null,
    to: null,
});

const emit = defineEmits<{
    (e: 'click', ev: any): void;
}>();

const loaded = ref(false);
const dropdown = ref(false);
const floating = ref(false);

const el = ref(null);
const confirmation = ref<typeof ConfirmationModal>(null);

const media = useMedia();
const condition = computed(() =>
{
    if ('condition' in instance.parent)
        return (instance.parent as any).condition;
    else
        return media.mobile();
});

const dropdownButton = computed(() => condition.value && dropdown.value);
const floatingButton = computed(() => condition.value && floating.value);
const backButton = computed(() => condition.value && props.back && !dropdownButton.value && !floatingButton.value);

const buttonType = computed(() => props.submit ? 'submit' : 'button');

const buttonClass = computed(() =>
{
    if (dropdownButton.value)
        return 'dropdown-item';

    if (floatingButton.value)
        return `btn btn-${props.variant} btn-float rounded-circle`;

    if (backButton.value)
        return `btn btn-light bg-white border-0 back-button`;

    return `btn btn-${props.variant}`;
});

const textVisible = computed(() =>
{
    if (dropdownButton.value)
        return true;

    if (!props.text)
        return false;

    if (floatingButton.value)
        return false;

    if (backButton.value)
        return false;

    return true;
});

const iconSpace = computed(() => textVisible.value ? 'me-2' : '');
const iconClass = computed(() =>
{
    if (dropdownButton.value)
        return `${props.icon} ${iconSpace.value}`;

    if (floatingButton.value)
        return `${props.icon} fa-fw`;

    if (backButton.value)
        return 'fas fa-arrow-left fa-back';

    return `${props.icon} ${iconSpace.value}`;
});

const buttonText = computed(() =>
{
    if (dropdownButton.value)
        return props.text || props.title;

    return props.text;
});

onMounted((): void =>
{
    dropdown.value = el.value.parentElement?.classList.contains('dropdown-menu');
    floating.value = el.value.parentElement?.classList.contains('floating');
    loaded.value = true;
});

const triggerClick = (e: any): void =>
{
    emit('click', e);
};

const onConfirm = (e: any): void =>
{
    if (props.to)
        $redirect(props.to);
    else
        triggerClick(e);
};

const onClick = (e: any): void =>
{
    if (props.confirm.length == 0)
    {
        onConfirm(e);
    }
    else
    {
        confirmation.value.open();
    }
};
</script>

<template>
    <component :is="tag" ref="el" :type="buttonType" :class="[buttonClass, {'text-nowrap': nowrap}]" :title="title" @click.stop="onClick" :disabled="disabled || loading">
        <i :class="[iconClass, {'d-none d-sm-inline': buttonType === 'submit' && !dropdown}]"></i>
        <span v-if="textVisible">{{ buttonText }}</span>

        <ConfirmationModal v-bind="confirmAttrs" ref="confirmation" :message="confirm" :value="{}" @confirm="onConfirm" v-if="loaded && confirm.length > 0" />

        <div v-show="loading" class="overflow">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </div>
    </component>
</template>

<style lang="scss" scoped>
.btn {
    position: relative;

    .overflow {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: inherit;
    }

    &-fancy {
        z-index: 1;
        background: linear-gradient(135deg, rgba(23,162,184,1) 0%, rgba(68,135,186,1) 49%, rgba(161,77,188,1) 100%);
        color: white !important;
        border: none !important;
        padding-top: 7px;
        padding-bottom: 6.5px;
        overflow: hidden;

        &:hover {
            border-color: var(--bs-card-border-color) !important;
        }

        @keyframes gradientMove {
            from {
                opacity: 0;
            }

            to {
                opacity: 1;
            }
        }

        &:before {
            z-index: -1;
            opacity: 0;
            animation-duration: 3s;
            animation-timing-function: linear;
            animation-name: gradientMove;
            animation-iteration-count: infinite;
            animation-direction: alternate;
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: linear-gradient(220deg, rgb(35, 141, 255) 0%, rgb(52, 122, 175) 40%, rgb(52, 122, 175) 60%, rgb(188, 77, 225) 100%);
        }
    }
}
.btn-float {
    padding: 12px !important;

    .fa-fw {
        display: block !important;
        width: 24px;
        height: 24px;
        line-height: 24px;
        font-size: 16px;
    }
}

.fa-back {
    font-size: 16px;
}

.back-button {
    transform: translate(8px, 0);
    box-shadow: none !important;

    .fa-back {
        color: var(--bs-black);
    }
}
</style>

import { Plugin } from 'vue';

import DunamicGrid from '@/components/dynamicgird/components/DynamicGrid.vue';

import TextFormatter from '@/components/dynamicgird/components/formatters/TextFormatter.vue';
import BooleanFormatter from '@/components/dynamicgird/components/formatters/BooleanFormatter.vue';
import DateTimeFormatter from '@/components/dynamicgird/components/formatters/DateTimeFormatter.vue';
import ImageFormatter from '@/components/dynamicgird/components/formatters/ImageFormatter.vue';
import WeightFormatter from '@/components/dynamicgird/components/formatters/WeightFormatter.vue';
import DimensionsFormatter from '@/components/dynamicgird/components/formatters/DimensionsFormatter.vue';
import QuantityFormatter from '@/components/dynamicgird/components/formatters/QuantityFormatter.vue';
import ProgressFormatter from '@/components/dynamicgird/components/formatters/ProgressFormatter.vue';
import StatusFormatter from '@/components/dynamicgird/components/formatters/StatusFormatter.vue';
import NullableBooleanFormatter from '@/components/dynamicgird/components/formatters/NullableBooleanFormatter.vue';
import WarehouseFormatter from '@/components/dynamicgird/components/formatters/WarehouseFormatter.vue';
import ContractorFormatter from '@/components/dynamicgird/components/formatters/ContractorFormatter.vue';
import NullableTextFormatter from './components/formatters/NullableTextFormatter.vue';
import DeliveryMethodFormatter from '@/components/dynamicgird/components/formatters/DeliveryMethodFormatter.vue';
import ProductDeliveryInfoArrayFormatter from '@/components/dynamicgird/components/formatters/ProductDeliveryInfoArrayFormatter.vue';
import CourierFormatter from '@/components/dynamicgird/components/formatters/CourierFormatter.vue';
import VolumeFormatter from '@/components/dynamicgird/components/formatters/VolumeFormatter.vue';


interface DynamicGrid
{
    format(type: string): DynamicGridFormatter;
}

interface DynamicGridOptions
{
    components: DynamicGridComponent[],
    formatters: DynamicGridFormatter[]
}

interface DynamicGridComponent
{
    type: string;
    vueElement: any;
}

interface DynamicGridFormatter
{
    type: string;
    vueElement: any;
}

class DynamicGridHelper implements DynamicGrid
{
    private options: DynamicGridOptions;

    public constructor(options: DynamicGridOptions)
    {
        this.options = options;
    }

    public format(type: string) : DynamicGridFormatter
    {
        const formatter = this.options.formatters.find(x => x.type == type);

        if (!formatter)
        {
            throw new Error(`not found: ${type}.`);
        }

        return formatter;
    }
}

const DynamicGridPlugin: Plugin =
{
    install(app, options)
    {
        const vue = app.config.globalProperties;

        options = {
            formatters: [
                {
                    type: 'string',
                    vueElement: TextFormatter
                },
                {
                    type: 'boolean',
                    vueElement: BooleanFormatter
                },
                {
                    type: 'number',
                    vueElement: TextFormatter
                },
                {
                    type: 'datetime',
                    vueElement: DateTimeFormatter
                },
                {
                    type: 'image',
                    vueElement: ImageFormatter
                },
                {
                    type: 'weight',
                    vueElement: WeightFormatter
                },
                {
                    type: 'dimensions',
                    vueElement: DimensionsFormatter
                },
                {
                    type: 'quantity',
                    vueElement: QuantityFormatter
                },
                {
                    type: 'progress',
                    vueElement: ProgressFormatter
                },
                {
                    type: 'status',
                    vueElement: StatusFormatter
                },
                {
                    type: 'warehouse',
                    vueElement: WarehouseFormatter
                },
                {
                    type: 'contractor',
                    vueElement: ContractorFormatter
                },
                {
                    type: 'boolean | null',
                    vueElement: NullableBooleanFormatter
                },
                {
                    type: 'number | null',
                    vueElement: NullableTextFormatter
                },
                {
                    type: 'string | null',
                    vueElement: NullableTextFormatter
                },
                {
                    type: 'datetime | null',
                    vueElement: DateTimeFormatter
                },
                {
                    type: 'deliverymethod',
                    vueElement: DeliveryMethodFormatter
                },
                {
                    type: 'product_delivery_info_array',
                    vueElement: ProductDeliveryInfoArrayFormatter
                },
                {
                    type: 'courier',
                    vueElement: CourierFormatter
                },
                {
                    type: 'volume',
                    vueElement: VolumeFormatter
                }
            ]
        } as DynamicGridOptions;

        app.component('DynamicGrid', DunamicGrid);

        vue.$dynamicGrid = new DynamicGridHelper(options);
    }
};

export default DynamicGridPlugin;

declare module "@vue/runtime-core"
{
    interface ComponentCustomProperties
    {
        $dynamicGrid: DynamicGrid;
    }
}

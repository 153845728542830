<template>
    <component :is="tagName" v-bind="attrs()" class="text-break" :class="classNames">
        <slot name="default" v-if="loaded">
            <i class="far fa-grip-dots-vertical"></i>
        </slot>
        <placeholder :width="[minWidth, maxWidth]" :height="14" v-else></placeholder>
    </component>
</template>

<script lang="ts" setup>
import { computed, inject, useAttrs, type Ref } from 'vue';
import { only } from '@/helpers/Utils';

defineOptions({
    name: 'list-view-handle',
    inheritAttrs: false
});

const loaded = inject<Ref<boolean>>('loaded');
const layout = inject<Ref<string>>('layout');

const table = computed(() => layout.value == 'table');
const tagName = computed(() => table.value ? 'td' : 'div');

const cols = computed(() => $attrs.cols as string);
const colsXs = computed(() => $attrs['cols-xs'] as string || cols.value);
const colsSm = computed(() => $attrs['cols-sm'] as string || colsXs.value);
const colsMd = computed(() => $attrs['cols-md'] as string || colsSm.value);
const colsClass = computed(() => ({
    'mb-1': true,
    'col': colsXs.value == null && colsSm.value == null && colsMd.value == null,
    [`col-${colsXs.value}`]: colsXs.value != null,
    [`col-sm-${colsSm.value}`]: colsSm.value != null,
    [`col-md-${colsMd.value}`]: colsMd.value != null
}));
const classNames = computed(() => table.value ? 'pointer' : colsClass.value);

const $attrs = useAttrs();
const minWidth = computed(() => parseInt($attrs.width as string) || 100);
const maxWidth = computed(() => parseInt($attrs.width as string) || 175);
const attrs = (): Record<string, any> => only($attrs, 'class', 'style');
</script>

import { axios } from '@/plugins/axios';
import merge from 'lodash/merge';
import Pager from '@/helpers/Pager';
import { Pagination, Resource, Statement } from '@/helpers/Interfaces';
import { DateTime } from 'luxon';
import { DashboardAccessibilityType } from '@/helpers/Enums';

/**
 * DashboardsService
 */
export default class DashboardsService
{
    public static path = 'admin/dashboards';

    /**
     * @param filter FilterModel
     * @param pager Pager
     *
     * @returns Promise<Pagination<Resource<ListItemModel>>>
     */
    public static async getList(filter: FilterModel, pager: Pager): Promise<Pagination<Resource<ListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<ListItemModel>>>(`${this.path}`, {
            params: merge({}, filter, pager.data())
        })).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async fetch(id: number): Promise<Resource<FormModel>>
    {
        return (await axios.get<Resource<FormModel>>(`${this.path}/${id}`)).data;
    }

    /**
     * @param model UpsertModel
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async create(model: FormModel): Promise<Resource<FormModel>>
    {
        return (await axios.post<Resource<FormModel>>(`${this.path}`, model)).data;
    }

    /**
     * @param id number
     * @param model UpsertModel
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async update(id: number, model: FormModel): Promise<Statement>
    {
        return (await axios.put<Statement>(`${this.path}/${id}`, model)).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async copy(id: number): Promise<Statement>
    {
        return (await axios.post<Statement>(`${this.path}/${id}/copy`)).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Statement>
     */
    public static async remove(id: number): Promise<Statement>
    {
        return (await axios.delete(`${this.path}/${id}`)).data as Statement;
    }

    /**
     * @param id number
     * @param widgetId string
     *
     * @returns Promise<WidgetConfigModel>
     */
    public static async getWidgetConfig(id: number, widgetId: string): Promise<WidgetConfigModel>
    {
        return (await axios.get<WidgetConfigModel>(`${this.path}/${id}/widgets/${widgetId}`)).data;
    }

    /**
     * @param id number
     * @param widgetId string
     * @param model WidgetConfigModel
     *
     * @returns Promise<Statement>
     */
    public static async updateWidgetConfig(id: number, widgetId: string, model: WidgetConfigModel): Promise<Statement>
    {
        return (await axios.post<Statement>(`${this.path}/${id}/widgets/${widgetId}`, model)).data;
    }

    /**
     * @returns Promise<Resource<FormModel>>
     */
    public static async getUserDashboard(): Promise<Resource<FormModel>>
    {
        return (await axios.get<Resource<FormModel>>(`${this.path}/my`)).data;
    }

    /**
     * @param dashboardId string
     *
     * @returns Promise<DashboardConfigModel>
     */
    public static async getDashboard(dashboardId: string): Promise<DashboardConfigModel>
    {
        return (await axios.get<DashboardConfigModel>(`${this.path}/${dashboardId}/config`)).data;
    }

    /**
     * @param dashboardId string
     * @param model DashboardConfigModel
     *
     * @returns Promise<Resource<Statement>>
     */
    public static async saveDashboard(dashboardId: number, model: DashboardConfigModel): Promise<Statement>
    {
        return (await axios.put<Resource<FormModel>>(`${this.path}/${dashboardId}/config`, model)).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Statement>
     */
    public static async attachDashboard(id: number): Promise<Statement>
    {
        return (await axios.post<Statement>(`${this.path}/${id}/attach`)).data;
    }

    /**
     * @returns Promise<Array<WidgetCategoryModel>>
     */
    public static async getWidgetCategories(): Promise<Array<WidgetCategoryModel>>
    {
        return (await axios.get<Array<WidgetCategoryModel>>(`${this.path}/widget-categories`)).data;
    }

    /**
     * @param categoryId string
     * @param search string
     *
     * @returns Promise<Array<WidgetModel>>
     */
    public static async getWidgets(filter: AddWidgetFilterModel): Promise<Array<WidgetModel>>
    {
        return (await axios.get<Array<WidgetModel>>(`${this.path}/widgets`, {
            params: merge({}, filter)
        })).data;
    }

    /**
     * @param id string
     *
     * @returns Promise<WidgetModel>
     */
    public static async getWidget(id: string): Promise<WidgetModel>
    {
        return (await axios.get<WidgetModel>(`${this.path}/widgets/${id}`)).data;
    }
}

export interface ListItemModel
{
    id: number;
    name: string;
    dateCreatedUtc: DateTime;
    isDefault: boolean;
    isActive: boolean;
    accessibilityType: DashboardAccessibilityType;
    createdBy: string;
}

export interface FilterModel
{
    name: string;
    dateCreatedFromUtc?: DateTime;
    dateCreatedToUtc?: DateTime;
    onlyMine: boolean;
    isDefault: boolean;
    isActive: boolean;
    accessibilityType: DashboardAccessibilityType;
    createdBy?: number;
}

export interface FormModel {
    id: number,
    name: string;
    description: string;
    isDefault: boolean;
    isActive: boolean;
    config: DashboardConfigModel;
    ownerId: number;
    resourceId: number;
    accessibilityType: DashboardAccessibilityType;
}

export interface DashboardConfigModel
{
    widgets: WidgetConfigModel[];
}

export interface WidgetConfigModel
{
    id: string;
    x?: number;
    y?: number;
    width: number;
    height: number;
    sourceConfig: Record<string, any> | string;
}

export interface WidgetCategoryModel
{
    id: string;
    name: string;
    icon: string;
}

export interface WidgetModel {
    id: string;
    name: string;
    description: string;
    icon: string;
    height: number;
    width: number;
    categories: string[];
    type: string;
}

export interface GridItemAttributes {
    id: string;
    "gs-id": string;
    "gs-x": number;
    "gs-y": number;
    "gs-w": number;
    "gs-h": number;
    "gs-no-resize"?: boolean;
}

export interface AddWidgetFilterModel {
    search: string;
    categoryId: string;
}

export enum WidgetTypeEnum {
    ActiveSubstitutions = "ActiveSubstitutions",
    ActiveInventories = "ActiveInventories",
    Inventories = "Inventories",
    UnfinishedTransfers = "UnfinishedTransfers",
    UnfinishedIssues = "UnfinishedIssues",
    ReceiptStatuses = "ReceiptStatuses",
    ReceiptsProductsSum = "ReceiptsProductsSum",
    UsersOnline = "UsersOnline",
    TodaysIssues = "TodaysIssues",
    PiecesIssuedToCouriers = "PiecesIssuedToCouriers",
    MostIssuedProducts = "MostIssuedProducts",
    PiecesIssuedByCouriers = "PiecesIssuedByCouriers",
    Shortcuts = "Shortcuts",
    IssuesByStatus = "IssuesByStatus",
    IssuesProductsSum = "IssuesProductsSum",
    TransfersByStatus = "TransfersByStatus",
}
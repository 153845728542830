<script setup lang="ts">
import { useOneSignal } from '@onesignal/onesignal-vue3';
import { useAuth } from '@/plugins/auth';
import { computed, onMounted } from 'vue';

defineOptions({
    name: 'PushNotifications'
});

const { $auth } = useAuth();

const userIdentity = computed(() => $auth.user());

onMounted(() =>
{
    setTimeout(async () =>
    {
        await loginOneSignal();
    }, 500);
}
);

const loginOneSignal = async () : Promise<void> =>
{
    const oneSignal = useOneSignal();

    const notifications = oneSignal.Notifications;

    if (!notifications.permission)
        await oneSignal.Notifications.requestPermission();

    if (notifications.permission && notifications.isPushSupported())
    {
        if (userIdentity.value)
        {
            await oneSignal.login(userIdentity.value.publicId);
        }
    }

    notifications.setDefaultTitle("Asiston WMS");
};

</script>

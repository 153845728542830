<script lang="ts" setup>
import { computed, watch } from 'vue';
import { computedAsync } from '@vueuse/core';
import { useLocalization } from '@/plugins/localization';
import { Option } from '@/helpers/Interfaces';
import IdeoComboBoxContainer from './IdeoComboBoxContainer.vue';

type BaseType = string|number;

const model = defineModel<(BaseType)[]>();
const query = defineModel<string>('query', {required: false, default: ''});
const props = withDefaults(defineProps<{
    name: string;
    placeholder?: string;
    footer?: boolean,
    options?: Record<string, any>[],
    valueField?: string,
    textField?: string,
    size?: number,
    default?: boolean,
    defaultLabel?: string;
    fetch?: (ids: (BaseType)[]) => Promise<Option<BaseType>[]>;
    search?: (query: string, limit: number) => Promise<Option<BaseType>[]>
}>(), {
    placeholder: '[[[Wyszukaj...]]]',
    footer: true,
    options: (): [] => ([]),
    valueField: 'value',
    textField: 'text',
    size: 10,
    default: true,
    defaultLabel: '[[[Brak wyboru]]]',
    fetch: undefined,
    search: undefined
});
const emit = defineEmits<{
    (e: 'change', value: Option<BaseType>[]): void,
    (e: 'display', value: string): void
}>();

const { $t } = useLocalization();

const options = computedAsync(async () =>
{
    if (props.search !== undefined)
    {
        return await props.search(query.value, props.size);
    }

    return props.options.map(p => ({
        value: p[props.valueField],
        text: p[props.textField]
    }));
});
const optionsFiltered = computed(() =>
{
    return options.value?.filter(p =>
        !model.value.includes(p.value) &&
        p.text.toLowerCase().includes(query.value.toLowerCase())
    ) || [];
});
const selected = computedAsync(async () =>
{
    let result: Option<BaseType>[] = [];

    if (props.fetch !== undefined)
        result = await props.fetch(model.value);
    else
        result = options.value.filter(p => model.value.includes(p.value));

    emit('change', result);

    return result;
});

watch([model, options], async () =>
{
    let result: string = null;

    if (model.value.length > 0)
        result = $t('[[[Wybrane: %0|||{0}]]]', model.value.length);

    emit('display', result ?? (props.default ? $t(props.defaultLabel) : null));
},
{immediate: true});
</script>

<template>
    <IdeoComboBoxContainer
        v-model:query="query"
        :count="selected?.length ?? 0"
        :total="options?.length ?? 0"
        :placeholder="$t(props.placeholder)"
        :footer="props.footer"
    >
        <template #header>
            <slot name="header"></slot>
        </template>
        <template #default>
            <h6 class="text-uppercase ps-1 ms-2 mt-2 mb-0" v-if="selected?.length > 0">
                {{ $t('[[[Wybrane]]]') }}
            </h6>
            <ideo-form-checkbox-group
                v-model="model"
                name="selected"
                :options="selected"
                :key="`selected-${model.join(',')}`"
                class="text-info mt-1"
                stacked
                v-if="selected?.length > 0"
            >
                <template #label="{option, index}">
                    <slot name="label" :option="option" :index="index"></slot>
                </template>
            </ideo-form-checkbox-group>
            <h6 class="text-uppercase ps-1 ms-2 mt-2 mb-0" v-if="selected?.length > 0 && (query || optionsFiltered?.length > 0)">
                {{ $t('[[[Pozostałe]]]') }}
            </h6>
            <ideo-form-checkbox-group
                v-model="model"
                :name="props.name"
                :options="optionsFiltered"
                :key="`options-${model.join(',')}`"
                class="mt-1"
                stacked
                v-if="optionsFiltered?.length > 0"
            >
                <template #label="{option, index}">
                    <slot name="label" :option="option" :index="index"></slot>
                </template>
            </ideo-form-checkbox-group>
            <div class="d-flex justify-content-center py-3" v-if="query && optionsFiltered?.length == 0">
                {{ $t('[[[Brak wyników]]]') }}
            </div>
            <div class="d-flex justify-content-center py-3" v-if="selected?.length == 0 && options?.length == 0 && !query">
                {{ $t('[[[Brak opcji]]]') }}
            </div>
        </template>
        <template #footer>
            <slot name="footer"></slot>
        </template>
    </IdeoComboBoxContainer>
</template>
